import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";
import {templates} from "./shared";

function getTemplatesInRandomOrderByWeights(templates) {
  const ordered = templates.slice();
  const results = [];

  while (ordered.length > 0) {
    const weightsSum = ordered.reduce((t, template) => {
      return t + (template.weight ** 2);
    }, 0);

    let r = Math.floor(Math.random() * (weightsSum + 1));
    const index = ordered.findIndex((template) => {
      r -= template.weight ** 2;
      return r <= 0;
    });

    if (index > -1) {
      results.push(...ordered.splice(index, 1));
    }
  }

  return results;
}

export default class Valentine3Page extends React.Component {

  state = {
    isLoading: true,
    template: null,
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.files = getWebviewInputFilesFromURL();
    this.processings = [];
    this.templates = getTemplatesInRandomOrderByWeights(templates.slice());
    this.state.template = this.templates.first();

    console.log(this.templates);

    const webdata = decodeWebData(window.location.href);
    if (webdata && webdata.template && webdata.template.id) {
      // eslint-disable-next-line eqeqeq
      const template = this.templates.find((t) => t.id == webdata.template.id);
      if (template) {
        this.state.template = template;
      }
    }
  }

  componentDidMount() {
    this.startProcessing();
  }

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template: this.state.template,
    };

    this.processings.push(processing);

    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(this.state.template.id))
      .setLanguage(window.clientConfig.lang || "en");

    (this.state.isSwaped ? [1, 0] : [0, 1]).forEach((index) => {
      const file = index >= this.files.length
        ? this.files[0]
        : this.files[index];

      taskBuilder.addImage(new PhotolabTaskImageUrl(
        file.url,
        file.rect,
        file.rotation,
        file.flip,
        file.alt_body || file.alt_body_template
      ));
    })

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({template: this.state.template}),
    });
  };

  handleRetryClick = (processing) => {
    const pos = this.processings.findIndex((p) => p === processing);
    if (pos >= 0) {
      this.processings.splice(pos, 1);
    }

    this.startProcessing();
    this.setState({
      updatedAt: Date.now(),
    });
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleSwap = () => {
    this.processings = [];

    this.setState({
      isSwaped: !this.state.isSwaped,
    }, () => {
      this.startProcessing();
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleMoreStylesClick = () => {
    const usedTemplatesIds = this.processings.map((p) => p.template.id);
    let template = this.templates.find((t) => !usedTemplatesIds.includes(t.id));
    if (template === undefined) {
      let index = this.templates.findIndex((t) => t.id === this.state.template.id);
      if (index + 1 >= this.templates.length) {
        index = 0;
      } else {
        index += 1;
      }

      template = this.templates[index];
    }

    const processing = this.processings.find((processing) => {
      return processing.template.id === template.id;
    });

    this.setState({template}, () => {
      if (!processing) {
        this.startProcessing();
      }
    })
  };

  render() {
    const processing = this.processings.find((p) => {
      return p.template.id === this.state.template.id;
    });

    return <section className="start-page valentine22">
      <div className="start-page-header" hidden>
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={() => this.handleRetryClick(processing)}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container templates-container_column" hidden={!processing || !processing.isProcessed}>
        <button className="btn-more" onClick={this.handleMoreStylesClick}>
          {i18n.t("another_go")}
        </button>
        {/*{this.files.length > 1 && <button onClick={this.handleSwap} className="btn-swap">*/}
        {/*  <div className="btn-images">*/}
        {/*    <img src={this.files[0].url} alt="" />*/}
        {/*    <SvgArrowsIcon />*/}
        {/*    <img src={this.files[1].url} alt="" />*/}
        {/*  </div>*/}
        {/*  <span dangerouslySetInnerHTML={{__html: i18n.t("button_tap_to_swap")}} />*/}
        {/*</button>}*/}
      </div>
    </section>;
  }
}

Valentine3Page.contextType = AppContext;
