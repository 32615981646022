const genders = {
  male: "male",
  female: "female",
};

const bodies = [
  {id: 6575, gender: genders.female},
  {id: 6475, gender: genders.female},
  {id: 6566, gender: genders.female},
  {id: 6467, gender: genders.female},
  {id: 6464, gender: genders.female},
  {id: 6466, gender: genders.female},
  {id: 6391, gender: genders.female},
  {id: 6383, gender: genders.female},
  {id: 6390, gender: genders.female},
  {id: 6577, gender: genders.female},
  {id: 6578, gender: genders.female},
  {id: 6579, gender: genders.female},
  {id: 6580, gender: genders.female},
  {id: 6581, gender: genders.female},
  {id: 6582, gender: genders.female},

  {id: 6575, gender: genders.male},
  {id: 6475, gender: genders.male},
  {id: 6566, gender: genders.male},
  {id: 6467, gender: genders.male},
  {id: 6464, gender: genders.male},
  {id: 6577, gender: genders.male},
  {id: 6578, gender: genders.male},
  {id: 6579, gender: genders.male},
  {id: 6580, gender: genders.male},
  {id: 6581, gender: genders.male},
  {id: 6582, gender: genders.male},
];

export default {
  genders,
  bodies,
}