export function reorderMaskTemplates(templates) {
  const groups = [];

  templates.forEach((template) => {
    const key = template.weight || 1;
    const group = groups.find((g) => g.key === key);
    if (group) {
      group.items.push(template);
    } else {
      groups.push({key, items: [template]});
    }
  });

  groups.sort((a, b) => a.key < b.key ? 1 : -1);

  return groups.flatMap((g) => g.items.shuffle());
}