import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {
  decodeWebData,
  encodeWebData,
  getStepIdx, getSteps
} from "../../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";
import TemplateItemView from "../../components/TemplateItemView";
import {pwAssetUrl} from "../../../utils/etc";

function createProcessing(template) {
  const processing = {};
  processing.tries = 0;
  processing.template = template;
  processing.isProcessing = true;
  processing.isProcessed = false;
  processing.isFailed = false;
  processing.startedAt = Date.now();

  return processing;
}

export default class SimplePickerPage extends React.Component {

  state = {
    updatedAt: 0,
    template: null,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.files = getWebviewInputFilesFromURL();

    const webdata = decodeWebData(window.location.href);
    if (webdata && webdata.template_id) {
      const templateId = parseInt(webdata.template_id);
      const template = this.props.templates.find((t) => t.id === templateId);
      if (template) {
        this.state.template = template;
      }
    }

    if (this.state.template === null) {
      this.state.template = this.props.templates.first();
    }

    const processing = createProcessing(this.state.template);
    this.processings = [processing];
    this.startProcessing(processing);
  }

  startProcessing = (processing) => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const file = this.files[0];
    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
      .addImage(new PhotolabTaskImageUrl(
        file.url,
        file.rect,
        file.rotation,
        file.flip,
        file.alt_body || file.alt_body_template
      ))
      .setLanguage(window.clientConfig.lang || "en");

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
    });

    const resultData = {
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({template_id: this.state.template.id}),
    };

    if (this.masksEnabled) {
      this.files.forEach((file, index) => {
        if (file.fixed) {
          return;
        }

        const appIndex = index + 1;

        if (file.alt_body) {
          resultData["alt_body_" + appIndex] = encodeURIComponent(file.alt_body);
        } else if (file.alt_body_template) {
          resultData["body_" + appIndex] = encodeURIComponent(file.alt_body_template);
        }
      });
    }

    webviewSendResult(resultData);
  };

  handleRetryClick = (processing) => {
    this.startProcessing(processing);
  };

  handleResultLoaded = () => {
    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
    });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.props.templates.findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
      is_hidden: false,
    });

    this.setState({template});

    let processing = this.processings.find((processing) => {
      return processing.template.id === template.id;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.processings.push(processing);

    this.setState({updatedAt: Date.now()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  };

  render() {
    if (this.noUI) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const processing = this.processings.find((p) => {
      return p.template.id === this.state.template.id;
    });

    if (!processing) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const stepIdx = getStepIdx(window.location.href, 2);
    const steps = getSteps(window.location.href, 2);

    return <section className="start-page">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container" hidden={stepIdx === 1 && steps === 1}>
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: stepIdx, steps: steps})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("start_page_title")}}/>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={() => this.handleRetryClick(processing)}
          />}

          <button
            className="btn-done"
            hidden={!processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container">
        <div className="container">
          {this.props.templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.template.id === template.id}
            previewUrl={template.previewUrl || pwAssetUrl(`previews/256x256/1/${template.id}.jpg?3`)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>
    </section>;
  }
}

SimplePickerPage.contextType = AppContext;
