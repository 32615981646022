import React from "react";
import {Route} from "react-router";
import Xmas21TransparentPage from "./Xmas21TransparentPage";
import Xmas21BackgroundPage from "./Xmas21BackgroundPage";
import Xmas21PreparationPage from "./Xmas21PreparationPage";
import Xmas21TreeBodyPage from "./Xmas21TreeBodyPage";
import WinterIs21BackgroundPage from "./WinterIs21BackgroundPage";
import Xmas21MaskPage from "./Xmas21MaskPage";

const routes = [{
  key: "xmas21BackgroundRoute",
  path: "/xmas21-background",
  render: (props) => <Xmas21BackgroundPage {...props} pageName="xmas21-background" />,
}, {
  key: "xmas21PreparationRoute",
  path: "/xmas21-preparation",
  render: (props) => <Xmas21PreparationPage {...props} pageName="xmas21-preparation" />,
}, {
  key: "xmas21TransparentRoute",
  path: "/xmas21-transparent",
  render: (props) => <Xmas21TransparentPage {...props} pageName="xmas21-transparent" />,
}, {
  key: "xmas21TreeBodyRoute",
  path: "/xmas21-tree-body",
  render: (props) => <Xmas21TreeBodyPage {...props} pageName="xmas21-tree-body" />,
}, {
  key: "winterIs21BackgroundRoute",
  path: "/winter-is-21-background",
  render: (props) => <WinterIs21BackgroundPage {...props} pageName="winter-is-21-background" />,
}, {
  key: "xmas21MaskRoute",
  path: "/xmas21-mask",
  render: (props) => <Xmas21MaskPage {...props} pageName="xmas21-mask" />,
},];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}