module.exports = {
  "strings": {

    "can_swap_or_continue": "Troque de rosto ou continue como está",
    "button_tap_to_swap": "Toque para trocar",

    "button_choice_gender_male": "masculinos",
    "button_choice_gender_female": "femininos",
    "button_choice_gender_text": "estilos",
    "btn_retry": "Tentar novamente",

    "step_count_text": "{{step_idx}} de {{steps}}",
    "start_page_title": "Escolha o estilo que mais gosta",
    "start_page_title_2": "Escolha o estilo que mais gosta",
    "olympics22__start_page_title" : "Por quem você está torcendo?",

    "title__choose_body": "Escolher estilo do corpo",
    "title__choose_crop": "Escolher estilo do corpo",
    "title__choose_background": "Escolha o estilo de fundo",
    "gfp936_title_choose_crop_backgroud": "Selecione aparar retrato e plano de fundo",

    "loader_processing": "Processando",

    "vector_tab_refresh__title": "Quase pronto!",
    "vector_tab_refresh__subtitle": "Escolher estilo da cabeça",

    "error_modal__default_message": "Algo deu errado...",
    "error_modal__network_message": "Verifique a sua conexão a internet.",

    "footer_title": "Escolher estilo do corpo",

    "more_styles": "Mais estilos",
  },
};
