import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabAddTask, photolabGenderTask, PhotolabResponseError, photolabWaitTask} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import {assetUrl} from "../../../utils/etc";
import {hitEvent, hitPhotoFailed, hitPhotoProcessed, hits} from "../../../utils/hits";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData, getStepIdx, getSteps} from "../../../utils/photolab.utils";

function suppressTemplates(templates) {
  const groups = [
    templates.filter((t) => t.gender === config.genders.male),
    templates.filter((t) => t.gender === config.genders.female),
  ];

  groups.forEach((templates) => {
    for (let i = 0; i < 1;) {
      if (templates[i].suppressed) {
        const template = templates.shift();
        templates.splice(templates.randomIndex(), 0, template);
        i = 0;
      } else {
        i++;
      }
    }
  });

  return groups[0].concat(groups[1]);
}

export default class BodyChooserPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
    gender: config.genders.male,
    genderDetectionResult: null,
    templates: [],
    selectedTemplate: null,
    selectedTemplateIsUser: false,
    processings: [],
    webdata: null,
    isShowHiddenBodies: false,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);

    const allTemplates = config.templates.filter((t) => t.style === this.props.style);
    const visibleTemplates = allTemplates.filter((t) => !t.isHidden).shuffle();
    const hiddenTemplates = allTemplates.filter((t) => t.isHidden).shuffle();

    this.state.gender = window.localStorage.getItem("bodychooser:gender") || this.state.gender;
    this.state.templates = suppressTemplates([...visibleTemplates, ...hiddenTemplates]);

    this.state.files = getWebviewInputFilesFromURL();
    this.state.webdata = decodeWebData(window.location.href);
  }

  initWithGenderDetection = () => {
    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => {
        this.setState({
          gender: taskResult.gender.value,
          genderDetectionResult: taskResult.gender,
        }, () => {
          this.initBySelectedTemplate(this.getFirstTemplate());
        });
      })
      .catch((err) => {
        console.error(err);
        this.initBySelectedTemplate(this.getFirstTemplate());
      });
  };

  getWebdataTemplate = () => {
    return this.state.webdata
        && this.state.webdata.body
        && this.state.webdata.body.template
        && this.state.templates.find((template) => {
          return template.id === parseInt(this.state.webdata.body.template.id)
              && template.gender === this.state.webdata.body.template.gender;
        });
  };

  getFirstTemplate = () => {
    return this.state.templates.find((template) => template.gender === this.state.gender);
  };

  initBySelectedTemplate = (selectedTemplate) => {
    const pos = this.state.templates.findIndex((t) => {
      return t.gender === selectedTemplate.gender
          && t.id === selectedTemplate.id
    });

    if (pos > 0) {
      this.state.templates.splice(pos, 1);
      this.state.templates.unshift(selectedTemplate);
    }

    this.setState({
      isLoading: false,
      gender: selectedTemplate.gender,
      templates: this.state.templates.slice(),
    }, () => {
      this.handleTemplateSelect(selectedTemplate, false);
    });
  };

  componentDidMount() {
    const template = this.getWebdataTemplate();

    if (this.state.webdata && template) {
      this.initBySelectedTemplate(template);
    } else {
      this.initWithGenderDetection();
    }
  }

  startProcessing = (processing) => {
    hitEvent(hits.CREATIVE_STARTED);

    if (processing.tries > 0) {
      hitEvent(hits.CREATIVE_STARTED_RETRY);
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(this.state.files[0].url))
      .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
      .setLanguage(window.clientConfig.lang || "en")
      .build();

    photolabAddTask(taskConfig)
      .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 1000, 500))
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        hitEvent(hits.CREATIVE_PROCESSED);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult;

        this.setState({processings: this.state.processings.slice()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        hitEvent(hits.CREATIVE_FAILED);

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        if (err instanceof PhotolabResponseError) {
          logError.taskRequestId = err.requestId;
          logError.taskInputImageUrl = this.state.files[0].url;
        } else {
          hitEvent(hits.CREATIVE_FAILED__NONPHOTOLAB);
        }

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({processings: this.state.processings.slice()});
      });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.templates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
      is_hidden: !!(config.templates.find((t) => t.id === template.id).isHidden),
    });

    this.setState({
      selectedTemplateIsUser: !!isUser,
      selectedTemplate: template,
    });

    let processing = this.state.processings.find((processing) => {
      return processing.template.id === template.id
          && processing.template.gender === template.gender;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  };

  handleConfirmResult = (processing) => {
    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: processing.template.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderDetectionResult,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
      is_hidden: !!(config.templates.find((t) => t.id === processing.template.id).isHidden),
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        body: {
          template: {
            id: processing.template.id,
            gender: processing.template.gender,
          },
        },
      }),
    });
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    window.localStorage.setItem("bodychooser:gender", gender);
    this.setState({gender});
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
      is_hidden: !!(config.templates.find((t) => t.id === processing.template.id).isHidden),
    });
  };

  handleShowHiddenBodies = () => {
    this.setState({isShowHiddenBodies: true});
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    let templates = this.state.isShowHiddenBodies
      ? this.state.templates.slice()
      : this.state.templates.filter((template) => {
        if (this.state.selectedTemplate && template.id === this.state.selectedTemplate.id) {
          return true;
        } else {
          return !template.isHidden;
        }
      });

    templates = templates.filter((template) => template.gender === this.state.gender);

    const processing = this.state.selectedTemplate && this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedTemplate.id
          && processing.template.gender === this.state.selectedTemplate.gender;
    });

    const stepIdx = getStepIdx(window.location.href, 2);
    const steps = getSteps(window.location.href, 2);

    return <section className="start-page">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container" hidden={stepIdx === 1 && steps === 1}>
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: stepIdx, steps: steps})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("start_page_title")}}/>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>

          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}
        </div>
      </div>

      <div className="templates-container">
        <div className="container">
          <button className={"btn-choice-gender " + (this.state.gender === config.genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.toggleGender}>
            <SvgSprite viewBox="0 0 48 48" icon="icon-arrow-back" />
            <span className="btn-choice-gender-title">
              {i18n.t(this.state.gender === config.genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}
              <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />
            </span>
          </button>

          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedTemplate ? (this.state.selectedTemplate.id === template.id && this.state.selectedTemplate.gender === template.gender) : false}
            previewUrl={assetUrl(`assets/images/bodychooser/bodies/${template.previewFileName || template.id}.png`)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}

          <button
            key="toggle-hidden"
            hidden={this.state.isShowHiddenBodies}
            className={"btn-toggle-styles"}
            onClick={this.handleShowHiddenBodies}
            children={i18n.t("more_styles")} />
        </div>
      </div>
    </section>;
  }
}

BodyChooserPage.contextType = AppContext;