import React from "react";

const triesMax = 5;

export default class DumbImageView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.triesLeft = triesMax;
    this.retryTimer = null;

    this.image = new Image();
    this.image.onload = () => {
      this.props.onImageLoaded && this.props.onImageLoaded();
      this.setState({isLoading: false});
    };

    this.image.onerror = () => {
      if (this.triesLeft > 0) {
        this.retryTimer = setTimeout(this.load, 1000);
      } else {
        this.props.onImageLoadError && this.props.onImageLoadError(this.props.image);
      }
    };

    this.load = this.load.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.image) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.image.onload = null;
    clearTimeout(this.retryTimer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.triesLeft = triesMax;
      this.load();
    }
  }

  load() {
    clearTimeout(this.retryTimer);
    this.triesLeft--;

    this.setState({
      isLoading: true,
    }, () => {
      this.image.src = this.props.image;
    });
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }

  render() {
    const isImageDisplayed = this.props.image && !this.state.isLoading;
    const styles = {};

    if (isImageDisplayed) {
      styles.backgroundImage = `url(${this.props.image})`;
    }

    return <div
      className={this.props.className || ""}
      style={styles}
      onClick={this.handleClick}
    />;
  }
}
