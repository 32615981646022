import React from "react";
import {Route} from "react-router";
import MySecretIdentityPage from "./MySecretIdentityPage";

const routes = [{
  key: "mySecretIdentityRoute",
  path: "/mysecretidentity",
  render: (props) => <MySecretIdentityPage {...props} pageName="mysecretidentity" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
};