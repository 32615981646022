export const logotypes = [
  {id: "apecoin"},
  {id: "binance_coin"},
  {id: "bitcoin1"},
  {id: "bitcoin_cash"},
  {id: "chainlink"},
  {id: "dogecoin1"},
  {id: "ethereum"},
  {id: "litecoin"},
  {id: "neo"},
  {id: "ripple_xrp"},
  {id: "solana"},
  {id: "tether1"},
  {id: "tron1"},
  {id: "uniswap"},
  {id: "xrp"},
  {id: "avalanche"},
  {id: "binance_usd"},
  {id: "bitcoin2"},
  {id: "cardano"},
  {id: "dash"},
  {id: "dogecoin2"},
  {id: "iota"},
  {id: "nano"},
  {id: "polkadot"},
  {id: "shiba_inu"},
  {id: "stellar"},
  {id: "tether2"},
  {id: "tron2"},
  {id: "usd_coin"},
];