export default class PhotolabTaskImageUrl {

  constructor(url, rect, rotation, flip, altBody) {
    if (arguments.length === 1 && typeof url === "object") {
      this._url = url.url;
      this._rect = url.rect || "";
      this._rotation = url.rotation || 0;
      this._flip = url.flip || 0;
      this._altBody = url.alt_body || url.alt_body_template || "";
    } else {
      this._url = url;
      this._rect = rect || "";
      this._rotation = rotation || 0;
      this._flip = flip || 0;
      this._altBody = altBody || "";
    }
  }

  get url() {
    return this._url;
  }

  get rect() {
    return this._rect;
  }

  get rotation() {
    return this._rotation;
  }

  get flip() {
    return this._flip;
  }

  get altBody() {
    return this._altBody;
  }
}
