const genders = {
  male: "male",
  female: "female",
};

const templates = [
  {id: 6611, gender: genders.female},
  {id: 6626, gender: genders.female},
  {id: 6629, gender: genders.female},
  {id: 6621, gender: genders.female},
  {id: 6640, gender: genders.female},

  {id: 6611, gender: genders.male},
  {id: 6626, gender: genders.male},
  {id: 6629, gender: genders.male},
  {id: 6621, gender: genders.male},
  {id: 6640, gender: genders.male},
];

const cropTemplates = [
  {id: 6637, gender: genders.female, weight: 3}, // v15
  {id: 6455, gender: genders.female, weight: 2}, // v2
  {id: "fullsize", gender: genders.female, weight: 1},

  {id: 6637, gender: genders.male, weight: 3}, // v15
  {id: 6455, gender: genders.male, weight: 2}, // v2
  {id: "fullsize", gender: genders.male, weight: 1},
];

const backgroundAliases = {
  blur: "blurBg",
  user: "userBg",
  triangl: "trianglBg",
  avgclr: "avgclrBg",
  fresco: "frescoBg",
  strokes: "strokesBg",
};

const backgroundTemplates = [
  {id: 6631, crop: "fullsize", alias: backgroundAliases.blur},
  {id: 6632, crop: "fullsize", alias: backgroundAliases.user},
  {id: 6619, crop: "fullsize", alias: backgroundAliases.triangl},
  {id: 6653, crop: "fullsize", alias: backgroundAliases.avgclr},
  {id: 6705, crop: "fullsize", alias: backgroundAliases.strokes},

  {id: 6626, crop: 6455, alias: backgroundAliases.blur},
  {id: 6629, crop: 6455, alias: backgroundAliases.user},
  {id: 6611, crop: 6455, alias: backgroundAliases.triangl},
  {id: 6640, crop: 6455, alias: backgroundAliases.avgclr},
  {id: 6621, crop: 6455, alias: backgroundAliases.fresco},
  {id: 6703, crop: 6455, alias: backgroundAliases.strokes},

  {id: 6655, crop: 6637, alias: backgroundAliases.blur},
  {id: 6636, crop: 6637, alias: backgroundAliases.user},
  {id: 6658, crop: 6637, alias: backgroundAliases.triangl},
  {id: 6657, crop: 6637, alias: backgroundAliases.avgclr},
  {id: 6656, crop: 6637, alias: backgroundAliases.fresco},
  {id: 6704, crop: 6637, alias: backgroundAliases.strokes},
];

export default {
  genders,
  templates,
  cropTemplates,
  backgroundTemplates,
  backgroundAliases,
}