export function encodeWebData(data) {
  return encodeURIComponent(JSON.stringify(data));
}

export function decodeWebData(url) {
  const webData = (new URL(url)).searchParams.get("web_data");
  if (webData != null) {
    try {
      return JSON.parse(decodeURIComponent(webData));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return null;
}

export function getStepIdx(url, defaultValue) {
  const stepIdx = (new URL(url)).searchParams.get("step_idx");

  return parseInt(stepIdx) || defaultValue;
}

export function getSteps(url, defaultValue) {
  const steps = (new URL(url)).searchParams.get("steps");

  return parseInt(steps) || defaultValue;
}