module.exports = {
  "strings": {

    "can_swap_or_continue": "คุณสามารถเปลี่ยนหน้าหรือใช้หน้าเดิมต่อได้",
    "button_tap_to_swap": "แตะเพื่อเปลี่ยน",

    "button_choice_gender_male": "สำหรับผู้ชาย",
    "button_choice_gender_female": "สำหรับผู้หญิง",
    "button_choice_gender_text": "รูปแบบ",
    "btn_retry": "ลองใหม่",

    "step_count_text": "ขั้นตอนที่ {{step_idx}} จากทั้งหมด {{steps}} ขั้นตอน",
    "start_page_title": "เลือกสไตล์ที่ชอบที่สุด",
    "start_page_title_2": "เลือกสไตล์ที่ชอบที่สุด",
    "olympics22__start_page_title" : "คุณสนับสนุนใคร?",

    "title__choose_body": "เลือกสไตล์รูปร่าง",
    "title__choose_crop": "เลือกสไตล์รูปร่าง",
    "title__choose_background": "เลือกสไตล์พื้นหลัง",
    "gfp936_title_choose_crop_backgroud": "เลือกตัดภาพเหมือนและพื้นหลัง",

    "loader_processing": "กำลังดำเนินการ",

    "vector_tab_refresh__title": "ใกล้เสร็จแล้ว!",
    "vector_tab_refresh__subtitle": "เลือกสไตล์ส่วนหัว",

    "error_modal__default_message": "มีบางอย่างผิดปกติ...",
    "error_modal__network_message": "รณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ต",

    "footer_title": "เลือกสไตล์รูปร่าง",

    "more_styles": "รูปแบบที่มากขึ้น",
  },
};
