const genders = {
  male: "male",
  female: "female",
};

const templates = [{
  id: 5576,
  gender: genders.female,
  name: "fairy",
}, {
  id: 5584,
  gender: genders.female,
  name: "witch",
}, {
  id: 5587,
  gender: genders.female,
  name: "catwoman",
}, {
  id: 5588,
  gender: genders.female,
  name: "snow white",
}, {
  id: 5589,
  gender: genders.female,
  name: "cinderella",
}, {
  id: 5591,
  gender: genders.female,
  name: "angel",
  previewFileName: "5591f",
}, {
  id: 5598,
  gender: genders.female,
  name: "demon",
}, {
  id: 5577,
  gender: genders.male,
  name: "demon",
}, {
  id: 5578,
  gender: genders.male,
  name: "vampire",
}, {
  id: 5586,
  gender: genders.male,
  name: "magician",
}, {
  id: 5590,
  gender: genders.male,
  name: "superman",
}, {
  id: 5591,
  gender: genders.male,
  name: "angel",
  previewFileName: "5591m",
}, {
  id: 5606,
  gender: genders.male,
  name: "pirate",
}, {
  id: 5612,
  gender: genders.female,
  name: "karaoke star",
}, {
  id: 5629,
  gender: genders.male,
  name: "tough guy",
}, {
  id: 5614,
  gender: genders.male,
  name: "gym superstar",
}, {
  id: 5615,
  gender: genders.female,
  name: "fitness bunny",
}, {
  id: 5616,
  gender: genders.female,
  name: "drama queen",
}, {
  id: 5617,
  gender: genders.male,
  name: "police dept",
}, {
  id: 5618,
  gender: genders.male,
  name: "champion",
}, {
  id: 5619,
  gender: genders.female,
  name: "twenties girl",
}, {
  id: 5620,
  gender: genders.male,
  name: "born racer",
  previewFileName: "5620m",
}, {
  id: 5620,
  gender: genders.female,
  name: "born racer",
  previewFileName: "5620f",
}, {
  id: 5628,
  gender: genders.male,
  name: "drama king",
}, {
  id: 5631,
  gender: genders.male,
  name: "king almighty",
}];

export default {
  genders,
  templates,
}
