import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";
import {celebrities, templates} from "./shared";

export default class Valentine4Page extends React.Component {

  state = {
    isLoading: true,
    isSwaped: false,
    template: null,
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.files = getWebviewInputFilesFromURL();
    this.processings = [];
    this.templates = templates.slice();

    this.gender = "male";
    this.celebrity = celebrities[this.gender].random();
    this.state.template = this.templates.random();

    const webdata = decodeWebData(window.location.href);
    if (webdata) {
      this.state.isSwaped = webdata.is_swaped;
    }
  }

  componentDidMount() {
    photolabGenderTask(this.files[0].url)
      .then((taskResult) => {
        this.gender = taskResult.gender.value;
        this.celebrity = celebrities[this.gender].random();

        this.setState({isLoading: false}, () => {
          this.startProcessing();
        })
      });
  }

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template: this.state.template,
      celebrity: this.celebrity,
    };

    this.processings.push(processing);

    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(this.state.template.id))
      .setLanguage(window.clientConfig.lang || "en");

    taskBuilder.addImage(new PhotolabTaskImageUrl(
      this.files[0].url,
      this.files[0].rect,
      this.files[0].rotation,
      this.files[0].flip,
      this.files[0].alt_body || this.files[0].alt_body_template
    ));

    taskBuilder.addImage(new PhotolabTaskImageUrl(
      getCelebrityUrl(this.celebrity),
    ));

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({is_swaped: this.state.isSwaped}),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleMoreStylesClick = () => {
    this.celebrity = celebrities[this.gender].random();

    const usedTemplatesIds = this.processings.map((p) => p.template.id);
    let template = this.templates.find((t) => !usedTemplatesIds.includes(t.id));
    if (template === undefined) {
      template = this.templates.shuffle().find((t) => t.id !== this.state.template.id);
    }

    const processing = this.processings.find((processing) => {
      return processing.template.id === template.id
          && processing.celebrity.id === this.celebrity.id;
    });

    this.setState({template}, () => {
      if (!processing) {
        this.startProcessing();
      }
    })
  };

  render() {
    const processing = this.processings.find((p) => {
      return p.template.id === this.state.template.id
          && p.celebrity.id === this.celebrity.id;
    });

    if (this.state.isLoading || !processing) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    return <section className="start-page valentine22">
      <div className="start-page-header" hidden>
        <div className="container">
          {/*<h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />*/}
          <h2>&nbsp;</h2>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container templates-container_column" >
        <button className="btn-more" onClick={this.handleMoreStylesClick}>
          {i18n.t("another_go")}
        </button>
      </div>
    </section>;
  }
}

function getCelebrityUrl(celebrity) {
  return `https://assets.photo-cdn.net/celebrities/${celebrity.id}.${celebrity.fileExt || "jpg"}`;
}

Valentine4Page.contextType = AppContext;
