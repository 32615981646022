module.exports = {
  "strings": {

    "can_swap_or_continue": "Puoi scambiare i volti o continuare così com'è",
    "button_tap_to_swap": "Tocca per scambiare",

    "button_choice_gender_male": "da uomo",
    "button_choice_gender_female": "da donna",
    "button_choice_gender_text": "stili",
    "btn_retry": "Riprova",

    "step_count_text": "{{step_idx}} di {{steps}}",
    "start_page_title": "Scegli lo stile che più ti piace",
    "start_page_title_2": "Scegli lo stile che più ti piace",
    "olympics22__start_page_title" : "Per chi fai il tifo?",

    "title__choose_body": "Scegli lo stile del corpo",
    "title__choose_crop": "Scegli lo stile del corpo",
    "title__choose_background": "Scegli lo stile dello sfondo",
    "gfp936_title_choose_crop_backgroud": "Scegli il taglio verticale e lo sfondo",

    "loader_processing": "In elaborazione",

    "vector_tab_refresh__title": "Ce l'hai quasi fatta!",
    "vector_tab_refresh__subtitle": "Scegli lo stile della testa",

    "error_modal__default_message": "Si è verificato un problema...",
    "error_modal__network_message": "Verificare la connessione a Internet.",

    "footer_title": "Scegli lo stile del corpo",

    "more_styles": "Altri stili",

  },
};
