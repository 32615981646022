import React from "react";
import {Route} from "react-router";
import SquidgameBodyPage from "./SquidgameBodyPage";

const routes = [{
  key: "squidgameBodyRoute",
  path: "/squidgame",
  render: (props) => <SquidgameBodyPage {...props} pageName="squidgame" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}