module.exports = {
  "strings": {

    "can_swap_or_continue": "얼굴을 바꾸거나 이대로 계속하기",
    "button_tap_to_swap": "바꾸려면 탭하세요",

    "button_choice_gender_male": "남성",
    "button_choice_gender_female": "여성",
    "button_choice_gender_text": "스타일",
    "btn_retry": "다시 시도하기",

    "step_count_text": "{{step_idx}}단계 중 {{steps}}단계",
    "start_page_title": "가장 마음에 드는 스타일을 선택하세요.",
    "start_page_title_2": "가장 마음에 드는 스타일을 선택하세요.",
    "olympics22__start_page_title" : "누구를 응원하고 있나요?",

    "title__choose_body": "바디 스타일 선택",
    "title__choose_crop": "바디 스타일 선택",
    "title__choose_background": "배경 스타일을 선택하세요.",
    "gfp936_title_choose_crop_backgroud": "세로 자르기 및 배경 선택",

    "loader_processing": "처리 중",

    "vector_tab_refresh__title": "거의 다 되었습니다!",
    "vector_tab_refresh__subtitle": "머리 스타일 선택",

    "error_modal__default_message": "문제가 발생했습니다...",
    "error_modal__network_message": "인터넷 연결을 확인해주세요.",

    "footer_title": "바디 스타일 선택",

    "more_styles": "더 많은 스타일",
  },
};
