import React from "react";
import {Route} from "react-router";
import ValentinePage from "./ValentinePage";
import Valentine2Page from "./Valentine2Page";
import Valentine3Page from "./Valentine3Page";
import Valentine4Page from "./Valentine4Page";
import Valentine5Page from "./Valentine5Page";

const routes = [{
  path: "/valentine22-1",
  render: (props) => <ValentinePage {...props} pageName="valentine22-1" templateId="6662" headTemplateId="6629" />,
}, {
  path: "/valentine22-2",
  render: (props) => <ValentinePage {...props} pageName="valentine22-2" templateId="6663" headTemplateId="6629" />,
}, {
  path: "/valentine22-3",
  render: (props) => <ValentinePage {...props} pageName="valentine22-3" templateId="6669" headTemplateId="6629" />,
}, {
  path: "/valentine22-4",
  render: (props) => <ValentinePage {...props} pageName="valentine22-4" templateId="6688" headTemplateId="6629" />,
}, {
  path: "/valentine22-5",
  render: (props) => <ValentinePage {...props} pageName="valentine22-5" templateId="6691" headTemplateId="6629" />,
}, {
  path: "/valentine22-6",
  render: (props) => <ValentinePage {...props} pageName="valentine22-6" templateId="6692" headTemplateId="6629" />,
}, {
  path: "/valentine22-7",
  render: (props) => <ValentinePage {...props} pageName="valentine22-7" templateId="6722" headTemplateId="6629" />,
}, {
  path: "/valentine22-8",
  render: (props) => <ValentinePage {...props} pageName="valentine22-8" templateId="6741" headTemplateId="6629" />,
}, {
  path: "/valentine22-9",
  render: (props) => <ValentinePage {...props} pageName="valentine22-9" templateId="6752" headTemplateId="6629" />,
}, {
  path: "/valentine22-10",
  render: (props) => <ValentinePage {...props} pageName="valentine22-10" templateId="6751" headTemplateId="6629" />,
}, {
  path: "/valentine-11",
  render: (props) => <ValentinePage {...props} pageName="valentine-11" templateId="7824" headTemplateId="6756" />,
}, {
  path: "/valentine12",
  render: (props) => <Valentine2Page {...props} pageName="valentine-12" />,
}, {
  path: "/valentine13",
  render: (props) => <Valentine3Page {...props} pageName="valentine-13" />,
}, {
  path: "/valentine14",
  render: (props) => <Valentine4Page {...props} pageName="valentine-14" />,
}, {
  path: "/valentine15",
  render: (props) => <Valentine5Page {...props} pageName="valentine-15" />,
}];

export default {
  routes: routes.map((routeData) => <Route key={routeData.path} {...routeData} />),
}
