const genders = {
  male: "male",
  female: "female",
};

const templates = {
  [genders.male]: 5505,
  [genders.female]: 5508,
};

export default {
  genders,
  templates,
}
