import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import ModalWrapper from "./ui/modals/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import BodyChooser from "./ui/pages/bodychooser";
import Halloween from "./ui/pages/halloween";
import Squidgame from "./ui/pages/squidgame";
import MySecretIdentity from "./ui/pages/mysecretidentity";
import GenderSwap from "./ui/pages/genderswap";
import Xmas21 from "./ui/pages/xmas21";
import NewYear22 from "./ui/pages/newyear22";
import QRToon from "./ui/pages/qrtoon";
import Gfp936 from "./ui/pages/gfp936";
import Olympics22 from "./ui/pages/olympics22";
import Valentine from "./ui/pages/valentine";
import Pickers from "./ui/pages/pickers";
import CryptoLogoChooser from "./ui/pages/crypto-logo-chooser";
import NativeAdOverlay from "./ui/components/NativeAdOverlay";
import "./ui/styles/app.scss";
import {hexToRgb} from "./utils/color";
import {hitPhotoStarted} from "./utils/hits";
import WebTemplateHandler1 from "./ui/pages/generic/WebTemplateHandler1";
import Hw2022_1Page from "./ui/pages/halloween2022/Hw2022_1Page";
import SimpleViewPage from "./ui/pages/SimpleViewPage";
import SimpleListPage from "./ui/pages/SimpleListPage";
import ShapeWithTextPage from "./ui/pages/ShapeWithTextPage";
import CoupleViewPage from "./ui/pages/CoupleViewPage";
import Collage1Page from "./ui/pages/collages/Collage1Page";
import Collage2Page from "./ui/pages/collages/Collage2Page";
import SdSingleHeadPage from "./ui/pages/SdSingleHeadPage";
import SdSingleSkinPage from "./ui/pages/SdSingleSkinPage";
import DreamFilterPage from "./ui/pages/DreamFilterPage";

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-lang--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    if (window.clientConfig.isWebviewAndroid) {
      document.body.classList.add("app-webview--android");
    } else if (window.clientConfig.isWebviewIOS) {
      document.body.classList.add("app-webview--ios");
    }

    const loadedParams = new URL(window.location.href).searchParams;
    const rootEl = document.documentElement;
    if (loadedParams.has("th_primary")) {
      const primaryColorHex = loadedParams.get("th_primary");
      const primaryColorRgb = hexToRgb(primaryColorHex);

      rootEl.style.setProperty("--color-primary", "#" + primaryColorHex);
      rootEl.style.setProperty(
        "--color-tap-highlight",
        `rgba(${primaryColorRgb.r}, ${primaryColorRgb.g}, ${primaryColorRgb.b}, .16)`
      );
    }

    if (loadedParams.has("th_fab_bg")) {
      rootEl.style.setProperty("--color-fab-background", "#" + loadedParams.get("th_fab_bg"));
    }

    if (loadedParams.has("th_fab_ic")) {
      rootEl.style.setProperty("--color-fab-foreground", "#" + loadedParams.get("th_fab_ic"));
    }

    if (loadedParams.get("th_dark") === "1") {
      document.body.classList.add("app-theme--dark");
    }

    if (loadedParams.has("th_fab_shape")) {
      document.body.classList.add("app-theme--fab--" + loadedParams.get("th_fab_shape"));
    }

    if (window.appConfig.isToonCoinApp) {
      document.body.classList.add("app-theme--tooncoin");
    }

    hitPhotoStarted();
  }

  render() {
    let routes = [
      ...BodyChooser.routes,
      ...Halloween.routes,
      ...MySecretIdentity.routes,
      ...Squidgame.routes,
      ...GenderSwap.routes,
      ...Xmas21.routes,
      ...NewYear22.routes,
      ...QRToon.routes,
      ...Gfp936.routes,
      ...Olympics22.routes,
      ...Valentine.routes,
      ...Pickers.routes,
      ...CryptoLogoChooser.routes,
      <Route
        key={"/t6732to7081"}
        path={"/t6732to7081"}
        render={(props) => <WebTemplateHandler1
          {...props}
          pageName="t6732to7081"
          firstTemplateId={6732}
          secondTemplateId={7081}
        />}
      />,
      <Route
        key={"/t6732to4174"}
        path={"/t6732to4174"}
        render={(props) => <WebTemplateHandler1
          {...props}
          pageName="t6732to4174"
          cropTemplateId={6455}
          firstTemplateId={6756}
          secondTemplateId={7127}
        />}
      />,
      <Route
        key={"/hw2022_1"}
        path={"/hw2022_1"}
        // eslint-disable-next-line react/jsx-pascal-case
        render={(props) => <Hw2022_1Page {...props} pageName="hw2022_1" />}
      />,
      <Route
        key={"/t7832"}
        path="/t7832"
        render={(props) => <SimpleViewPage {...props} pageName="t7832" templateId={7832} />}
      />,
      <Route
        key={"/valentines-greeting-card"}
        path="/valentines-greeting-card"
        render={(props) => <SimpleListPage
          {...props}
          pageName="valentines-greeting-card"
          templates={[8007, 8009, 8010, 8011, 8012, 8013, 8014, 8015]}
          shuffleTemplates="all"
        />}
      />,
      <Route
        key={"/i-heart-you"}
        path="/i-heart-you"
        render={(props) => <SimpleListPage
          {...props}
          pageName="valentines-greeting-card-2"
          templates={[8003, 8004, 8005, 8006]}
          shuffleTemplates="all"
        />}
      />,
      <Route
        key={"/zodiac"}
        path="/zodiac"
        render={(props) => <SimpleListPage
          {...props}
          pageName="zodiac"
          templates={[8589, 8590, 8591, 8592, 8593, 8594, 8595, 8596, 8597, 8598, 8599, 8600, 8601]}
        />}
      />,
      <Route
        key={"/shape-with-text"}
        path="/shape-with-text"
        render={(props) => <ShapeWithTextPage {...props} pageName="shape-with-text" />}
      />,
      <Route
        key="/puzzle"
        path="/puzzle"
        render={(props) => <CoupleViewPage
          {...props}
          pageName="puzzle"
          faceTemplateId={6756}
          resultTemplateId={8152}
          canSwapPositions={true}
        />}
      />,
      <Route
        key="/collage-1"
        path="/collage-1"
        render={(props) => <Collage1Page
          {...props}
          pageName="collage-1"
          faceTemplateId={7088}
          collageTemplateId={8204}
        />}
      />,
      <Route
        key="/collage-2"
        path="/collage-2"
        render={(props) => <Collage1Page
          {...props}
          pageName="collage-2"
          faceTemplateId={7088}
          collageTemplateId={8396}
        />}
      />,
      <Route
        key="/collage-3"
        path="/collage-3"
        render={(props) => <Collage2Page
          {...props}
          pageName="collage-3"
          faceTemplateId={7088}
          collageTemplateId={8531}
        />}
      />,
      <Route
        key="/sd-single-head"
        path="/sd-single-head"
        render={(props) => <SdSingleHeadPage {...props} pageName="sd-single-head" />}
      />,
      <Route
        key="/sd-single-skin"
        path="/sd-single-skin"
        render={(props) => <SdSingleSkinPage {...props} pageName="sd-single-skin" />}
      />,
      <Route
        key="/dream-filter"
        path="/dream-filter"
        render={(props) => <DreamFilterPage {...props} pageName="dream-filter" faceTemplateId={7088} />}
      />,
    ];

    return <React.Fragment>
      <Switch>{routes}</Switch>
      <ModalWrapper />
      <NativeAdOverlay />
    </React.Fragment>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<BrowserRouter><AppContextProvider><AppWithRouter /></AppContextProvider></BrowserRouter>);
