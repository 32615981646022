import React from "react";
import {Route} from "react-router";
import config from "./config";
import HeadBodyChooserPage from "./HeadBodyChooserPage";
import ConfigDiffPage from "./ConfigDiffPage";
import BodyChooserPage from "./BodyChooserPage";

const routes = [{
  key: "vectorHeadBodyChooserRoute",
  path: "/vectorheadbodychooser",
  render: (props) => <HeadBodyChooserPage {...props} style={config.styles.vector} pageName="vectorheadbodychooser" />,
}, {
  key: "vectorBodyChooserRoute",
  path: "/vectorbodychooser",
  render: (props) => <BodyChooserPage {...props} style={config.styles.vector} pageName="vectorbodychooser" />,
}, {
  key: "toonBodyChooserRoute",
  path: "/toonbodychooser",
  render: (props) => <BodyChooserPage {...props} style={config.styles.toon} pageName="toonbodychooser" />,
}, {
  key: "disneyBodyChooserRoute",
  path: "/disneybodychooser",
  render: (props) => <BodyChooserPage {...props} style={config.styles.disney} pageName="disneybodychooser" />,
}, {
  key: "bodyChooserConfigDiffRoute",
  path: "/bodychooser/configdiff",
  render: (props) => <ConfigDiffPage {...props} />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}