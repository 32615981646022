import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabAddTask, photolabGenderTask, PhotolabResponseError, photolabWaitTask} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import {assetUrl} from "../../../utils/etc";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {reorderMaskTemplates} from "./helpers";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

const views = {
  background: "background",
  body: "body",
};

function getBackgroundPreivewUrl(template) {
  return assetUrl(`assets/images/halloween/backgrounds/${template.id}.jpg`);
}

function getBodyPreivewUrl(template) {
  return assetUrl(`assets/images/halloween/transparentbodies/${template.previewFileName || template.id}.png`);
}

export default class HalloweenBodyBackgroundsPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
    genderTask: null,
    gender: config.genders.male,
    bodiesTemplates: [],
    selectedBodyTemplate: null,
    selectedBodyTemplateIsUser: false,
    backgroundsTemplates: [],
    selectedBackgroundTemplate: null,
    processings: [],
    webdata: null,
    headTask: null,
    view: views.body,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.bodyTasks = [];
    this.headTaskResult = null;

    this.state.files = getWebviewInputFilesFromURL();
    this.state.webdata = decodeWebData(window.location.href);
    this.state.gender = window.localStorage.getItem("bodychooser:gender") || this.state.gender;
    this.state.bodiesTemplates = reorderMaskTemplates(config.transparentBodies);
    this.state.backgroundsTemplates = config.transparentBodiesBackgrounds.slice().shuffle();
    this.state.selectedBackgroundTemplate = this.state.backgroundsTemplates.random();

    if (this.state.webdata) {
      this.state.selectedBackgroundTemplate
        = this.getWebdataBackgroundTemplate() || this.state.selectedBackgroundTemplate;
    }
  }

  componentDidMount() {
    if (this.state.webdata) {
      this.initBySelectedTemplate(this.getWebdataTemplate() || this.getFirstTemplate());
    } else {
      this.initWithGenderDetection();
    }
  }

  getWebdataTemplate = () => {
    return this.state.webdata
      && this.state.webdata.body
      && this.state.webdata.body.template
      && this.state.bodiesTemplates.find((template) => {
        return template.id === parseInt(this.state.webdata.body.template.id)
          && template.gender === this.state.webdata.body.template.gender;
      });
  };

  getWebdataBackgroundTemplate = () => {
    return this.state.webdata
      && this.state.webdata.background
      && this.state.webdata.background.template
      && this.state.backgroundsTemplates.find((template) => {
        return template.id === parseInt(this.state.webdata.background.template.id);
      });
  };

  initWithGenderDetection = () => {
    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => {
        this.setState({
          genderTask: taskResult,
          gender: taskResult.gender.value,
        }, () => {
          this.initBySelectedTemplate(this.getFirstBodyTemplate());
        });
      })
      .catch((err) => {
        console.error(err);
        this.initBySelectedTemplate(this.getFirstBodyTemplate());
      });
  };

  getFirstBodyTemplate = () => {
    return this.state.bodiesTemplates.find((template) => template.gender === this.state.gender);
  };

  initBySelectedTemplate = (selectedTemplate) => {
    const bodyPos = this.state.bodiesTemplates.findIndex((t) => {
      return t.gender === selectedTemplate.gender
          && t.id === selectedTemplate.id;
    });

    if (bodyPos > 0) {
      this.state.bodiesTemplates.splice(bodyPos, 1);
      this.state.bodiesTemplates.unshift(selectedTemplate);
    }

    const backgroundPos = this.state.backgroundsTemplates.findIndex((t) => {
      return t.id === this.state.selectedBackgroundTemplate.id;
    });

    if (backgroundPos > 0) {
      this.state.backgroundsTemplates.splice(backgroundPos, 1);
      this.state.backgroundsTemplates.unshift(this.state.selectedBackgroundTemplate);
    }

    this.setState({
      isLoading: false,
      gender: selectedTemplate.gender,
      bodiesTemplates: this.state.bodiesTemplates.slice(),
      backgroundsTemplates: this.state.backgroundsTemplates.slice(),
      selectedBodyTemplate: selectedTemplate,
    }, () => {
      this.handleTemplateSelect(selectedTemplate, false);
    });
  };

  getHeadTask = () => {
    if (this.headTaskResult != null && this.headTaskResult.resultUrl) {
      return Promise.resolve(this.headTaskResult);
    }

    const file = this.state.files[0];
    const headConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
      .addMethod(new PhotolabTaskCollageMethod(5779))
      .build();

    return photolabAddTask(headConfig)
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 3000))
      .then((taskResult) => this.headTaskResult = taskResult);
  };

  getBodyTask = (templateId, gender, headImageUrl) => {
    const task = this.bodyTasks.find((t) => {
      return t.templateId === templateId && t.gender === gender;
    });

    if (task && task.result) {
      return task.result;
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(templateId))
      .addImage(new PhotolabTaskImageUrl(headImageUrl))
      .setLanguage(window.clientConfig.lang || "en")
      .build();

    return photolabAddTask(taskConfig)
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 1000))
      .then((taskResult) => {
        this.bodyTasks.push({
          templateId,
          gender,
          result: taskResult
        });

        return taskResult;
      });
  };

  startProcessing = (processing) => {
    this.getHeadTask()
      .then((taskResult) => this.getBodyTask(
        processing.template.id,
        processing.template.gender,
        taskResult.resultUrl
      ))
      .then((taskResult) => photolabAddTask(new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(processing.backgroundTemplate.id))
        .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
        .setLanguage(window.clientConfig.lang || "en")
        .build()))
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 1000, 500))
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult;

        this.setState({processings: this.state.processings.slice()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({processings: this.state.processings.slice()});
      });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
    });

    this.setState({
      selectedBodyTemplateIsUser: !!isUser,
      selectedBodyTemplate: template,
    }, () => {
      this.startTemplate();
    });
  };

  startTemplate = () => {
    let processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedBodyTemplate.id
          && processing.template.gender === this.state.selectedBodyTemplate.gender
          && processing.backgroundTemplate.id === this.state.selectedBackgroundTemplate.id;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = this.state.selectedBodyTemplate;
    processing.backgroundTemplate = this.state.selectedBackgroundTemplate;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  }

  handleSelectBackgroundTemplate = (template) => {
    this.setState({
      selectedBackgroundTemplate: template,
    }, () => {
      this.startTemplate();
    });
  };

  handleConfirmResult = (processing) => {
    if (this.state.view === views.body) {
      this.setState({view: views.background});
      return;
    }

    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      background_template_id: processing.backgroundTemplate.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderTask ? this.state.genderTask.gender.value : null,
      is_user_select: this.state.selectedBodyTemplateIsUser,
      index,
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        body: {
          template: {
            id: processing.template.id,
            gender: processing.template.gender,
          },
        },
        background: {
          template: {
            id: processing.backgroundTemplate.id,
          },
        },
      }),
    });
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    window.localStorage.setItem("bodychooser:gender", gender);
    this.setState({gender});
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedBodyTemplateIsUser,
      index,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const templates = this.state.bodiesTemplates
      .filter((template) => template.gender === this.state.gender);

    const processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedBodyTemplate.id
        && processing.template.gender === this.state.selectedBodyTemplate.gender
        && processing.backgroundTemplate.id === this.state.selectedBackgroundTemplate.id;
    });

    const bodyTask = this.bodyTasks.find((t) => {
      return t.templateId === this.state.selectedBodyTemplate.id
          && t.gender === this.state.selectedBodyTemplate.gender;
    });

    const currentStep = this.state.view === views.body ? 1 : 2;
    const stepTitle = this.state.view === views.body
      ? i18n.t("title__choose_body")
      : i18n.t("title__choose_background");

    return <section className="start-page">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container">
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: currentStep, steps: 2})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: stepTitle}} />
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.body}>
        <div className="container">
          {/*<button className={"btn-choice-gender " + (this.state.gender === config.genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.toggleGender}>*/}
          {/*  <SvgSprite viewBox="0 0 48 48" icon="icon-arrow-back" />*/}
          {/*  <span className="btn-choice-gender-title">*/}
          {/*    {i18n.t(this.state.gender === config.genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}*/}
          {/*    <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />*/}
          {/*  </span>*/}
          {/*</button>*/}

          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedBodyTemplate.id === template.id && this.state.selectedBodyTemplate.gender === template.gender}
            className="halloween-body-background"
            previewUrl={getBackgroundPreivewUrl(this.state.selectedBackgroundTemplate)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
            children={<img src={getBodyPreivewUrl(template)} alt="Preview" />}
          />)}
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.background}>
        <div className="container">
          {this.state.backgroundsTemplates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedBackgroundTemplate.id === template.id}
            className="halloween-body-background"
            previewUrl={getBackgroundPreivewUrl(template)}
            onClick={(template) => this.handleSelectBackgroundTemplate(template)}
            children={bodyTask && bodyTask.result && <img src={bodyTask.result.resultUrl} alt="Body" />}
          />)}
        </div>
      </div>
    </section>;
  }
}

HalloweenBodyBackgroundsPage.contextType = AppContext;
