import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabAddTask,
  photolabGenderTask,
  PhotolabResponseError,
  photolabTask,
  photolabWaitTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {assetUrl} from "../../../utils/etc";
import {decodeWebData, encodeWebData, getStepIdx, getSteps} from "../../../utils/photolab.utils";
import {reorderMaskTemplates} from "./helpers";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

export default class HalloweenHorrorificMaskPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
    gender: config.genders.male,
    genderDetectionResult: null,
    templates: [],
    selectedTemplate: null,
    selectedTemplateIsUser: false,
    processings: [],
    webdata: null,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);

    this.state.gender = window.localStorage.getItem("bodychooser:gender") || this.state.gender;
    this.state.templates = reorderMaskTemplates(config.templates);
    this.state.files = getWebviewInputFilesFromURL();
    this.state.webdata = decodeWebData(window.location.href);
  }

  initWithGenderDetection = () => {
    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => {
        this.setState({
          gender: taskResult.gender.value,
          genderDetectionResult: taskResult.gender,
        }, () => {
          this.initBySelectedTemplate(this.getFirstTemplate());
        });
      })
      .catch((err) => {
        console.error(err);
        this.initBySelectedTemplate(this.getFirstTemplate());
      });
  };

  getWebdataTemplate = () => {
    return this.state.webdata
        && this.state.webdata.body
        && this.state.webdata.body.template
        && this.state.templates.find((template) => {
          return template.id === parseInt(this.state.webdata.body.template.id)
              && template.gender === this.state.webdata.body.template.gender;
        });
  };

  getFirstTemplate = () => {
    return this.state.templates.find((template) => template.gender === this.state.gender);
  };

  initBySelectedTemplate = (selectedTemplate) => {
    const pos = this.state.templates.findIndex((t) => {
      return t.gender === selectedTemplate.gender
          && t.id === selectedTemplate.id
    });

    if (pos > 0) {
      this.state.templates.splice(pos, 1);
      this.state.templates.unshift(selectedTemplate);
    }

    this.setState({
      isLoading: false,
      gender: selectedTemplate.gender,
      templates: this.state.templates.slice(),
    }, () => {
      this.handleTemplateSelect(selectedTemplate, false);
    });
  };

  componentDidMount() {
    if (this.state.webdata) {
      this.initBySelectedTemplate(this.getWebdataTemplate() || this.getFirstTemplate());
    } else {
      this.initWithGenderDetection();
    }
  }

  getCropTask = () => {
    if (this.cropTaskResult != null && this.cropTaskResult.resultUrl) {
      return Promise.resolve(this.cropTaskResult);
    }

    const file = this.state.files[0];
    const headConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
      .addMethod(new PhotolabTaskCollageMethod(4021))
      .build();

    return photolabAddTask(headConfig)
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 2000))
      .then((taskResult) => this.cropTaskResult = taskResult);
  }

  getHeadTask = () => {
    if (this.headTaskResult != null && this.headTaskResult.resultUrl) {
      return Promise.resolve(this.headTaskResult);
    }

    if (this.headTaskPromise) {
      return this.headTaskPromise;
    }

    const file = this.state.files[0];
    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
      .addMethod(new PhotolabTaskCollageMethod(5779))
      .build();

    this.headTaskPromise = photolabTask(taskConfig, 3000)
      .then((taskResult) => this.headTaskResult = taskResult);

    return this.headTaskPromise;
  };

  startProcessing = (processing) => {
    this.getCropTask()
      .then(() => {
        // start preprocessing for head
        this.getHeadTask().then(() => {});

        return photolabTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
          .addImage(new PhotolabTaskImageUrl(this.state.files[0].url))
          .setLanguage(window.clientConfig.lang || "en")
          .build(), 2000);
      })
      .then((taskResult) => {
        return photolabTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(6037))
          .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
          .setLanguage(window.clientConfig.lang || "en")
          .build(), 2000);
      })
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult;

        this.setState({processings: this.state.processings.slice()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({processings: this.state.processings.slice()});
      });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.templates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
    });

    this.setState({
      selectedTemplateIsUser: !!isUser,
      selectedTemplate: template,
    });

    let processing = this.state.processings.find((processing) => {
      return processing.template.id === template.id
          && processing.template.gender === template.gender;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  };

  handleConfirmResult = (processing) => {
    this.setState({isLoading: true});

    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: processing.template.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderDetectionResult,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });

    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const templates = [
      6056, 6057, 6058,
    ];

    this.getHeadTask()
      .then((taskResult) => {
        return photolabTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(templates.random()))
          .addImage(new PhotolabTaskImageUrl(processing.result.resultUrl))
          .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
          .build())
      })
      .then((taskResult) => {
        webviewSendResult({
          status: "OK",
          result_url: encodeURIComponent(taskResult.resultUrl),
          web_data: encodeWebData({
            body: {
              template: {
                id: processing.template.id,
                gender: processing.template.gender,
              },
            },
          }),
        });
      })
      .catch((err) => {
        webviewSendResult({status: "Error", description: err.message});
      });
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    window.localStorage.setItem("bodychooser:gender", gender);
    this.setState({gender});
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      style: this.props.style,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const templates = this.state.templates
      .filter((template) => template.gender === this.state.gender);

    const processing = this.state.selectedTemplate && this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedTemplate.id
          && processing.template.gender === this.state.selectedTemplate.gender;
    });

    const stepIdx = getStepIdx(window.location.href, 2);
    const steps = getSteps(window.location.href, 2);

    return <section className="start-page">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container" hidden={stepIdx === 1 && steps === 1}>
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: stepIdx, steps: steps})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("start_page_title")}}/>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>

          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}
        </div>
      </div>

      <div className="templates-container">
        <div className="container">
          {/*<button className={"btn-choice-gender " + (this.state.gender === config.genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.toggleGender}>*/}
          {/*  <SvgSprite viewBox="0 0 48 48" icon="icon-arrow-back" />*/}
          {/*  <span className="btn-choice-gender-title">*/}
          {/*    {i18n.t(this.state.gender === config.genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}*/}
          {/*    <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />*/}
          {/*  </span>*/}
          {/*</button>*/}

          {templates.map((template) => <TemplateItemView
            key={template.id}
            className="named"
            template={template}
            isActive={this.state.selectedTemplate ? (this.state.selectedTemplate.id === template.id && this.state.selectedTemplate.gender === template.gender) : false}
            previewUrl={assetUrl(`assets/images/halloween/templates/${template.previewFileName || template.id}.jpeg`)}
            children={<span dangerouslySetInnerHTML={{__html: template.name.replace(/\sv2$/, "&nbsp;v2")}} />}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>
    </section>;
  }
}

HalloweenHorrorificMaskPage.contextType = AppContext;