import DumbImageView from "./DumbImageView";
import React from "react";
import PropTypes from "prop-types";

export default function ProcessingResultView(props) {
  const {
    processing,
    onImageLoaded,
    onImageLoadError,
    templateId,
  } = props;

  return <DumbImageView
    className={`collage collage-result--${templateId}`}
    image={processing.result.resultUrl}
    onImageLoaded={onImageLoaded}
    onImageLoadError={onImageLoadError}
  />;
}

ProcessingResultView.propTypes = {
  processing: PropTypes.object.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onImageLoadError: PropTypes.func,
};