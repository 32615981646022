module.exports = {
  "strings": {

    "can_swap_or_continue": "Вы можете поменять лица местами или продолжить",
    "button_tap_to_swap": "Нажмите, чтобы поменять",

    "button_choice_gender_male": "мужские",
    "button_choice_gender_female": "женские",
    "button_choice_gender_text": "стили",
    "btn_retry": "Попробовать ещё раз",

    "step_count_text": "{{step_idx}} из {{steps}}",
    "start_page_title": "Выберите понравившийся стиль",
    "start_page_title_2": "Выберите понравившийся стиль",
    "olympics22__start_page_title" : "За кого вы болеете?",

    "title__choose_body": "Выберите стиль тела",
    "title__choose_crop": "Выберите вариант кадрирования",
    "title__choose_background": "Выберите стиль фона",
    "gfp936_title_choose_crop_backgroud": "Выберите вариант кадрирования и фон",

    "loader_processing": "Обработка",

    "vector_tab_refresh__title": "Почти готово",
    "vector_tab_refresh__subtitle": "Выберите стиль портрета",

    "error_modal__default_message": "Что-то пошло не так...",
    "error_modal__network_message": "Проверьте соединение с Интернетом.",

    "footer_title": "Выберите стиль тела",

    "more_styles": "Больше стилей",
  },
};
