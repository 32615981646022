const genders = {
  male: "male",
  female: "female",
};

const tvTemplate = {id: 5990};
const templatesUsedForTvTemplate = {
  [genders.male]: [5873, 5875, 5878, 5884],
  [genders.female]: [5878, 5876, 5875, 5884],
};

const bodies = [
  {id: 5954, gender: genders.male, previewFileName: "5954m"},
  {id: 5955, gender: genders.male, previewFileName: "5955m"},
  {id: 5956, gender: genders.male, previewFileName: "5956m"},
  {id: 5957, gender: genders.male, previewFileName: "5957m"},
  {id: 5958, gender: genders.male, previewFileName: "5958m"},
  {id: 5959, gender: genders.male, previewFileName: "5959m"},
  {id: 5960, gender: genders.male, previewFileName: "5960m"},
  {id: 5961, gender: genders.male, previewFileName: "5961m"},
  {id: 5962, gender: genders.male, previewFileName: "5962m"},
  {id: 5963, gender: genders.male, previewFileName: "5963m"},
  {id: 5964, gender: genders.male, previewFileName: "5964m"},
  {id: 6025, gender: genders.male, previewFileName: "6025m"},
  {id: 6026, gender: genders.male, previewFileName: "6026m"},
  {id: 6028, gender: genders.male, previewFileName: "6028m"},
  {id: 6029, gender: genders.male, previewFileName: "6029m"},
  // --
  {id: 5954, gender: genders.female, previewFileName: "5954f"},
  {id: 5955, gender: genders.female, previewFileName: "5955f"},
  {id: 5956, gender: genders.female, previewFileName: "5956f"},
  {id: 5957, gender: genders.female, previewFileName: "5957f"},
  {id: 5958, gender: genders.female, previewFileName: "5958f"},
  {id: 5959, gender: genders.female, previewFileName: "5959f"},
  {id: 5960, gender: genders.female, previewFileName: "5960f"},
  {id: 5961, gender: genders.female, previewFileName: "5961f"},
  {id: 5962, gender: genders.female, previewFileName: "5962f"},
  {id: 5963, gender: genders.female, previewFileName: "5963f"},
  {id: 5964, gender: genders.female, previewFileName: "5964f"},
  {id: 6025, gender: genders.female, previewFileName: "6025f"},
  {id: 6026, gender: genders.female, previewFileName: "6026f"},
  {id: 6027, gender: genders.female},
  {id: 6028, gender: genders.female, previewFileName: "6028f"},
  {id: 6029, gender: genders.female, previewFileName: "6029f"},
];

const transparentBodies = [
  {id: 5995, gender: genders.male, previewFileName: "5995m", weight: 10},
  {id: 5996, gender: genders.male, previewFileName: "5996m", weight: 10},
  {id: 5997, gender: genders.male, previewFileName: "5997m"},
  {id: 5998, gender: genders.male, previewFileName: "5998m"},
  {id: 5999, gender: genders.male, previewFileName: "5999m"},
  {id: 6000, gender: genders.male, previewFileName: "6000m"},
  {id: 6006, gender: genders.male, previewFileName: "6006m"},
  {id: 6007, gender: genders.male, previewFileName: "6007m"},
  {id: 6008, gender: genders.male, previewFileName: "6008m", weight: 10},
  {id: 6009, gender: genders.male, previewFileName: "6009m"},
  {id: 6010, gender: genders.male, previewFileName: "6010m", weight: 10},
  // --
  {id: 5995, gender: genders.female, previewFileName: "5995f", weight: 10},
  {id: 5996, gender: genders.female, previewFileName: "5996f", weight: 10},
  {id: 5997, gender: genders.female, previewFileName: "5997f"},
  {id: 5998, gender: genders.female, previewFileName: "5998f"},
  {id: 5999, gender: genders.female, previewFileName: "5999f"},
  {id: 6000, gender: genders.female, previewFileName: "6000f"},
  {id: 6006, gender: genders.female, previewFileName: "6006f"},
  {id: 6007, gender: genders.female, previewFileName: "6007f"},
  {id: 6008, gender: genders.female, previewFileName: "6008f", weight: 10},
  {id: 6009, gender: genders.female, previewFileName: "6009f"},
  {id: 6010, gender: genders.female, previewFileName: "6010f", weight: 10},
  // --
  {id: 6061, gender: genders.female, previewFileName: "6061f", weight: 10},
  {id: 6061, gender: genders.male, previewFileName: "6061m", weight: 10},
  {id: 6062, gender: genders.female, previewFileName: "6062f"},
  {id: 6062, gender: genders.male, previewFileName: "6062m"},
  {id: 6071, gender: genders.female, weight: 10},
  {id: 6059, gender: genders.female, previewFileName: "6059f", weight: 10},
  {id: 6059, gender: genders.male, previewFileName: "6059m", weight: 10},
  {id: 6063, gender: genders.female, previewFileName: "6063f"},
  {id: 6063, gender: genders.male, previewFileName: "6063m"},
  {id: 6072, gender: genders.female, previewFileName: "6072f"},
  {id: 6072, gender: genders.male, previewFileName: "6072m"},
  {id: 6064, gender: genders.female, previewFileName: "6064f", weight: 10},
  {id: 6064, gender: genders.male, previewFileName: "6064m", weight: 10},
  {id: 6066, gender: genders.female},
  {id: 6065, gender: genders.male},
  {id: 6067, gender: genders.female, previewFileName: "6067f"},
  {id: 6067, gender: genders.male, previewFileName: "6067m"},
  {id: 6069, gender: genders.female, previewFileName: "6069f"},
  {id: 6069, gender: genders.male, previewFileName: "6069m"},
  {id: 6068, gender: genders.female},
  {id: 6070, gender: genders.female, weight: 10},
  // --
  {id: 6081, gender: genders.female, weight: 10},
  {id: 6097, gender: genders.male, weight: 10},
  {id: 6093, gender: genders.female, weight: 10},
  {id: 6094, gender: genders.male},
  {id: 6082, gender: genders.male, previewFileName: "6082m"},
  {id: 6082, gender: genders.female, previewFileName: "6082f"},
  {id: 6098, gender: genders.male, previewFileName: "6098m", weight: 10},
  {id: 6098, gender: genders.female, previewFileName: "6098f", weight: 10},
  {id: 6095, gender: genders.male, previewFileName: "6095m"},
  {id: 6095, gender: genders.female, previewFileName: "6095f"},
  {id: 6096, gender: genders.male, previewFileName: "6096m"},
  {id: 6096, gender: genders.female, previewFileName: "6096f"},
  // --
  {id: 6138, gender: genders.female, weight: 10},
  {id: 6141, gender: genders.male, weight: 10},
  {id: 6143, gender: genders.female, weight: 10},
  {id: 6140, gender: genders.male},
  {id: 6144, gender: genders.female, weight: 10},
];

const transparentBodiesBackgrounds = [
  {id: 6011},
  {id: 6012},
  {id: 6013},
  {id: 6014},
  {id: 6015},
  {id: 6016},
  {id: 6017},
  {id: 6018},
  {id: 6019},
  {id: 6047},
  {id: 6048},
  {id: 6049},
  {id: 6050},
  {id: 6051},
  {id: 6052},
  {id: 6053},
  {id: 6054},
  {id: 6055},
];

const templates = [

  {id: 5873, gender: genders.male, name: "Frankenstein", weight: 10},
  {id: 5874, gender: genders.male, name: "Jack Skellington"},
  {id: 5875, gender: genders.male, name: "Goblin", weight: 10},
  {id: 5876, gender: genders.male, name: "Corpse Bride"},
  {id: 5877, gender: genders.male, name: "Calavera Makeup"},
  {id: 5878, gender: genders.male, name: "Zombie v2", weight: 10},
  {id: 5879, gender: genders.male, name: "Corpse Bride v2"},
  {id: 5880, gender: genders.male, name: "The Addams Family"},
  {id: 5881, gender: genders.male, name: "Zombie", weight: 10},
  {id: 5882, gender: genders.male, name: "Evil Santa"},
  {id: 5883, gender: genders.male, name: "Scary Nurse", weight: 10},
  {id: 5884, gender: genders.male, name: "Joker", weight: 10},
  {id: 5885, gender: genders.male, name: "Harley Quinn"},
  {id: 5886, gender: genders.male, name: "Evil Doll"},
  {id: 5887, gender: genders.male, name: "Creepy Doll"},
  {id: 5888, gender: genders.male, name: "Michael Myers"},
  {id: 5889, gender: genders.male, name: "Hotel Transylvania"},
  {id: 5890, gender: genders.male, name: "Drunk Clown"},
  {id: 5891, gender: genders.male, name: "Scary Clown v2", weight: 10},
  {id: 5892, gender: genders.male, name: "Scary Clown"},
  {id: 5893, gender: genders.male, name: "Demon", weight: 10},
  {id: 5894, gender: genders.male, name: "Killer Robot"},
  {id: 5895, gender: genders.male, name: "Creepy Banshee", weight: 10},
  {id: 5896, gender: genders.male, name: "Evil Mummy"},
  {id: 5897, gender: genders.male, name: "Chucky"},
  {id: 5898, gender: genders.male, name: "Chucky v2"},
  {id: 5899, gender: genders.male, name: "Halloween Ghoul"},
  {id: 5900, gender: genders.male, name: "Gremlin", weight: 10},
  {id: 5901, gender: genders.male, name: "Troll"},
  {id: 5902, gender: genders.male, name: "Lord Voldemort"},
  {id: 5903, gender: genders.male, name: "Creepy Mummy"},
  {id: 5907, gender: genders.male, name: "Gremlin v2", weight: 10},
  {id: 5908, gender: genders.male, name: "Beetlejuice"},
  {id: 5909, gender: genders.male, name: "Orc"},
  {id: 5910, gender: genders.male, name: "Skull Face"},
  {id: 5911, gender: genders.male, name: "Mysterious Sorceress"},
  {id: 5912, gender: genders.male, name: "Judge Doom"},
  {id: 5913, gender: genders.male, name: "Monsters"},
  {id: 5914, gender: genders.male, name: "Lord Voldemort v2"},
  {id: 5915, gender: genders.male, name: "Devil Face"},
  {id: 5916, gender: genders.male, name: "Boogeyman"},
  {id: 5917, gender: genders.male, name: "Dracula"},
  {id: 5918, gender: genders.male, name: "Annabelle Doll"},
  {id: 5919, gender: genders.male, name: "Annabelle Doll v2"},
  {id: 5920, gender: genders.male, name: "Calavera Makeup v2"},
  {id: 5921, gender: genders.male, name: "Creepy Banshee v2", weight: 10},
  {id: 5925, gender: genders.male, name: "Haunted"},
  {id: 5926, gender: genders.male, name: "Vampire"},
  {id: 5927, gender: genders.male, name: "Werewolf", weight: 10},
  {id: 5928, gender: genders.male, name: "Evil Goblin", weight: 10},
  {id: 6080, gender: genders.male, name: "Evil Doll"},
  {id: 6100, gender: genders.male, name: "Pontianak"},
  {id: 6101, gender: genders.male, name: "The Evil Within", weight: 10},
  {id: 6102, gender: genders.male, name: "Drunk Doll"},
  {id: 6103, gender: genders.male, name: "Oni"},
  {id: 6104, gender: genders.male, name: "Drunk Creepy Doll"},
  {id: 6105, gender: genders.male, name: "Mysterious Ghost", weight: 10},
  {id: 6106, gender: genders.male, name: "Zombie Doll"},
  {id: 6107, gender: genders.male, name: "Creepy Mermaid"},
  {id: 6132, gender: genders.male, name: "Evil Doll"}, // specials
  {id: 6133, gender: genders.male, name: "Evil Doll"}, // specials
  {id: 6134, gender: genders.male, name: "Evil Doll"}, // specials
  {id: 6142, gender: genders.male, name: "Evil Doll"}, // specials
  {id: 6112, gender: genders.male, name: "Scary Stories: Harold"},
  {id: 6113, gender: genders.male, name: "Zelda Witch"},
  {id: 6114, gender: genders.male, name: "Chucky: Tiffany dolls"},
  {id: 6115, gender: genders.male, name: "Iron Maiden Killers Mask"},
  {id: 6116, gender: genders.male, name: "The Hobbit: Azog"},
  {id: 6117, gender: genders.male, name: "Imhotep"},
  {id: 6118, gender: genders.male, name: "Billy the Puppet"},
  {id: 6119, gender: genders.male, name: "Little Nightmare Monsters"},
  {id: 6120, gender: genders.male, name: "The Evil Within v2"},
  {id: 6121, gender: genders.male, name: "Mysterious Ghost v2"},
  {id: 6122, gender: genders.male, name: "Zombie Doll v2"},
  {id: 6123, gender: genders.male, name: "Evil Puppet"},
  {id: 6129, gender: genders.male, name: "Blackout"},
  {id: 6130, gender: genders.male, name: "Depresso"},

  {id: 5873, gender: genders.female, name: "Frankenstein"},
  {id: 5874, gender: genders.female, name: "Jack Skellington"},
  {id: 5875, gender: genders.female, name: "Goblin", weight: 10},
  {id: 5876, gender: genders.female, name: "Corpse Bride", weight: 10},
  {id: 5877, gender: genders.female, name: "Calavera Makeup"},
  {id: 5878, gender: genders.female, name: "Zombie v2", weight: 10},
  {id: 5879, gender: genders.female, name: "Corpse Bride v2"},
  {id: 5880, gender: genders.female, name: "The Addams Family"},
  {id: 5881, gender: genders.female, name: "Zombie", weight: 10},
  {id: 5882, gender: genders.female, name: "Evil Santa"},
  {id: 5883, gender: genders.female, name: "Scary Nurse", weight: 10},
  {id: 5884, gender: genders.female, name: "Joker", weight: 10},
  {id: 5885, gender: genders.female, name: "Harley Quinn", weight: 10},
  {id: 5886, gender: genders.female, name: "Evil Doll"},
  {id: 5887, gender: genders.female, name: "Creepy Doll"},
  {id: 5888, gender: genders.female, name: "Michael Myers"},
  {id: 5889, gender: genders.female, name: "Hotel Transylvania"},
  {id: 5890, gender: genders.female, name: "Drunk Clown"},
  {id: 5891, gender: genders.female, name: "Scary Clown v2"},
  {id: 5892, gender: genders.female, name: "Scary Clown"},
  {id: 5893, gender: genders.female, name: "Demon"},
  {id: 5894, gender: genders.female, name: "Killer Robot"},
  {id: 5895, gender: genders.female, name: "Creepy Banshee", weight: 10},
  {id: 5896, gender: genders.female, name: "Evil Mummy"},
  {id: 5897, gender: genders.female, name: "Chucky"},
  {id: 5898, gender: genders.female, name: "Chucky v2"},
  {id: 5899, gender: genders.female, name: "Halloween Ghoul"},
  {id: 5900, gender: genders.female, name: "Gremlin"},
  {id: 5901, gender: genders.female, name: "Troll", weight: 10},
  {id: 5902, gender: genders.female, name: "Lord Voldemort"},
  {id: 5903, gender: genders.female, name: "Creepy Mummy"},
  {id: 5907, gender: genders.female, name: "Gremlin v2", weight: 10},
  {id: 5908, gender: genders.female, name: "Beetlejuice"},
  {id: 5909, gender: genders.female, name: "Orc"},
  {id: 5910, gender: genders.female, name: "Skull Face"},
  {id: 5911, gender: genders.female, name: "Mysterious Sorceress"},
  {id: 5912, gender: genders.female, name: "Judge Doom"},
  {id: 5913, gender: genders.female, name: "Monsters"},
  {id: 5914, gender: genders.female, name: "Lord Voldemort v2"},
  {id: 5915, gender: genders.female, name: "Devil Face"},
  {id: 5916, gender: genders.female, name: "Boogeyman"},
  {id: 5917, gender: genders.female, name: "Dracula"},
  {id: 5918, gender: genders.female, name: "Annabelle Doll"},
  {id: 5919, gender: genders.female, name: "Annabelle Doll v2"},
  {id: 5920, gender: genders.female, name: "Calavera Makeup v2"},
  {id: 5921, gender: genders.female, name: "Creepy Banshee v2", weight: 10},
  {id: 5925, gender: genders.female, name: "Haunted"},
  {id: 5926, gender: genders.female, name: "Vampire", weight: 10},
  {id: 5927, gender: genders.female, name: "Werewolf"},
  {id: 5928, gender: genders.female, name: "Evil Goblin"},
  {id: 6080, gender: genders.female, name: "Evil Doll"},
  {id: 6100, gender: genders.female, name: "Pontianak"},
  {id: 6101, gender: genders.female, name: "The Evil Within"},
  {id: 6102, gender: genders.female, name: "Drunk Doll"},
  {id: 6103, gender: genders.female, name: "Oni"},
  {id: 6104, gender: genders.female, name: "Drunk Creepy Doll"},
  {id: 6105, gender: genders.female, name: "Mysterious Ghost"},
  {id: 6106, gender: genders.female, name: "Zombie Doll"},
  {id: 6107, gender: genders.female, name: "Creepy Mermaid"},
  {id: 6132, gender: genders.female, name: "Evil Doll"}, // specials
  {id: 6133, gender: genders.female, name: "Evil Doll"}, // specials
  {id: 6134, gender: genders.female, name: "Evil Doll"}, // specials
  {id: 6142, gender: genders.female, name: "Evil Doll"}, // specials
  {id: 6112, gender: genders.female, name: "Scary Stories: Harold"},
  {id: 6113, gender: genders.female, name: "Zelda Witch"},
  {id: 6114, gender: genders.female, name: "Chucky: Tiffany dolls"},
  {id: 6115, gender: genders.female, name: "Iron Maiden Killers Mask"},
  {id: 6116, gender: genders.female, name: "The Hobbit: Azog"},
  {id: 6117, gender: genders.female, name: "Imhotep"},
  {id: 6118, gender: genders.female, name: "Billy the Puppet"},
  {id: 6119, gender: genders.female, name: "Little Nightmare Monsters"},
  {id: 6120, gender: genders.female, name: "The Evil Within v2"},
  {id: 6121, gender: genders.female, name: "Mysterious Ghost v2"},
  {id: 6122, gender: genders.female, name: "Zombie Doll v2"},
  {id: 6123, gender: genders.female, name: "Evil Puppet"},
  {id: 6129, gender: genders.female, name: "Blackout"},
  {id: 6130, gender: genders.female, name: "Depresso"},

];

export default {
  genders,
  templates,
  bodies,
  tvTemplate,
  templatesUsedForTvTemplate,
  transparentBodies,
  transparentBodiesBackgrounds,
}