module.exports = {
  "strings": {

    "can_swap_or_continue": "Yüz değiştirebilir veya olduğu gibi devam edebilirsin",
    "button_tap_to_swap": "Değiştirmek için dokun",

    "button_choice_gender_male": "erkek",
    "button_choice_gender_female": "kadın",
    "button_choice_gender_text": "stiller",
    "btn_retry": "Yeniden dene",

    "step_count_text": "Adım {{step_idx}}/{{steps}}",
    "start_page_title": "En beğendiğin stili seç",
    "start_page_title_2": "En beğendiğin stili seç",
    "olympics22__start_page_title" : "Kimi destekliyorsun?",

    "title__choose_body": "Vücut stilini seç",
    "title__choose_crop": "Vücut stilini seç",
    "title__choose_background": "Arka plan stili seç",
    "gfp936_title_choose_crop_backgroud": "Portre kırpmayı ve arka planı seç",

    "loader_processing": "İşleme",

    "vector_tab_refresh__subtitle": "Baş stilini seç",

    "error_modal__default_message": "Bir şeyler ters gitti. Lütfen tekrar deneyin.",
    "error_modal__network_message": "Lütfen internet bağlantısını kontrol edin.",

    "footer_title": "Vücut stilini seç",
  },
};
