module.exports = {
  "strings": {

    "can_swap_or_continue": "Puedes cambiar de cara o seguir tal como está",
    "button_tap_to_swap": "Toca para cambiar",

    "button_choice_gender_male": "masculinos",
    "button_choice_gender_female": "femeninos",
    "button_choice_gender_text": "estilos",
    "btn_retry": "Reintentar",

    "step_count_text": "{{step_idx}} de {{steps}}",
    "start_page_title": "Elige el estilo que más te guste",
    "start_page_title_2": "Elige el estilo que más te guste",
    "olympics22__start_page_title" : "¿A quién estás apoyando?",

    "title__choose_body": "Elige el estilo de cuerpo",
    "title__choose_crop": "Elige el estilo de cuerpo",
    "title__choose_background": "Elige un estilo de fondo",
    "gfp936_title_choose_crop_backgroud": "Elija el encuadre y el fondo de la foto",

    "loader_processing": "Procesando",

    "vector_tab_refresh__title": "¡Ya casi está!",
    "vector_tab_refresh__subtitle": "Elige el estilo de cabeza",

    "error_modal__default_message": "Algo salió mal...",
    "error_modal__network_message": "Por favor revisa tu conexión a Internet.",

    "footer_title": "Elige el estilo de cuerpo",

    "more_styles": "Más estilos",
  },
};
