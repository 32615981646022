import React from "react";
import {Route} from "react-router";
import HalloweenPage from "./HalloweenPage";
import HalloweenBodyPage from "./HalloweenBodyPage";
import HalloweenTvPage from "./HalloweenTvPage";
import HalloweenBodyBackgroundsPage from "./HalloweenBodyBackgroundsPage";
import HalloweenBootifulToonPage from "./HalloweenBootifulToonPage";
import HalloweenHorrorificMaskPage from "./HalloweenHorrorificMaskPage";

const routes = [{
  key: "halloweenRoute",
  path: "/halloween",
  render: (props) => <HalloweenPage {...props} pageName="halloween" />,
}, {
  key: "halloweenBodyRoute",
  path: "/halloweenbody",
  render: (props) => <HalloweenBodyPage {...props} pageName="halloweenbody" />,
}, {
  key: "halloweenBodyBackgroundsRoute",
  path: "/halloweenbodybackgrounds",
  render: (props) => <HalloweenBodyBackgroundsPage {...props} pageName="halloweenbodybackgrounds" />,
}, {
  key: "halloweenTvRoute",
  path: "/halloweentv",
  render: (props) => <HalloweenTvPage {...props} pageName="halloweentv" />,
}, {
  key: "halloweenBootifulToonRoute",
  path: "/halloween-bootiful-toon",
  render: (props) => <HalloweenBootifulToonPage {...props} pageName="halloween-bootiful-toon" />,
}, {
  key: "halloweenHorrorificMaskRoute",
  path: "/halloween-horrorific-mask",
  render: (props) => <HalloweenHorrorificMaskPage {...props} pageName="halloweenhorrorificmask" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}