import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabTask} from "../../../photolab/api";
import {getWebviewInputFilesFromURL, webviewSendResult} from "../../../utils/webview";
import i18n from "../../../i18n";
import Loader from "../../components/Loader";
import {logCreativeFailed, logCreativeProcessed} from "../../../utils/log";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

const userTemplateId = 7458;
const finalTemplateId = 7462;

export default class Hw2022_1Page extends React.Component {

  constructor(props) {
    super(props);
    this.files = getWebviewInputFilesFromURL();
  }

  componentDidMount() {
    const processing = {
      startedAt: Date.now()
    };

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(userTemplateId))
      .addImage(new PhotolabTaskImageUrl(this.files[0].url))
      .build();

    photolabTask(taskConfig)
      .then((taskResult) => {
        const taskConfig = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(finalTemplateId))
          .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
          .addImage(new PhotolabTaskImageUrl((this.files[1] || this.files[0]).url))
          .build();

      return photolabTask(taskConfig);
    }).then((taskResult) => {
      processing.finishedAt = Date.now();

      hitPhotoProcessed();
      logCreativeProcessed(this.props.pageName, processing);

      if (window.appConfig.isDebug) {
        console.log(taskResult.resultUrl);
      }

      webviewSendResult({
        status: "OK",
        result_url: encodeURIComponent(taskResult.resultUrl),
      });
    }).catch((err) => {
      processing.finishedAt = Date.now();

      hitPhotoFailed();
      logCreativeFailed(this.props.pageName, processing);

      webviewSendResult({
        status: "Error",
        description: err.message,
      });
    });
  }

  render() {
    return <Loader message={i18n.t("loader_processing")} />;
  }
}

Hw2022_1Page.contextType = AppContext;
