const styles = {
  vector: "vector",
  toon: "toon",
  disney: "disney",
};

const genders = {
  male: "male",
  female: "female",
};

const templates = [

  // {
  //   style (стиль шаблона)
  //   id (номер шаблона)
  //   gender (к какому полу принадлежит шаблон)
  //   previewFileName (используется для переопределения имени файла превьюшки)
  //   suppressed (если true, то шаблон никогда не станет первым в списке)
  // }

  // vector
  {style: styles.vector, id: 5243, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5246, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5255, gender: genders.female},
  {style: styles.vector, id: 5256, gender: genders.female},
  {style: styles.vector, id: 5254, gender: genders.female},
  {style: styles.vector, id: 5253, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5258, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5214, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5129, gender: genders.male},
  {style: styles.vector, id: 5131, gender: genders.male},
  {style: styles.vector, id: 5123, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5206, gender: genders.male, previewFileName: "5206m", isHidden: true},
  {style: styles.vector, id: 5206, gender: genders.female, previewFileName: "5206f", isHidden: true},
  {style: styles.vector, id: 5208, gender: genders.male},
  {style: styles.vector, id: 5173, gender: genders.male},
  {style: styles.vector, id: 5171, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5149, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5150, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5151, gender: genders.male},
  {style: styles.vector, id: 5152, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5153, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5154, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5126, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5125, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5095, gender: genders.male},
  {style: styles.vector, id: 5094, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5093, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5092, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5088, gender: genders.male},
  {style: styles.vector, id: 5087, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5074, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5211, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5080, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5082, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5085, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5086, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5213, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5204, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5205, gender: genders.female},
  {style: styles.vector, id: 5207, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5212, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5174, gender: genders.female},
  {style: styles.vector, id: 5172, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5128, gender: genders.female},
  {style: styles.vector, id: 5127, gender: genders.female},
  {style: styles.vector, id: 5104, gender: genders.female},
  {style: styles.vector, id: 5096, gender: genders.female},
  {style: styles.vector, id: 5083, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5075, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5081, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5084, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5350, gender: genders.female, previewFileName: "5350f", isHidden: true},
  {style: styles.vector, id: 5350, gender: genders.male, previewFileName: "5350m", isHidden: true},
  {style: styles.vector, id: 5349, gender: genders.female, previewFileName: "5349f", suppressed: true},
  {style: styles.vector, id: 5349, gender: genders.male, previewFileName: "5349m", suppressed: true},
  {style: styles.vector, id: 5342, gender: genders.female, previewFileName: "5342f", isHidden: true},
  {style: styles.vector, id: 5342, gender: genders.male, previewFileName: "5342m", isHidden: true},
  {style: styles.vector, id: 5334, gender: genders.female, previewFileName: "5334f"},
  {style: styles.vector, id: 5335, gender: genders.female},
  {style: styles.vector, id: 5299, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5287, gender: genders.male},
  {style: styles.vector, id: 5354, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5353, gender: genders.female},
  {style: styles.vector, id: 5352, gender: genders.female},
  {style: styles.vector, id: 5351, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5341, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5333, gender: genders.female},
  {style: styles.vector, id: 5298, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5297, gender: genders.female},
  {style: styles.vector, id: 5293, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5290, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5291, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5288, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5272, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5268, gender: genders.female},
  {style: styles.vector, id: 5264, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5262, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5261, gender: genders.female},
  {style: styles.vector, id: 5263, gender: genders.female},
  {style: styles.vector, id: 5372, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5371, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5364, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5363, gender: genders.female},
  {style: styles.vector, id: 5358, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5369, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5360, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5362, gender: genders.male},
  {style: styles.vector, id: 5373, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5374, gender: genders.male, previewFileName: "5374m"},
  {style: styles.vector, id: 5374, gender: genders.female, previewFileName: "5374f"},
  {style: styles.vector, id: 5359, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5375, gender: genders.male},
  {style: styles.vector, id: 5376, gender: genders.male},
  {style: styles.vector, id: 5377, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5383, gender: genders.male},
  {style: styles.vector, id: 5387, gender: genders.male},
  {style: styles.vector, id: 5388, gender: genders.male},
  {style: styles.vector, id: 5400, gender: genders.male},
  {style: styles.vector, id: 5402, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5403, gender: genders.male},
  {style: styles.vector, id: 5404, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5405, gender: genders.male},
  {style: styles.vector, id: 5406, gender: genders.male},
  {style: styles.vector, id: 5407, gender: genders.male},
  {style: styles.vector, id: 5410, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5412, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5413, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5414, gender: genders.male, isHidden: true},
  {style: styles.vector, id: 5415, gender: genders.male},
  {style: styles.vector, id: 5411, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5416, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5401, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5419, gender: genders.female},
  {style: styles.vector, id: 5420, gender: genders.female},
  {style: styles.vector, id: 5421, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5422, gender: genders.female},
  {style: styles.vector, id: 5423, gender: genders.female},
  {style: styles.vector, id: 5424, gender: genders.female},
  {style: styles.vector, id: 5425, gender: genders.female},
  {style: styles.vector, id: 5426, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5430, gender: genders.female, previewFileName: "5430f", isHidden: true},
  {style: styles.vector, id: 5430, gender: genders.male, previewFileName: "5430m", isHidden: true},
  {style: styles.vector, id: 5431, gender: genders.female},
  {style: styles.vector, id: 5432, gender: genders.female, isHidden: true},
  {style: styles.vector, id: 5434, gender: genders.male},
  {style: styles.vector, id: 5439, gender: genders.female},
  {style: styles.vector, id: 5442, gender: genders.male},
  {style: styles.vector, id: 5470, gender: genders.male},
  {style: styles.vector, id: 5101, gender: genders.male, previewFileName: "5101m"},
  {style: styles.vector, id: 5101, gender: genders.female, previewFileName: "5101f"},
  {style: styles.vector, id: 5483, gender: genders.female},
  {style: styles.vector, id: 5523, gender: genders.male},
  {style: styles.vector, id: 5524, gender: genders.male},
  {style: styles.vector, id: 5526, gender: genders.male},
  {style: styles.vector, id: 5545, gender: genders.male},
  {style: styles.vector, id: 5564, gender: genders.male},
  {style: styles.vector, id: 5544, gender: genders.female},
  {style: styles.vector, id: 5546, gender: genders.female},
  {style: styles.vector, id: 5547, gender: genders.female},
  {style: styles.vector, id: 5548, gender: genders.female},
  {style: styles.vector, id: 5549, gender: genders.female},
  {style: styles.vector, id: 5550, gender: genders.female},
  {style: styles.vector, id: 5551, gender: genders.female},
  {style: styles.vector, id: 5552, gender: genders.female},
  {style: styles.vector, id: 5554, gender: genders.female},
  {style: styles.vector, id: 5563, gender: genders.female},
  {style: styles.vector, id: 5678, gender: genders.female},
  {style: styles.vector, id: 5679, gender: genders.female},
  {style: styles.vector, id: 5680, gender: genders.female},
  // toon
  {style: styles.toon, id: 5100, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5101, gender: genders.male, previewFileName: "5101m"},
  {style: styles.toon, id: 5101, gender: genders.female, previewFileName: "5101f"},
  {style: styles.toon, id: 5196, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5197, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5198, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5218, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5219, gender: genders.female},
  {style: styles.toon, id: 5220, gender: genders.female, isHidden: true},
  {style: styles.toon, id: 5221, gender: genders.male},
  {style: styles.toon, id: 5224, gender: genders.male, isHidden: true},
  {style: styles.toon, id: 5241, gender: genders.male},
  {style: styles.toon, id: 5242, gender: genders.female},

  // disney
  {style: styles.disney, id: 5309, gender: genders.female},
  {style: styles.disney, id: 5310, gender: genders.female},
  {style: styles.disney, id: 5311, gender: genders.female},
  {style: styles.disney, id: 5313, gender: genders.female},
  {style: styles.disney, id: 5314, gender: genders.female},
  {style: styles.disney, id: 5315, gender: genders.female},
  {style: styles.disney, id: 5316, gender: genders.female},
  {style: styles.disney, id: 5318, gender: genders.female},
  {style: styles.disney, id: 5320, gender: genders.female},
  {style: styles.disney, id: 5324, gender: genders.female},
  {style: styles.disney, id: 5325, gender: genders.female},
  {style: styles.disney, id: 5326, gender: genders.female},
  {style: styles.disney, id: 5327, gender: genders.female},
  {style: styles.disney, id: 5312, gender: genders.male},
  {style: styles.disney, id: 5317, gender: genders.male},
  {style: styles.disney, id: 5319, gender: genders.male},
  {style: styles.disney, id: 5321, gender: genders.male},
  {style: styles.disney, id: 5322, gender: genders.male},
  {style: styles.disney, id: 5323, gender: genders.male},
  {style: styles.disney, id: 5328, gender: genders.male},
];

export default {
  styles,
  genders,
  templates,
}
