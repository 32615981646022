module.exports = {
  "strings": {

    "can_swap_or_continue": "يمكنك تبديل الأوجه أو المتابعة بها كما هي",
    "button_tap_to_swap": "انقر للتبديل",

    "button_choice_gender_male": "الذكور",
    "button_choice_gender_female": "النساء",
    "button_choice_gender_text": "أنماط",
    "btn_retry": "أعد المحاولة",

    "step_count_text": "الخطوة {{step_idx}} من {{steps}}",
    "start_page_title": "اختر النمط الذي يعجبك أكثر",
    "start_page_title_2": "اختر النمط الذي يعجبك أكثر",
    "olympics22__start_page_title" : "من تشجع؟",

    "title__choose_body": "اختر نمط الجسم",
    "title__choose_crop": "اختر نمط الجسم",
    "title__choose_background": "اختر نمط الخلفية",
    "gfp936_title_choose_crop_backgroud": "اختر طريقة قص البورتريه والخلفية",

    "loader_processing": "طور الإنجاز",

    "vector_tab_refresh__subtitle": "اختر نمط الرأس",

    "error_modal__default_message": " حدث خطأ ما...",
    "error_modal__network_message": "المرجو التحقق من اتصالكم بالإنترنت.",

    "footer_title": "اختر نمط الجسم",
  },
};
