import React from "react";
import {Route} from "react-router";
import CryptoLogoChooserPage from "./CryptoLogoChooserPage";

const routes = [{
  key: "t6756-crypto-logo-chooser",
  path: "/t6756-crypto-logo-chooser",
  render: (props) => <CryptoLogoChooserPage
    {...props}
    pageName="t6756-crypto-logo-chooser"
  />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}
