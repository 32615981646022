import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError, photolabSimpleTask,
  photolabTask
} from "../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../utils/webview";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../utils/log";
import Loader from "../components/Loader";
import ProcessingErrorView from "../components/ProcessingErrorView";
import ProcessingResultView from "../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../utils/hits";
import TemplateItemView2 from "../components/TemplateItemView2";

const processingTexts = [
  "AI is looking for a stunning<br />look for you 🔍",
  "Any idea what it’s gonna<br />look like? 🙈",
  "Just wait for it… 🪄",
  "AI’s fantasy is coming true 🧚",
];

const templates = {
  newSkin: {
    id: 8658,
    canvasId: 8661,
    maskId: 8662,
  },
  newHead: {
    id: 8658,
    canvasId: 8663,
    maskId: 8664,
  },
};

const defaultNegativePrompt = "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo";
const configs = [
  {
    id: "art",
    seed: 421,
    prompt: "impressionism, 8k, masterpiece, fine detail, full of color, intricate detail, golden ratio illustration, trending on artstation",
    promptNegative: defaultNegativePrompt,
  },
  {
    id: "watercolor",
    seed: 22614,
    prompt: "(watercolour:1.5), digital painting, 8k, masterpiece, fine detail, full of color, intricate detail, golden ratio illustration, trending on artstation, HD",
    promptNegative: defaultNegativePrompt,
  },
  {
    id: "old_comics",
    seed: 324222,
    prompt: "(old comics style!!!), digital painting, 8k, old comics style background, masterpiece, fine detail, full of color, intricate detail, golden ratio illustration, trending on artstation, HD",
    promptNegative: defaultNegativePrompt,
  },
  {
    id: "pastel",
    seed: 1327722,
    prompt: "(pastel!!!), digital painting, 8k, pastel background, masterpiece, fine detail, full of color, intricate detail, golden ratio illustration, trending on artstation, HD",
    promptNegative: defaultNegativePrompt,
  },
  {
    id: "art_nouveau",
    seed: 324334,
    prompt: "Alphonse Mucha style portrait, art-nouveau style elements, highly detailed, high resolution",
    promptNegative: defaultNegativePrompt,
  },
].shuffle();

const strengths = {
  low: 0.4,
  medium: 0.5,
  super: 0.6,
};

function getConfigById(id) {
  return configs.find((c) => c.id === id);
}

export default class DreamFilterPage extends React.Component {

  state = {
    isLoading: true,
    configId: configs.first().id,
    strength: strengths.low,
    hairStylization: false,
  };

  constructor(props) {
    super(props);

    this.noUI = window.location.href.indexOf("no_ui=1") > 0;
    this.files = getWebviewInputFilesFromURL();

    const webdata = decodeWebData(window.location.href);
    if (webdata) {
      const webDataConfig = getConfigById(webdata.configId);
      this.state.configId = (webDataConfig && webDataConfig.id) || this.state.configId;
      this.state.strength = webdata.strength || this.state.strength;
      this.state.hairStylization = webdata.hair_stylization || this.state.hairStylization;
    }

    this.processings = [
      this.createProcessing(
        getConfigById(this.state.configId),
        this.state.hairStylization,
        this.state.strength,
      ),
    ];
  }

  componentDidMount() {
    this.startProcessing(this.processings.first());
  }

  createProcessing = (config, hairStylization, strength) => {
    const template = hairStylization
      ? templates.newSkin
      : templates.newHead;

    return {
      id: Date.now(),
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      config,
      hairStylization,
      strength,
      template,
    };
  };

  createProcessingAndStartIfNot(configId, hairStylization, strength) {
    let processing = this.processings.find((processing) => {
      return processing.config.id === configId
        && processing.hairStylization === hairStylization
        && processing.strength === strength
        ;
    });

    if (!processing) {
      processing = this.createProcessing(
        getConfigById(configId),
        hairStylization,
        strength
      );

      this.processings.push(processing);
      this.startProcessing(processing);
    }

    return processing;
  }

  getGenderTask = () => {
    if (!this.genderTask) {
      this.genderTask = photolabGenderTask(this.files.first().url, 500, 500);
    }

    return this.genderTask;
  };

  getFaceTask = () => {
    if (!this.faceTask) {
      this.faceTask = photolabSimpleTask(this.props.faceTemplateId, this.files.first().url, 2000, 500);
    }

    return this.faceTask;
  }

  startProcessing = (processing) => {
    if (window.clientConfig.isWebview) {
      this.context.setNativeAdProcessingTexts(processingTexts);
      webviewShowNativeAds();
    }

    processing.error = null;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.isProcessing = true;
    processing.startedAt = Date.now();

    Promise.all([
      this.getGenderTask(),
      this.getFaceTask(),
    ])
      .then(([genderTaskResult, taskResult]) => {
        this.gender = genderTaskResult.gender.value;

        const taskBuilder = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(processing.template.id, {
            canvas_template_name: processing.template.canvasId,
            mask_template_name: processing.template.maskId,
            gender: this.gender === "male" ? "man" : "woman",
            seeds: processing.config.seed,
            prompt: processing.config.prompt,
            negative_prompt: processing.config.promptNegative,
            strength: processing.strength,
          }))
          .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
          .setLanguage(window.clientConfig.lang || "en");

        return photolabTask(taskBuilder.build(), 3000, 1000)
      })
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.forceUpdate();
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.forceUpdate();
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: this.gender,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        configId: processing.config.id,
        strength: processing.strength,
        hair_stylization: processing.hairStylization,
      }),
    });
  };

  handleRetryClick = (processing) => {
    this.startProcessing(processing);
    this.forceUpdate();
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: this.gender,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {};

  handleConfigSelect = (config) => {
    this.createProcessingAndStartIfNot(config.id, this.state.hairStylization, this.state.strength);
    this.setState({configId: config.id});
  };

  handleStrenghtSelect = (strength) => {
    this.createProcessingAndStartIfNot(this.state.configId, this.state.hairStylization, strength);
    this.setState({strength});
  };

  handleHairStylizationChange = (hairStylization) => {
    this.createProcessingAndStartIfNot(this.state.configId, hairStylization, this.state.strength);
    this.setState({hairStylization});
  };

  render() {
    const hasUnfinishedProcessing = this.processings.some((p) => p.isProcessing);
    const processing = this.processings.find((processing) => {
      return processing.config.id === this.state.configId
        && processing.hairStylization === this.state.hairStylization
        && processing.strength === this.state.strength
        ;
    });

    return <section className="start-page valentine22">
      <div className="start-page-header" hidden>
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={!processing.isProcessing}
            message={processingTexts} />

          {processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={() => this.handleRetryClick(processing)}
          />}

          <button
            className="btn-done"
            hidden={!processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div>
        <div>
          Strength:
          {" "}
          {this.state.strength === strengths.low ? <u>LOW</u> : <button disabled={hasUnfinishedProcessing} onClick={() => this.handleStrenghtSelect(strengths.low)}>LOW</button>}
          {" "}
          {this.state.strength === strengths.medium ? <u>MEDIUM</u> : <button disabled={hasUnfinishedProcessing} onClick={() => this.handleStrenghtSelect(strengths.medium)}>MEDIUM</button>}
          {" "}
          {this.state.strength === strengths.super ? <u>SUPER</u> : <button disabled={hasUnfinishedProcessing} onClick={() => this.handleStrenghtSelect(strengths.super)}>SUPER</button>}
        </div>
        <div>
          Hair Stylization:
          {" "}
          {this.state.hairStylization ? <u>YES</u> : <button disabled={hasUnfinishedProcessing} onClick={() => this.handleHairStylizationChange(true)}>YES</button>}
          {" "}
          {!this.state.hairStylization ? <u>NO</u> : <button disabled={hasUnfinishedProcessing} onClick={() => this.handleHairStylizationChange(false)}>NO</button>}
        </div>
      </div>

      <div className="templates-container templates-container_column pb-0">
        <div className="container">
          {configs.map((config) => <TemplateItemView2
            key={config.id}
            itemId={config.id}
            isLoading={false}
            isActive={config.id === processing.config.id}
            previewUrl=""
            disabled={hasUnfinishedProcessing}
            onClick={() => this.handleConfigSelect(config)}
          />)}
        </div>
      </div>
    </section>;
  }
}

DreamFilterPage.contextType = AppContext;