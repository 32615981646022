const templates = [
  {id: "AUS", cc2: "AU"}, // Au Австралия
  {id: "AUT", cc2: "AT"}, // At Австрия
  {id: "ARG", cc2: "AR"}, // Ar Аргентина
  {id: "BEL", cc2: "BE"}, // Be Бельгия
  {id: "BRA", cc2: "BR"}, // Br Бразилия
  {id: "GBR", cc2: "GB"}, // Gb Великобритания
  {id: "DEU", cc2: "DE"}, // De Германия
  {id: "DNK", cc2: "DK"}, // Dk Дания
  {id: "IRL", cc2: "IE"}, // Ie Ирландия
  {id: "ESP", cc2: "ES"}, // Es Испания
  {id: "ITA", cc2: "IT"}, // It Италия
  {id: "CAN", cc2: "CA"}, // Ca Канада
  {id: "CHN", cc2: "CN"}, // Cn Китай
  {id: "NLD", cc2: "NL"}, // Nl Нидерланды
  {id: "NOR", cc2: "NO"}, // No Норвегия
  {id: "USA", cc2: "US"}, // Us США (222)
  {id: "UKR", cc2: "UA"}, // Ua Украина
  {id: "RUS", cc2: "RU"}, // Ru Россия
  {id: "FIN", cc2: "FI"}, // Fi Финляндия
  {id: "FRA", cc2: "FR"}, // Fr Франция
  {id: "HRV", cc2: "HR"}, // Hr Хорватия
  {id: "CZE", cc2: "CZ"}, // Cz Чехия
  {id: "CHE", cc2: "CH"}, // Ch Швейцария
  {id: "SWE", cc2: "SE"}, // Se Швеция
  {id: "KOR", cc2: "KR"}, // Kr Южная Корея
  {id: "JPN", cc2: "JP"}, // jp япония
  {id: "KAZ", cc2: "KZ"}, // Казахстан
  {id: "ENG", cc2: "GB-ENG"}, // England
  {id: "BIH", cc2: "BA"}, // Bosnia and Herzegovina
  {id: "LIE", cc2: "LI"}, // Liechtenstein
  {id: "MAR", cc2: "MA"}, // Morocco
  {id: "SVK", cc2: "SK"}, // Slovakia
  {id: "PRT", cc2: "PT"}, // Portugal
];

export default {
  templates,
}