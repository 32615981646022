import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import {pwAssetUrl} from "../../../utils/etc";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {reorderTemplates} from "./helpers";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

const views = {
  background: "background",
  body: "body",
};

export default class Gfp936v2CropPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
    genderTask: null,
    gender: config.genders.male,
    bodiesTemplates: [],
    selectedBodyTemplate: null,
    selectedBodyTemplateIsUser: false,
    backgroundsTemplates: [],
    selectedBackgroundTemplate: null,
    processings: [],
    webdata: null,
    view: views.body,
    noUI: false,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.bodyTasks = [];

    this.state.files = getWebviewInputFilesFromURL();
    this.state.webdata = decodeWebData(window.location.href);
    this.state.gender = window.localStorage.getItem("bodychooser:gender") || this.state.gender;
    this.state.bodiesTemplates = reorderTemplates(config.cropTemplates);
    this.state.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
  }

  componentDidMount() {
    const template = this.getWebdataTemplate();

    if (this.state.webdata && template) {
      this.initBySelectedTemplate(template);
    } else {
      this.initWithGenderDetection();
    }
  }

  getWebdataTemplate = () => {
    return this.state.webdata
      && this.state.webdata.body
      && this.state.webdata.body.template
      && this.state.bodiesTemplates.find((template) => {
        // eslint-disable-next-line eqeqeq
        return template.id == this.state.webdata.body.template.id
            && template.gender === this.state.webdata.body.template.gender;
      });
  };

  getWebdataBackgroundTemplate = () => {
    return this.state.webdata
      && this.state.webdata.background
      && this.state.webdata.background.template
      && this.state.backgroundsTemplates.find((template) => {
        return template.id === parseInt(this.state.webdata.background.template.id);
      });
  };

  initWithGenderDetection = () => {
    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => {
        this.setState({
          genderTask: taskResult,
          gender: taskResult.gender.value,
        }, () => {
          this.initBySelectedTemplate(this.getFirstBodyTemplate());
        });
      })
      .catch((err) => {
        console.error(err);
        this.initBySelectedTemplate(this.getFirstBodyTemplate());
      });
  };

  getFirstBodyTemplate = () => {
    return this.state.bodiesTemplates.find((template) => template.gender === this.state.gender);
  };

  initBySelectedTemplate = (selectedTemplate) => {
    const bodyPos = this.state.bodiesTemplates.findIndex((t) => {
      return t.gender === selectedTemplate.gender
          && t.id === selectedTemplate.id;
    });

    if (bodyPos > 0) {
      this.state.bodiesTemplates.splice(bodyPos, 1);
      this.state.bodiesTemplates.unshift(selectedTemplate);
    }

    const backgroundsTemplates = config.backgroundTemplates.filter((t) => t.crop === selectedTemplate.id).shuffle();

    const backgroundTemplate = this.state.webdata
        && this.state.webdata.background
        && this.state.webdata.background.template
        && config.backgroundTemplates.find((template) => {
          return template.id === parseInt(this.state.webdata.background.template.id);
        });

    let selectedBackgroundTemplate = null;

    if (backgroundTemplate) {
      selectedBackgroundTemplate = backgroundsTemplates.find((t) => t.alias === backgroundTemplate.alias);
    }

    if (!selectedBackgroundTemplate) {
      selectedBackgroundTemplate = backgroundsTemplates.find((t) => t.alias === "blurBg");
    }

    const backgroundPos = backgroundsTemplates.findIndex((t) => {
      return t.id === selectedBackgroundTemplate.id;
    });

    if (backgroundPos > 0) {
      backgroundsTemplates.splice(backgroundPos, 1);
      backgroundsTemplates.unshift(selectedBackgroundTemplate);
    }

    this.setState({
      isLoading: this.state.noUI,
      gender: selectedTemplate.gender,
      bodiesTemplates: this.state.bodiesTemplates.slice(),
      backgroundsTemplates: backgroundsTemplates,
      selectedBackgroundTemplate: selectedBackgroundTemplate,
      selectedBodyTemplate: selectedTemplate,
    }, () => {
      this.handleTemplateSelect(selectedTemplate, false);
    });
  };

  getBodyTask = (templateId, gender) => {
    const task = this.bodyTasks.find((t) => {
      return t.templateId === templateId && t.gender === gender;
    });

    if (task && task.result) {
      return Promise.resolve(task.result);
    }

    const file = this.state.files[0];

    if (templateId === "fullsize") {
      return Promise.resolve({resultUrl: file.url});
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(templateId))
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip, file.alt_body))
      .setLanguage(window.clientConfig.lang || "en")
      .build();

    return photolabTask(taskConfig)
      .then((taskResult) => {
        this.bodyTasks.push({
          templateId,
          gender,
          result: taskResult
        });

        return taskResult;
      });
  };

  startProcessing = (processing) => {
    const file = this.state.files[0];

    // this.getBodyTask(processing.template.id, processing.template.gender)
    // .then((taskResult) => photolabTask(new PhotolabTaskBuilder()
    //   .addMethod(new PhotolabTaskCollageMethod(processing.backgroundTemplate.id))
    //   .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl, "", 0, 0, file.alt_body))
    //   .setLanguage(window.clientConfig.lang || "en")
    //   .build()))
    photolabTask(new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(processing.backgroundTemplate.id))
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip, file.alt_body))
      .setLanguage(window.clientConfig.lang || "en")
      .build())
    .then((taskResult) => {
      processing.finishedAt = Date.now();

      if (window.clientConfig.isWebview) {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }

      hitPhotoProcessed();
      logCreativeProcessed(this.props.pageName, processing);

      processing.isProcessing = false;
      processing.isProcessed = true;
      processing.result = taskResult;

      const mask = taskResult.masks.length > 0
          && taskResult.masks.find((mask) => mask.index === 1 && mask.name === "body");

      if (mask) {
        processing.maskUrl = mask.url;
      }

      if (this.state.noUI) {
        this.handleConfirmResult(processing);
      }

      this.setState({processings: this.state.processings.slice()});
    })
    .catch((err) => {
      processing.finishedAt = Date.now();

      if (window.clientConfig.isWebview) {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }

      hitPhotoFailed();
      logCreativeFailed(this.props.pageName, processing);

      if (err instanceof PhotolabResponseError) {
        webviewSendResult({status: "Error", description: err.message});
        return;
      }

      const logError = {
        page_name: this.props.pageName,
        name: err.name,
        code: err.code || undefined,
        message: err.message || undefined,
      };

      logEvent(userEvents.BODYCHOOSER__ERROR, logError);

      processing.isProcessing = false;
      processing.isFailed = true;
      processing.error = err;

      this.setState({processings: this.state.processings.slice()});
    });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
    });

    const backgroundsTemplates = config.backgroundTemplates.filter((t) => t.crop === template.id).shuffle();

    const backgroundTemplate = this.state.webdata
      && this.state.webdata.background
      && this.state.webdata.background.template
      && config.backgroundTemplates.find((template) => {
        return template.id === parseInt(this.state.webdata.background.template.id);
      });

    let selectedBackgroundTemplate = null;

    if (backgroundTemplate) {
      selectedBackgroundTemplate = backgroundsTemplates.find((t) => t.alias === backgroundTemplate.alias);
    }

    if (!selectedBackgroundTemplate) {
      selectedBackgroundTemplate = backgroundsTemplates.find((t) => t.alias === "blurBg");
    }

    const backgroundPos = backgroundsTemplates.findIndex((t) => {
      return t.id === selectedBackgroundTemplate.id;
    });

    if (backgroundPos > 0) {
      backgroundsTemplates.splice(backgroundPos, 1);
      backgroundsTemplates.unshift(selectedBackgroundTemplate);
    }

    this.setState({
      selectedBodyTemplateIsUser: !!isUser,
      selectedBodyTemplate: template,
      backgroundsTemplates: backgroundsTemplates,
      selectedBackgroundTemplate: selectedBackgroundTemplate,
    }, () => {
      this.startTemplate();
    });
  };

  startTemplate = () => {
    let processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedBodyTemplate.id
          && processing.template.gender === this.state.selectedBodyTemplate.gender
          && processing.backgroundTemplate.id === this.state.selectedBackgroundTemplate.id;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = this.state.selectedBodyTemplate;
    processing.backgroundTemplate = this.state.selectedBackgroundTemplate;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.maskUrl = undefined;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  }

  handleSelectBackgroundTemplate = (template) => {
    this.setState({
      selectedBackgroundTemplate: template,
    }, () => {
      this.startTemplate();
    });
  };

  handleConfirmResult = (processing) => {
    if (this.state.view === views.body && !this.state.noUI) {
      this.setState({view: views.background});
      return;
    }

    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      background_template_id: processing.backgroundTemplate.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderTask ? this.state.genderTask.gender.value : null,
      is_user_select: this.state.selectedBodyTemplateIsUser,
      index,
    });

    const resultData = {
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        body: {
          template: {
            id: processing.template.id,
            gender: processing.template.gender,
          },
        },
        background: {
          template: {
            id: processing.backgroundTemplate.id,
          },
        },
      }),
    };

    if (processing.maskUrl) {
      resultData.body_1 = encodeURIComponent(processing.maskUrl);
    }

    if (this.state.files[0].alt_body) {
      resultData.alt_body_1 = encodeURIComponent(this.state.files[0].alt_body);
    }

    webviewSendResult(resultData);
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    window.localStorage.setItem("bodychooser:gender", gender);
    this.setState({gender});
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.maskUrl = undefined;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.bodiesTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedBodyTemplateIsUser,
      index,
    });
  };

  handleEditMask = (processing) => {
    const fileUrl = this.state.files[0].src || this.state.files[0].url;
    const maskUrl = this.state.files[0].alt_body || processing.maskUrl;
    const params = {
      "src[0]": encodeURIComponent(fileUrl),
      "mask[0]": encodeURIComponent(maskUrl),
    };

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    const files = this.state.files;
    files[0].alt_body = data[0]["0"];

    this.setState({
      processings: [],
      files: files,
    }, () => this.handleTemplateSelect(this.state.selectedBodyTemplate, true));
  }

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const templates = this.state.bodiesTemplates
      .filter((template) => template.gender === this.state.gender);

    const processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedBodyTemplate.id
        && processing.template.gender === this.state.selectedBodyTemplate.gender
        && processing.backgroundTemplate.id === this.state.selectedBackgroundTemplate.id;
    });

    const currentStep = this.state.view === views.body ? 1 : 2;
    const stepTitle = this.state.view === views.body
      ? i18n.t("title__choose_crop")
      : i18n.t("title__choose_background");

    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsHidden = !processing
        || !processing.isProcessed
        || (!processing.maskUrl && !this.state.files[0].alt_body)
        || !maskSupport;

    return <section className="start-page xmas21">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container">
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: currentStep, steps: 2})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: stepTitle}} />
          <button
              className="btn-edit-mask"
              hidden={editMaskButtonIsHidden}
              onClick={() => this.handleEditMask(processing)}>
            <svg viewBox="0 0 62 62" id="icon-mask">
              <g fillRule="evenodd">
                <path d="M43.492 34.139c.83-1.196 1.45-2.552 1.895-4.146l-.36.529c2.102-1.593 3.176-4.027 3.443-7.494l.013-.164-1.994-.154-.013.164c-.227 2.944-1.068 4.85-2.656 6.054l-.27.203-.09.325c-.386 1.384-.913 2.536-1.611 3.543l1.643 1.14zm4.557-17.132c.044-.36.081-.699.112-1.02.32-3.33-.85-7.12-3.18-10.022-.239-.298-.489-.586-.75-.864l-1.458 1.368c.225.24.441.49.649.748 2.007 2.5 3.018 5.774 2.749 8.578-.03.303-.065.625-.108.968l1.986.244zM38.91 1.456C36.585.49 33.926 0 31 0c-1.59 0-3.101.145-4.525.433l.396 1.96A20.84 20.84 0 0 1 31 2c2.672 0 5.07.443 7.144 1.303l.766-1.847zM20.56 2.776a14.8 14.8 0 0 0-3.494 3.129c-1.78 2.216-2.888 4.88-3.194 7.53l1.987.23c.263-2.277 1.224-4.586 2.763-6.504a12.792 12.792 0 0 1 3.02-2.703L20.56 2.777zm-6.452 16.385a9.622 9.622 0 0 0-.595 3.391v.205l.017.268c.267 3.468 1.34 5.902 3.442 7.495l.604-.797-.963.269c.09.321.186.632.29.934l1.89-.651c-.09-.264-.175-.537-.254-.82l-.09-.325-.27-.203c-1.588-1.205-2.428-3.11-2.654-6.055l-.015-.192.003-.126c0-.969.16-1.873.467-2.688l-1.872-.705zm4.719 15.96c-.54.527-1.82 1.116-4.775 2.168-2.314.823-4.285 1.648-5.98 2.51l.907 1.782c1.611-.82 3.505-1.612 5.743-2.408 3.26-1.16 4.671-1.81 5.502-2.621l-1.397-1.43zM2.868 43.356C.978 45.218.003 47.316 0 49.671v5.714h2v-5.713c.003-1.789.746-3.387 2.272-4.891l-1.404-1.425zM1.385 62h12v-2h-12v2zm18 0h12v-2h-12v2zm18 0h12v-2h-12v2zm18 0H62v-7.385h-2V61l1-1h-5.615v2zm6.526-13.53c-.543-3.624-3.476-6.52-8.743-9.046l-.864 1.804c4.73 2.268 7.205 4.713 7.629 7.538l1.978-.296zM47.56 37.15c-3.804-1.369-4.596-1.885-4.71-2.678l-.99.142.762.647c.337-.398.607-.744.869-1.122l-1.643-1.14c-.223.321-.454.617-.751.969l-.292.343.065.446c.267 1.851 1.488 2.646 6.012 4.276l.678-1.882z" fillRule="nonzero"/>
                <path d="M31 9c2.87 0 4.874.7 6.184 1.802 1.64 1.38 2.116 3.311 2.019 4.32-.04.411-.092.849-.15 1.283l-.06.433a71.384 71.384 0 0 1-.474 2.858l.96 2.662c-.023.495-.06.834-.127 1.09-1.115.537-2.031 1.145-2.428 3.233-.337 1.774-.876 2.43-1.329 2.932-1.052 1.165-1.774 2.098-1.774 4.335 0 3.154.87 5.42 2.85 7.366 1.643 1.617 4.248 3.028 8.305 4.47 2.97 1.057 5.166 2.105 6.65 3.101.447.3 1.374.443 1.374.798V53H9v-3.317c0-.355.925-.498 1.372-.798 1.484-.996 3.68-2.044 6.65-3.101 4.059-1.443 6.663-2.853 8.307-4.47 1.979-1.947 2.849-4.212 2.849-7.367 0-2.237-.723-3.17-1.775-4.335-.453-.5-.991-1.157-1.328-2.93-.397-2.09-1.313-2.698-2.428-3.235-.07-.27-.107-.633-.144-1.112l.977-2.64s-.31-1.625-.533-3.291l-.056-.435a31.615 31.615 0 0 1-.095-.849c-.098-1.01.379-2.939 2.018-4.317C26.124 9.7 28.13 9 31 9z" stroke="#868686" strokeWidth="6"/>
              </g>
            </svg>
          </button>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.body}>
        <div className="container">
          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedBodyTemplate.id === template.id && this.state.selectedBodyTemplate.gender === template.gender}
            previewUrl={pwAssetUrl(`templates-previews/gfp936-${template.id}.jpg`)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.background}>
        <div className="container">
          {this.state.backgroundsTemplates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedBackgroundTemplate.id === template.id}
            previewUrl={pwAssetUrl(`templates-previews/${template.id}.jpg?v2`)}
            onClick={(template) => this.handleSelectBackgroundTemplate(template)}
          />)}
        </div>
      </div>
    </section>;
  }
}

Gfp936v2CropPage.contextType = AppContext;
