export default class PhotolabTaskCollageMethod {

  constructor(templateId, extra = {}) {
    this._name = "collage";
    this._params = {
      template_name: templateId,
      ...extra,
    };
  }

  get name() {
    return this._name;
  }

  get params() {
    return this._params;
  }

  get extra() {
    return this._extra;
  }
}