const genders = {
  male: "male",
  female: "female",
};

const bodies = [
  {id: 5965, gender: genders.male, previewFileName: "5965m"},
  {id: 5967, gender: genders.male, previewFileName: "5967m"},
  {id: 5969, gender: genders.male, previewFileName: "5969m"},
  {id: 5975, gender: genders.male},
  // --
  {id: 5965, gender: genders.female, previewFileName: "5965f"},
  {id: 5968, gender: genders.female, previewFileName: "5968f"},
  {id: 5969, gender: genders.female, previewFileName: "5969f"},
  {id: 5966, gender: genders.female},
];

export default {
  genders,
  bodies,
}
