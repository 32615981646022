module.exports = {
  "strings": {

    "can_swap_or_continue": "You can swap faces or continue as is",
    "button_tap_to_swap": "Tap to swap",
    "button_swap_people": "swap people",

    "button_choice_gender_male": "male",
    "button_choice_gender_female": "female",
    "button_choice_gender_text": "styles",
    "btn_retry": "retry",

    "step_count_text": "{{step_idx}} out of {{steps}}",
    "start_page_title": "Choose the style you like most",
    "start_page_title_2": "Choose the style you like most:",
    "squidgame__start_page_title" : "With this, let the game begin",
    "olympics22__start_page_title" : "Who are you rooting for?",

    "title__choose_body": "Choose body style",
    "title__choose_crop": "Choose portrait zoom",
    "title__choose_background": "Choose background style",
    "gfp936_title_choose_crop_backgroud": "Choose portrait zoom and background",

    "loader_processing": "Processing",

    "error_modal__default_message": "Something went wrong. Try again.",
    "error_modal__network_message": "Please check your network connection.",

    "vector_tab_refresh__title": "Almost there!",
    "vector_tab_refresh__subtitle": "Choose head style",

    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",

    "footer_title": "Choose body style",

    "more_styles": "More styles",
    "another_go": "Another go",

    "selected_mask": "Selected mask:",
    "seed": "Seed:",

    "qrcode_title_step_1": "Start by picking a TOON style for your QR code",
    "qrcode_title_step_2": "Now enter a link your QR code will lead to",
    "qrcode_enter_url": "Enter your social media url",
    "qrcode_enter_url_placeholder": "e.g. https://www.instagram.com/@yourname",
    "qrcode_next": "next",
    "qrcode_footer_title": "To get a QR Toon by scanning an existing QR code <span>visit QRtoon.com</span>",
  },
};
