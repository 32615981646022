import axios from "axios";

export const hits = {
  PHOTO_STARTED: 8396,
  PHOTO_PROCESSED: 8388,
  PHOTO_FAILED: 8389,
  CREATIVE_STARTED: 8395,
  CREATIVE_STARTED_RETRY: 8397,
  CREATIVE_PROCESSED: 8390,
  CREATIVE_FAILED: 8391,
  CREATIVE_FAILED__NONPHOTOLAB: 8399,
  NATIVE_AD_SHOWN: 8910,
  NATIVE_AD_SHOW_REQUEST: 8911,
};

const httpClient = axios.create({});

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", id, hitName);
  }

  if (window.appConfig.hits.isEnabled) {
    const url = `${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`;

    httpClient.post(url).then(() => {/* dummy */}).catch(console.error);
  }
}

export function hitPhotoStarted() {
  hitEvent(hits.PHOTO_STARTED);
}

let hitPhotoProcessedIsTriggered = false;
export function hitPhotoProcessed() {
  if (hitPhotoProcessedIsTriggered === false) {
    hitPhotoProcessedIsTriggered = true;
    hitEvent(hits.PHOTO_PROCESSED);
  }
}

let hitPhotoFailedIsTriggered = false;
export function hitPhotoFailed() {
  if (hitPhotoFailedIsTriggered === false) {
    hitPhotoFailedIsTriggered = true;
    hitEvent(hits.PHOTO_FAILED);
  }
}