module.exports = {
  "strings": {

    "can_swap_or_continue": "Vous pouvez échanger les visages ou continuer ainsi",
    "button_tap_to_swap": "Taper pour échanger",

    "button_choice_gender_male": "masculins",
    "button_choice_gender_female": "féminins",
    "button_choice_gender_text": "modèles",
    "btn_retry": "Réessayer",

    "step_count_text": "{{step_idx}} sur {{steps}}",
    "start_page_title": "Choisissez le style qui vous plaît le plus",
    "start_page_title_2": "Choisissez le style qui vous plaît le plus",
    "olympics22__start_page_title" : "Qui allez-vous encourager ?",

    "title__choose_body": "Choisissez le style de corps",
    "title__choose_crop": "Changer le style de corps",
    "title__choose_background": "Choisissez le style de l'arrière-plan",
    "gfp936_title_choose_crop_backgroud": "Choisissez le cadrage et l'arrière-plan du portrait",

    "loader_processing": "Traitement",

    "vector_tab_refresh__title": "On y est presque !",
    "vector_tab_refresh__subtitle": "Choisissez le style de tête",

    "error_modal__default_message": "Une erreur est survenue...",
    "error_modal__network_message": "Veuillez vérifier votre connexion Internet.",

    "footer_title": "Changer le style de corps",

    "more_styles": "Plus de styles",
  },
};
