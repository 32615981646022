const genders = {
  male: "male",
  female: "female",
};

const bodies = [
  {id: 6316, gender: genders.female},
  {id: 6315, gender: genders.female},
  {id: 6427, gender: genders.female},
  {id: 6447, gender: genders.female},
  {id: 6360, gender: genders.female},
  {id: 6392, gender: genders.female},
  {id: 6385, gender: genders.female},
  {id: 6395, gender: genders.female},
  {id: 6386, gender: genders.female},
  {id: 6396, gender: genders.female},
  {id: 6394, gender: genders.female},
  {id: 6429, gender: genders.female},
  {id: 6430, gender: genders.female},
  {id: 6451, gender: genders.female},
  {id: 6448, gender: genders.female},
  {id: 6449, gender: genders.female},
  {id: 6291, gender: genders.female},

  {id: 6428, gender: genders.male},
  {id: 6446, gender: genders.male},
  {id: 6360, gender: genders.male},
  {id: 6392, gender: genders.male},
  {id: 6385, gender: genders.male},
  {id: 6395, gender: genders.male},
  {id: 6386, gender: genders.male},
  {id: 6396, gender: genders.male},
  {id: 6394, gender: genders.male},
  {id: 6429, gender: genders.male},
  {id: 6430, gender: genders.male},
  {id: 6451, gender: genders.male},
  {id: 6448, gender: genders.male},
  {id: 6449, gender: genders.male},
  {id: 6291, gender: genders.male},
];

const winterIs = [
  {id: 6488, gender: genders.female},
  {id: 6491, gender: genders.female},
  {id: 6503, gender: genders.female},
  {id: 6504, gender: genders.female},
  {id: 6505, gender: genders.female},
  {id: 6494, gender: genders.female},
  {id: 6498, gender: genders.female},
  {id: 6506, gender: genders.female},
  {id: 6489, gender: genders.female},
  {id: 6490, gender: genders.female},
  {id: 6497, gender: genders.female},
  {id: 6501, gender: genders.female},
  {id: 6500, gender: genders.female},
  {id: 6495, gender: genders.female},
  {id: 6493, gender: genders.female},
  {id: 6496, gender: genders.female},
  {id: 6561, gender: genders.female},
  {id: 6562, gender: genders.female},
  {id: 6563, gender: genders.female},

  {id: 6499, gender: genders.male},
  {id: 6502, gender: genders.male},
  {id: 6504, gender: genders.male},
  {id: 6505, gender: genders.male},
  {id: 6494, gender: genders.male},
  {id: 6498, gender: genders.male},
  {id: 6506, gender: genders.male},
  {id: 6489, gender: genders.male},
  {id: 6490, gender: genders.male},
  {id: 6497, gender: genders.male},
  {id: 6501, gender: genders.male},
  {id: 6500, gender: genders.male},
  {id: 6495, gender: genders.male},
  {id: 6493, gender: genders.male},
  {id: 6496, gender: genders.male},
  {id: 6561, gender: genders.male},
];

const preparationBodies = [
  {id: 6380, gender: genders.female},
  {id: 6384, gender: genders.female},
  {id: 6450, gender: genders.female},
  {id: 6466, gender: genders.female},
  {id: 6462, gender: genders.female},
  {id: 6460, gender: genders.female},
  {id: 6391, gender: genders.female},
  {id: 6381, gender: genders.female},
  {id: 6456, gender: genders.female},
  {id: 6467, gender: genders.female},
  {id: 6465, gender: genders.female},
  {id: 6464, gender: genders.female},
  {id: 6463, gender: genders.female},
  {id: 6461, gender: genders.female},
  {id: 6459, gender: genders.female},
  {id: 6458, gender: genders.female},
  {id: 6457, gender: genders.female},
  {id: 6469, gender: genders.female},
  {id: 6470, gender: genders.female},
  {id: 6187, gender: genders.female},
  {id: 6388, gender: genders.female},
  {id: 6378, gender: genders.female},
  {id: 6473, gender: genders.female},
  {id: 6475, gender: genders.female},
  {id: 6382, gender: genders.female},
  {id: 6359, gender: genders.female},
  {id: 6383, gender: genders.female},
  {id: 6390, gender: genders.female},
  {id: 6564, gender: genders.female},
  {id: 6565, gender: genders.female},
  {id: 6566, gender: genders.female},
  {id: 6567, gender: genders.female},
  {id: 6568, gender: genders.female},

  {id: 6379, gender: genders.male},
  {id: 6381, gender: genders.male},
  {id: 6456, gender: genders.male},
  {id: 6467, gender: genders.male},
  {id: 6465, gender: genders.male},
  {id: 6464, gender: genders.male},
  {id: 6463, gender: genders.male},
  {id: 6461, gender: genders.male},
  {id: 6459, gender: genders.male},
  {id: 6458, gender: genders.male},
  {id: 6457, gender: genders.male},
  {id: 6469, gender: genders.male},
  {id: 6470, gender: genders.male},
  {id: 6187, gender: genders.male},
  {id: 6388, gender: genders.male},
  {id: 6378, gender: genders.male},
  {id: 6473, gender: genders.male},
  {id: 6475, gender: genders.male},
  {id: 6565, gender: genders.male},
  {id: 6566, gender: genders.male},
  {id: 6567, gender: genders.male},
  {id: 6568, gender: genders.male},
];

const transparentBodies = [
  {id: 6168, gender: genders.female},
  {id: 6169, gender: genders.female},
  {id: 6170, gender: genders.female},
  {id: 6177, gender: genders.female},
  {id: 6178, gender: genders.female},
  {id: 6186, gender: genders.female},
  {id: 6197, gender: genders.female},
  {id: 6201, gender: genders.female},
  {id: 6204, gender: genders.female},
  {id: 6358, gender: genders.female},
  {id: 6200, gender: genders.female},
  {id: 6353, gender: genders.female},
  {id: 6174, gender: genders.female},
  {id: 6175, gender: genders.female},
  {id: 6176, gender: genders.female},
  {id: 6179, gender: genders.female},
  {id: 6181, gender: genders.female},
  {id: 6180, gender: genders.female},
  {id: 6188, gender: genders.female},
  {id: 6182, gender: genders.female},
  {id: 6183, gender: genders.female},
  {id: 6185, gender: genders.female},
  {id: 6196, gender: genders.female},
  {id: 6190, gender: genders.female},
  {id: 6198, gender: genders.female},
  {id: 6199, gender: genders.female},
  {id: 6202, gender: genders.female},
  {id: 6203, gender: genders.female},
  {id: 6205, gender: genders.female},
  {id: 6206, gender: genders.female},
  {id: 6207, gender: genders.female},
  {id: 6355, gender: genders.female},
  {id: 6356, gender: genders.female},
  {id: 6357, gender: genders.female},
  {id: 6393, gender: genders.female},

  {id: 6171, gender: genders.male},
  {id: 6172, gender: genders.male},
  {id: 6173, gender: genders.male},
  {id: 6184, gender: genders.male},
  {id: 6354, gender: genders.male},
  {id: 6174, gender: genders.male},
  {id: 6175, gender: genders.male},
  {id: 6176, gender: genders.male},
  {id: 6179, gender: genders.male},
  {id: 6181, gender: genders.male},
  {id: 6180, gender: genders.male},
  {id: 6188, gender: genders.male},
  {id: 6182, gender: genders.male},
  {id: 6183, gender: genders.male},
  {id: 6185, gender: genders.male},
  {id: 6196, gender: genders.male},
  {id: 6190, gender: genders.male},
  {id: 6198, gender: genders.male},
  {id: 6199, gender: genders.male},
  {id: 6202, gender: genders.male},
  {id: 6203, gender: genders.male},
  {id: 6205, gender: genders.male},
  {id: 6206, gender: genders.male},
  {id: 6207, gender: genders.male},
  {id: 6355, gender: genders.male},
  {id: 6356, gender: genders.male},
  {id: 6357, gender: genders.male},
  {id: 6393, gender: genders.male},
];

const transparentBodiesBackgrounds = [
  {id: 6397},
  {id: 6398},
  {id: 6399},
  {id: 6400},
  {id: 6401},
  {id: 6402},
  {id: 6403},
  {id: 6404},
  {id: 6405},
  {id: 6406},
  {id: 6407},
  {id: 6408},
  {id: 6409},
  {id: 6410},
  {id: 6411},
  {id: 6412},
  {id: 6413},
  {id: 6414},
  {id: 6415},
  {id: 6416},
  {id: 6417},
  {id: 6418},
  {id: 6419},
];

const masks = [
  {id: 6516, layoutId: 6523, gender: genders.male},
  {id: 6515, layoutId: 6523, gender: genders.male},
  {id: 6514, layoutId: 6526, gender: genders.male},
  {id: 6517, layoutId: 6525, gender: genders.male},
  {id: 6482, layoutId: 6524, gender: genders.male},
  {id: 6542, layoutId: 6526, gender: genders.male},
  //
  {id: 6516, layoutId: 6523, gender: genders.female},
  {id: 6515, layoutId: 6523, gender: genders.female},
  {id: 6514, layoutId: 6526, gender: genders.female},
  {id: 6517, layoutId: 6525, gender: genders.female},
  {id: 6482, layoutId: 6524, gender: genders.female},
  {id: 6542, layoutId: 6526, gender: genders.female},
];

export default {
  genders,
  bodies,
  winterIs,
  preparationBodies,
  transparentBodies,
  transparentBodiesBackgrounds,
  masks,
}