import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabTask, photolabGenderTask} from "../../../photolab/api";
import {getWebviewInputFilesFromURL, webviewSendResult} from "../../../utils/webview";
import i18n from "../../../i18n";
import Loader from "../../components/Loader";
import config from "./config";
import {logCreativeFailed, logCreativeProcessed} from "../../../utils/log";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

export default class GenderSwapPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.state.files = getWebviewInputFilesFromURL();
  }

  componentDidMount() {
    const processing = {
      startedAt: Date.now()
    };

    const cropTaskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(4021))
      .addImage(new PhotolabTaskImageUrl(this.state.files[0].url))
      .build();

    Promise.all([
      photolabGenderTask(this.state.files[0].url),
      photolabTask(cropTaskConfig, 1000, 1000),
    ]).then((tasksResults) => {
      const taskConfig = new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(config.templates[tasksResults[0].gender.value]))
        .addImage(new PhotolabTaskImageUrl(this.state.files[0].url))
        .build();

      return Promise.all([
        photolabTask(taskConfig, 2000),
        tasksResults[1],
      ]);
    }).then((tasksResults) => {
      const taskConfig = new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(5510))
        .addImage(new PhotolabTaskImageUrl(tasksResults[0].resultUrl))
        .addImage(new PhotolabTaskImageUrl(tasksResults[1].resultUrl))
        .build();

      return photolabTask(taskConfig);
    }).then((taskResult) => {
      processing.finishedAt = Date.now();

      hitPhotoProcessed();
      logCreativeProcessed(this.props.pageName, processing);

      webviewSendResult({
        status: "OK",
        result_url: encodeURIComponent(taskResult.resultUrl),
      });
    }).catch((err) => {
      processing.finishedAt = Date.now();

      hitPhotoFailed();
      logCreativeFailed(this.props.pageName, processing);

      webviewSendResult({
        status: "Error",
        description: err.message,
      });
    });
  }

  render() {
    return <Loader message={i18n.t("loader_processing")} />;
  }
}

GenderSwapPage.contextType = AppContext;
