import React from "react";
import {Route} from "react-router";
import GenderSwapPage from "./GenderSwapPage";

const routes = [{
  key: "genderSwapRoute",
  path: "/gender-swap",
  render: (props) => <GenderSwapPage {...props} pageName="genderswap" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}