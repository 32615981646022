module.exports = {
  "strings": {

    "can_swap_or_continue": "顔を入れ替えるかそのままで継続できます",
    "button_tap_to_swap": "タップで入れ替え",

    "button_choice_gender_male": "男性の",
    "button_choice_gender_female": "女性の",
    "button_choice_gender_text": "スタイル",
    "btn_retry": "再試行",

    "step_count_text": "手順{{step_idx}}/{{steps}}",
    "start_page_title": "一番気に入ったスタイルを選択",
    "start_page_title_2": "一番気に入ったスタイルを選択",
    "olympics22__start_page_title" : "誰を応援していますか？",

    "title__choose_body": "体型を選択",
    "title__choose_crop": "体型を選択",
    "title__choose_background": "背景のスタイルを選択",
    "gfp936_title_choose_crop_backgroud": "ポートレートの切り抜きと背景を選択",

    "loader_processing": "処理中です",

    "vector_tab_refresh__title": "あとちょっと！",
    "vector_tab_refresh__subtitle": "髪型を選択",

    "error_modal__default_message": "出问题了。。。",
    "error_modal__network_message": "インターネット接続をお確かめください。",

    "footer_title": "体型を選択",

    "more_styles": "その他のスタイル",
  },
};
