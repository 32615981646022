import React from "react";
import AppContext from "../../../contexts/AppContext";
import config from "./config";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import {photolabAddTask, photolabWaitTask} from "../../../photolab/api";

const styleChooserTemplate = {
  [config.styles.vector]: 5079,
  [config.styles.disney]: 5114,
  [config.styles.toon]: 5103,
};

export default class ConfigDiffPage extends React.Component {

  state = {
    isLoading: false,
    fileUrl: "https://photolab-tester2.photo-cdn.net/storage/02/bc/c0/138.jpeg",
    style: config.styles.vector,
    taskResult: null,
  };

  constructor(props) {
    super(props);

    this.state.fileUrl = window.localStorage.getItem("configdiffpage_last_url") || this.state.fileUrl;
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.fileUrl.length > 0) {
      window.localStorage.setItem("configdiffpage_last_url", this.state.fileUrl);
    }

    this.setState({
      isLoading: true,
    }, () => {
      const templateId = styleChooserTemplate[this.state.style];
      const taskConfig = new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(templateId))
        .addImage(new PhotolabTaskImageUrl(this.state.fileUrl))
        .build();

      photolabAddTask(taskConfig)
        .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 2000))
        .then((taskResult) => {
          this.setState({
            isLoading: false,
            taskResult,
          });
        })
    });
  };

  renderForm = () => {

    // http://worker-images.ws.pho.to/i2/bba8d29852813af95246b117d6ef8b2cb0df1ecd_m.jpeg
    return <div style={{margin: 20}}>
      <form onSubmit={this.handleFormSubmit}>
        <div>
          <input
            type="text"
            placeholder="Source file URL"
            style={{width: "100%"}}
            value={this.state.fileUrl}
            onChange={(e) => this.setState({fileUrl: e.target.value})}
          />
        </div>
        <div>
          <select onChange={(e) => this.setState({style: e.target.value})}>
            {Object.values(config.styles).map((value) => <option
              key={value}
              value={value}
              children={value}
            />)}
          </select>
        </div>
        <div>
          <input type="submit" value="Go" />
        </div>
      </form>
    </div>
  };

  renderResult = () => {
    const styleTemplatesInConfig = config.templates.filter((template) => {
      return template.style === this.state.style;
    });

    const newTemplatesInTask = this.state.taskResult.results.filter((template) => {
      return !styleTemplatesInConfig.find((t) => t.id === template.templateId);
    });

    if (newTemplatesInTask.length === 0) {
      return <div style={{margin: 20}}>
        <button children="Reset" onClick={() => this.setState({taskResult: null})} />
        <hr />
        <p style={{fontWeight: 700, color: "#080"}}>No new templates for '{this.state.style}' style.</p>
      </div>;
    }

    return <div style={{margin: 20}}>
      <button children="Reset" onClick={() => this.setState({taskResult: null})} />
      <hr />
      <p>New templates</p>
      <br />
      <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap"}}>
        {newTemplatesInTask.map((template) => {
          return <div key={template.templateId} style={{marginBottom: 20, marginRight: 20}}>
            <b>ID: {template.templateId}</b><br />
            <a href={template.resultUrl} target="_blank" title="Click to open in new tab" rel="noopener noreferrer">
              <img src={template.resultUrl} alt="Preview" width="200" />
            </a>
          </div>;
        })}
      </div>
    </div>;
  };

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    if (!this.state.taskResult) {
      return this.renderForm();
    }

    return this.renderResult();
  }
}

ConfigDiffPage.contextType = AppContext;
