import {urlencode} from "./text";
import {hitEvent, hits} from "./hits";

let lastCallbackId = 0;

const loadedUrl = new URL(window.location.href);
const isPostMessage = loadedUrl.searchParams.get("result") === "postmessage";

function createWebviewCallbackFunc(cb) {
  const funcName = "webviewFuncCallback_" + lastCallbackId;
  lastCallbackId++;

  window[funcName] = function() {
    if (cb) {
      cb(...arguments);
    }

    delete window[funcName];
  };

  return funcName;
}

export function webviewBuildCallURL(func, params) {
  const queryPairs = [];

  Object.keys(params).forEach((key) => {
    if (typeof params[key] !== "undefined") {
      queryPairs.push(`${key}=${params[key]}`);
    }
  });

  return `callback:${func}?${queryPairs.join("&")}`;
}

export function webviewCall(func, params) {
  const cbs = webviewBuildCallURL(func, params);

  console.log("WEBVIEW CALLBACK: " + cbs);
  window.location.href = cbs;
}

export function webviewCallWithCallback(func, params, cb) {
  params.func = createWebviewCallbackFunc(cb);

  webviewCall(func, params);
}

// ---

export function webviewOpenBrowser(url) {
  webviewCall("openblank", {url: urlencode(url)});
}

export function webviewShowNativeAds() {
  hitEvent(hits.NATIVE_AD_SHOW_REQUEST);
  webviewCall("showNativeAds", {
    func: "webview_native_ad_shown",
  });
}

export function webviewHideNativeAds() {
  webviewCall("hideNativeAds", {});
}

export function getWebviewInputFilesFromURL(inputURL) {
  const url = new URL(inputURL || window.location.href);
  const files = [];

  for (let i = 0; i < 10; i++) {
    if (!url.searchParams.has(`url[${i}]`)) {
      break;
    }

    const fileUrl = url.searchParams.get(`url[${i}]`);
    if (!fileUrl) {
      break;
    }

    const fileSrc = url.searchParams.get(`src[${i}]`);

    files.push({
      url: decodeURIComponent(fileUrl),
      src: fileSrc ? decodeURIComponent(fileSrc) : undefined,
      rect: url.searchParams.get(`rect[${i}]`) || "",
      rotation: parseInt(url.searchParams.get(`rotation[${i}]`)) || 0,
      flip: parseInt(url.searchParams.get(`flip[${i}]`)) || 0,
      fixed: (url.searchParams.get(`fixed[${i}]`) === "1") || false,
      alt_body: url.searchParams.has(`alt_body[${i}]`)
          ? decodeURIComponent(url.searchParams.get(`alt_body[${i}]`))
          : null,
    });
  }

  return files;
}

export function webviewSendResult(data) {
  console.info("proceed_to_result", data);

  if (isPostMessage) {
    (window.parent || window).postMessage(JSON.stringify(data), "*");
  } else {
    webviewCall("proceed_to_result", data);
  }
}