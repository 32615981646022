import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError, photolabSimpleTask,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import {logCreativeResult, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

const processingTexts = [
  "AI is analysing which activity<br />you might be good at 🎯",
  "AI is checking which profession<br />you have a disposition for 😎",
  "AI is looking for your totem animal 🦖",
  "AI is determining who you<br />could be in your past life 📜",
];

const positions = {
  "1": {canvasId: 8153, maskId: 8154},
  "2": {canvasId: 8155, maskId: 8156},
  "3": {canvasId: 8157, maskId: 8158},
};

const templates = [
  [
    {id: 8161, ...positions["1"]},
    {id: 8162, ...positions["1"]},
    {id: 8220, ...positions["2"]},
    {id: 8249, ...positions["2"]},
    {id: 8255, ...positions["2"]},
  ], [
    {id: 8242, ...positions["1"]},
    {id: 8244, ...positions["2"]},
    {id: 8240, ...positions["2"]},
    {id: 8239, ...positions["1"]},
    {id: 8238, ...positions["2"]},
    {id: 8237, ...positions["1"]},
    {id: 8260, ...positions["1"]},
    {id: 8261, ...positions["2"]},
    {id: 8262, ...positions["2"]},
    {id: 8264, ...positions["2"]},
    {id: 8266, ...positions["2"]},
    {id: 8267, ...positions["2"]},
  ], [
    {id: 8203, ...positions["2"]},
    {id: 8201, ...positions["2"]},
    {id: 8197, ...positions["2"]},
    {id: 8198, ...positions["2"]},
    {id: 8196, ...positions["1"]},
    {id: 8193, ...positions["2"]},
    {id: 8194, ...positions["2"]},
    {id: 8195, ...positions["2"]},
    {id: 8192, ...positions["2"]},
    {id: 8190, ...positions["2"]},
    {id: 8189, ...positions["1"]},
    {id: 8187, ...positions["1"]},
    {id: 8186, ...positions["1"]},
  ], [
    {id: 8167, ...positions["1"]},
    {id: 8171, ...positions["1"]},
    {id: 8224, ...positions["2"]},
    {id: 8226, ...positions["2"]},
    {id: 8227, ...positions["1"]},
    {id: 8229, ...positions["2"]},
    {id: 8232, ...positions["1"]},
    {id: 8234, ...positions["2"]},
    {id: 8235, ...positions["2"]},
    {id: 8257, ...positions["1"]},
  ]
]

export default class Collage1Page extends React.Component {

  state = {
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.files = getWebviewInputFilesFromURL();
    this.processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      templates: templates.map((group) => group.random()),
    };
  }

  componentDidMount() {
    this.startProcessing();
  }

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      this.context.setNativeAdProcessingTexts(processingTexts);
      webviewShowNativeAds();
    }

    this.processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      templates: templates.map((group) => group.random()),
    };

    this.setState({updatedAt: Date.now()});

    Promise.resolve()
      .then(() => {
        return Promise.all([
          photolabGenderTask(this.files[0].url, 500, 500),
          photolabSimpleTask(this.props.faceTemplateId, this.files.first(), 2000, 500),
          // photolabSimpleTask(this.props.face2TemplateId, this.files.first(), 2000, 500),
        ]);
      })
      .then((tasksResults) => {
        this.gender = tasksResults[0].gender.value;
        this._preprocessingTaskResult = tasksResults[1];

        return Promise.all(this.processing.templates.map((template, index) => {
          const taskBuilder = new PhotolabTaskBuilder()
            .addMethod(new PhotolabTaskCollageMethod(template.id, {
              canvas_template_name: template.canvasId,
              mask_template_name: template.maskId,
              gender: this.gender === "male" ? "man" : "woman",
            }))
            .setLanguage(window.clientConfig.lang || "en")
            // .addImage(new PhotolabTaskImageUrl(tasksResults[(index === 0 || index === 1) ? 1 : 2].resultUrl));
            .addImage(new PhotolabTaskImageUrl(tasksResults[1].resultUrl));

          return photolabTask(taskBuilder.build(), 10000, 500);
        }));
      })
      .then((tasksResults) => {
        const taskBuilder = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(this.props.collageTemplateId))
          .setLanguage(window.clientConfig.lang || "en");

        tasksResults.forEach((taskResult) => {
          taskBuilder.addImage(new PhotolabTaskImageUrl(taskResult.resultUrl));
        });
        taskBuilder.addImage(new PhotolabTaskImageUrl(this._preprocessingTaskResult.resultUrl));

        return photolabTask(taskBuilder.build(), 1000, 500);
      })
      .then((taskResult) => {
        this.processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        // logCreativeProcessed(this.props.pageName, processing, {
        //   template_id: processing.template.id,
        //   input: this.files.map((f) => f.url),
        //   output: [taskResult.resultUrl],
        // });

        logCreativeResult(
          this.props.collageTemplateId,
          this.files,
          [{url: taskResult.resultUrl}],
          true,
          {
            face: this.props.faceTemplateId,
            templates: this.processing.templates.map((t) => t.id),
            gender: this.gender,
          }
        );

        this.processing.isProcessed = true;
        this.processing.isProcessing = false;
        this.processing.result = taskResult;

        this.handleConfirmResult(this.processing);
        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        this.processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        // logCreativeFailed(this.props.pageName, processing, {
        //   template_id: processing.template.id,
        //   input: this.files.map((f) => f.url),
        // });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        this.processing.isProcessing = false;
        this.processing.isFailed = true;
        this.processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: this.props.collageTemplateId,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  render() {
    return <section className="start-page">
      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={this.processing.isFailed}
            message={processingTexts} />

          {this.processing.isFailed && <ProcessingErrorView
            processing={this.processing}
            onRetryClick={this.handleRetryClick}
          />}
        </div>
      </div>
    </section>;
  }
}

Collage1Page.contextType = AppContext;
