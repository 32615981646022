import React from "react";
import {Route} from "react-router";
import SimplePickerPage from "./SimplePickerPage";
import {pwAssetUrl} from "../../../utils/etc";

const templates = {
  "pick-you-blusher": [7054, 7055].map((id) => {
    return {id};
  }),
  "pick-your-lip-gloss": [7049, 7050, 7051, 7052, 7053].map((id) => {
    return {id};
  }),
  "xmas22": [7677, 7678, 7679, 7680, 7681, 7682, 7683, 7684, 7685, 7686, 7687].map((id) => {
    return {
      id,
      previewUrl: pwAssetUrl("templates-previews/" + id + ".jpg")
    };
  }),
};

const routes = [{
  key: "pickYouBlusher_Route",
  path: "/pick-you-blusher",
  render: (props) => <SimplePickerPage
    {...props}
    pageName="pick-you-blusher"
    templates={templates["pick-you-blusher"]}
  />,
}, {
  key: "PickYourLipGloss_Route",
  path: "/pick-your-lip-gloss",
  render: (props) => <SimplePickerPage
    {...props}
    pageName="pick-your-lip-gloss"
    templates={templates["pick-your-lip-gloss"]}
  />,
}, {
  key: "xmas22_Route",
  path: "/xmas22",
  render: (props) => <SimplePickerPage
    {...props}
    pageName="xmas22"
    templates={templates["xmas22"]}
  />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}
