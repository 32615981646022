import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabAddTask, photolabGenderTask, PhotolabResponseError, photolabWaitTask} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import Loader from "../../components/Loader";
import config from "./config";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {assetUrl} from "../../../utils/etc";
import md5 from "md5";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

function encodeWebData(data) {
  return encodeURIComponent(JSON.stringify(data));
}

function decodeWebData(url) {
  const webData = (new URL(url)).searchParams.get("web_data");
  if (webData != null) {
    try {
      return JSON.parse(decodeURIComponent(webData));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return null;
}

function createAndWaitHeadTask(templateId, file) {
  const taskConfig = new PhotolabTaskBuilder()
    .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
    .addMethod(new PhotolabTaskCollageMethod(templateId))
    .build();

  return photolabAddTask(taskConfig)
    .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 3000, 1000));
}

function createResultTask(templateId, sourceFile, headImageUrl) {
  const taskConfig = new PhotolabTaskBuilder()
    .addImage(new PhotolabTaskImageUrl(sourceFile.url, sourceFile.rect, sourceFile.rotation, sourceFile.flip))
    .addImage(new PhotolabTaskImageUrl(headImageUrl))
    .addMethod(new PhotolabTaskCollageMethod(templateId))
    .build();

  return photolabAddTask(taskConfig)
    .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 3000, 1000));
}

function restoreSelectedTemplate(fileKey) {
  const value = window.localStorage.getItem("mysecretidentity:template:" + fileKey);
  return value ? JSON.parse(value) : null;
}

function storeSelectedTemplate(fileKey, id, gender) {
  window.localStorage.setItem("mysecretidentity:template:" + fileKey, JSON.stringify({id, gender}));
}

export default class MySecretIdentityPage extends React.Component {

  state = {
    isLoading: true,
    files: [],
    error: null,
    gender: "male",
    genderTaskResult: null,
    headTaskResult: null,
    templates: [],
    selectedTemplate: null,
    selectedTemplateIsUser: false,
    processings: [],
  };

  constructor(props) {
    super(props);

    this.state.files = getWebviewInputFilesFromURL();
    this.state.templates = config.templates.slice().shuffle();

    this.loadedUrl = new URL(window.location.href);
    this.webdata = decodeWebData(window.location.href);
  }

  componentDidMount() {
    this.init();
  }

  getWebdataTemplate = () => {
    return this.webdata
      && this.webdata.body
      && this.webdata.body.template
      && this.state.templates.find((template) => {
        return template.id === parseInt(this.webdata.body.template.id)
          && template.gender === this.webdata.body.template.gender;
      });
  };

  getTemplateByFile = (gender) => {
    const fileKey = md5(this.state.files[0].url);
    const restoredTemplate = restoreSelectedTemplate(fileKey);
    let selectedTemplate = null;

    if (restoredTemplate !== null) {
      selectedTemplate = this.state.templates
        .find((t) => t.gender === restoredTemplate.gender && t.id === restoredTemplate.id);
    }

    if (selectedTemplate === null) {
      selectedTemplate = this.state.templates
        .filter((t) => t.gender === gender)
        .random();
    }

    const selectedTemplateIndex = this.state.templates.indexOf(selectedTemplate);
    if (selectedTemplateIndex > 0) {
      this.state.templates.unshift(...this.state.templates.splice(selectedTemplateIndex, 1));
    }

    storeSelectedTemplate(fileKey, selectedTemplate.id, selectedTemplate.gender);

    return selectedTemplate;
  };

  init = () => {
    Promise.all([
      photolabGenderTask(this.state.files[0].url),
      createAndWaitHeadTask(5444, this.state.files[0]),
    ]).then(([genderTaskResult, headTaskResult]) => {
      let selectedTemplate = null;
      if (this.webdata) {
        selectedTemplate = this.getWebdataTemplate();
      }

      if (selectedTemplate == null) {
        selectedTemplate = this.getTemplateByFile(genderTaskResult.gender.value);
      }

      const selectedTemplateIndex = this.state.templates.indexOf(selectedTemplate);
      if (selectedTemplateIndex > 0) {
        this.state.templates.unshift(...this.state.templates.splice(selectedTemplateIndex, 1));
      }

      this.setState({
        isLoading: false,
        gender: selectedTemplate.gender,
        genderTaskResult,
        headTaskResult,
        selectedTemplate,
      }, () => {
        this.handleTemplateSelect(selectedTemplate, false);
      });
    }).catch((err) => {
      const error = {
        status: "Error",
        description: err.message,
      };

      if (err instanceof PhotolabResponseError) {
        error.err_code = err.code;
      }

      webviewSendResult(error);
    });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.templates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
    });

    this.setState({
      selectedTemplate: template,
      selectedTemplateIsUser: !!isUser,
    });

    let processing = this.state.processings.find((processing) => {
      return processing.template.id === template.id
          && processing.template.gender === template.gender;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = Object.assign({}, template);
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  };

  startProcessing = (processing) => {
    createResultTask(
      processing.template.id,
      this.state.files[0],
      this.state.headTaskResult.resultUrl
    ).then((taskResult) => {
      processing.finishedAt = Date.now();

      if (window.clientConfig.isWebview) {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }

      hitPhotoProcessed();
      logCreativeProcessed(this.props.pageName, processing);

      processing.isProcessing = false;
      processing.isProcessed = true;
      processing.result = taskResult;

      this.setState({processings: this.state.processings.slice()});
    }).catch((err) => {
      processing.finishedAt = Date.now();

      if (window.clientConfig.isWebview) {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }

      hitPhotoFailed();
      logCreativeFailed(this.props.pageName, processing);

      const logError = {
        page_name: this.props.pageName,
        name: err.name,
        code: err.code || undefined,
        message: err.message || undefined,
      };

      if (err instanceof PhotolabResponseError) {
        logError.taskRequestId = err.requestId;
        logError.taskInputImageUrl = this.state.files[0].url;
      }

      logEvent(userEvents.BODYCHOOSER__ERROR, logError);

      processing.isProcessing = false;
      processing.isFailed = true;
      processing.error = err;

      this.setState({processings: this.state.processings.slice()});
    });
  };

  handleConfirmResult = (processing) => {
    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderDetectionResult,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        body: {
          template: {
            id: processing.template.id,
            gender: processing.template.gender,
          },
        },
      }),
    });
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    this.setState({gender});
  };

  handleResultLoaded = (processing) => {
    const index = this.state.templates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const templates = this.state.templates.filter((template) => template.gender === this.state.gender);
    const processing = this.state.selectedTemplate && this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedTemplate.id
        && processing.template.gender === this.state.selectedTemplate.gender;
    });

    return <section className="start-page mysecretidentity">
      <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("start_page_title")}}/>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>

          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}
        </div>
      </div>

      <div className="templates-container">
        <div className="container">
          <button className={"btn-choice-gender " + (this.state.gender === config.genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.toggleGender}>
            <SvgSprite viewBox="0 0 48 48" icon="icon-arrow-back" />
            <span className="btn-choice-gender-title">
              {i18n.t(this.state.gender === config.genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}
              <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />
            </span>
          </button>
          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedTemplate ? (this.state.selectedTemplate.id === template.id && this.state.selectedTemplate.gender === template.gender) : false}
            previewUrl={assetUrl(`assets/images/bodychooser/bodies/${template.previewFileName || template.id}.png?1`)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>
    </section>;
  }
}

MySecretIdentityPage.contextType = AppContext;