import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  createTask,
  waitTask
} from "./api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewOpenBrowser, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import {pwAssetUrl, assetUrl} from "../../../utils/etc";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {checkDataSize, isEmpty, reorderMaskTemplates, transformData} from "./helpers";
import DumbImageView from "../../components/DumbImageView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

function templateTask(templateId, file) {
  return photolabTask(new PhotolabTaskBuilder()
    .setLanguage(window.clientConfig.lang || "en")
    .addMethod(new PhotolabTaskCollageMethod(templateId))
    .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
    .build());
}

function backgroundTask(file, backgroundFileUrl) {
  return photolabTask(new PhotolabTaskBuilder()
    .setLanguage(window.clientConfig.lang || "en")
    .addMethod(new PhotolabTaskCollageMethod(1978))
    .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
    .addImage(new PhotolabTaskImageUrl(backgroundFileUrl))
    .build());
}

function vectorHandler(file) {
  return templateTask(6617, file)
    .then((taskResult) => templateTask(6537, {url: taskResult.resultUrl, rect: "", rotation: 0, flip: 0}));
}

function disneyHandler(file) {
  return templateTask(6151, file)
    .then((taskResult) => templateTask(6597, {url: taskResult.resultUrl, rect: "", rotation: 0, flip: 0}));
}

function gfp936Handler(file) {
  return templateTask(6032, file)
    .then((taskResult) => templateTask(6598, {url: taskResult.resultUrl, rect: "", rotation: 0, flip: 0}));
}

function simpsonsHandler(file) {
  return backgroundTask(file, "https://toonme.com/assets/photolab/simpsons/background_blue.jpg")
    .then((taskResult) => templateTask(5938, {url: taskResult.resultUrl, rect: "", rotation: 0, flip: 0}));
}

function getHandlerByName(name) {
  switch (name) {
    case "vector":
      return vectorHandler;
    case "disney":
      return disneyHandler;
    case "gfp936":
      return gfp936Handler;
    case "simpsons":
      return simpsonsHandler;
    default:
      throw new Error(`unknown templateId: ${name}`);
  }
}

const views = {
  qrcode: "qrcode",
  creative: "creative",
};

export default class QRToonPage extends React.Component {
  state = {
    isLoading: true,
    files: [],
    genderTask: null,
    gender: config.genders.male,
    creativeTemplates: [],
    selectedCreativeTemplate: null,
    selectedCreativeTemplateIsUser: false,
    processings: [],
    webdata: null,
    view: views.creative,
    codeContent: "",
    qrcodeTask: {
      isProcessing: false,
      isProcessed: false,
      isFailed: false,
    },
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.creativeTasks = [];

    this.state.files = getWebviewInputFilesFromURL();
    this.state.webdata = decodeWebData(window.location.href);
    this.state.gender = window.localStorage.getItem("bodychooser:gender") || this.state.gender;
    this.state.creativeTemplates = reorderMaskTemplates(config.templates);
  }

  componentDidMount() {
    const template = this.getWebdataTemplate();

    if (this.state.webdata && template) {
      this.initBySelectedTemplate(template);
    } else {
      this.initWithGenderDetection();
    }
  }

  getWebdataTemplate = () => {
    return this.state.webdata
      && this.state.webdata.body
      && this.state.webdata.body.template
      && this.state.creativeTemplates.find((template) => {
        return template.id === parseInt(this.state.webdata.body.template.id)
          && template.gender === this.state.webdata.body.template.gender;
      });
  };

  initWithGenderDetection = () => {
    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => {
        this.setState({
          genderTask: taskResult,
          gender: taskResult.gender.value,
        }, () => {
          this.initBySelectedTemplate(this.getFirstBodyTemplate());
        });
      })
      .catch((err) => {
        console.error(err);
        this.initBySelectedTemplate(this.getFirstBodyTemplate());
      });
  };

  getFirstBodyTemplate = () => {
    return this.state.creativeTemplates.find((template) => template.gender === this.state.gender);
  };

  initBySelectedTemplate = (selectedTemplate) => {
    const creativePos = this.state.creativeTemplates.findIndex((t) => {
      return t.gender === selectedTemplate.gender
          && t.id === selectedTemplate.id;
    });

    if (creativePos > 0) {
      this.state.creativeTemplates.splice(creativePos, 1);
      this.state.creativeTemplates.unshift(selectedTemplate);
    }

    this.setState({
      isLoading: false,
      gender: selectedTemplate.gender,
      creativeTemplates: this.state.creativeTemplates.slice(),
      selectedCreativeTemplate: selectedTemplate,
    }, () => {
      this.handleTemplateSelect(selectedTemplate, false);
    });
  };

  getCreativeTask = (templateId, gender) => {
    const creativeTask = this.creativeTasks.find((t) => {
      return t.templateId === templateId && t.gender === gender;
    });

    if (creativeTask && creativeTask.result) {
      return Promise.resolve(creativeTask.result);
    }

    const handler = getHandlerByName(templateId);
    const file = this.state.files[0];

    return handler(file)
      .then((taskResult) => {
        this.creativeTasks.push({
          templateId,
          gender,
          result: taskResult
        });

        return taskResult;
      });
  };

  startProcessing = (processing) => {
    this.getCreativeTask(processing.template.id, processing.template.gender)
      .then((taskResult) => createTask("qrcode_web_template", {
          content_url: taskResult.resultUrl,
          code_content: "qrtoon.com",
          framed: false,
        })
      )
      .then((taskResult) => waitTask(taskResult.id))
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult;

        this.setState({processings: this.state.processings.slice()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({processings: this.state.processings.slice()});
      });
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.creativeTemplates
      .filter((t) => t.gender === template.gender)
      .findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      gender: template.gender,
      is_user_select: isUser,
      index,
    });

    this.setState({
      selectedCreativeTemplateIsUser: !!isUser,
      selectedCreativeTemplate: template,
    }, () => {
      this.startTemplate();
    });
  };

  startTemplate = () => {
    let processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedCreativeTemplate.id
          && processing.template.gender === this.state.selectedCreativeTemplate.gender;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = this.state.selectedCreativeTemplate;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  }

  handleConfirmResult = (processing) => {
    this.setState({
      view: views.qrcode
    });

    const index = this.state.creativeTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      gender_detection: this.state.genderTask ? this.state.genderTask.gender.value : null,
      is_user_select: this.state.selectedCreativeTemplateIsUser,
      index,
    });
  };

  toggleGender = () => {
    const gender = this.state.gender === config.genders.male
      ? config.genders.female
      : config.genders.male;

    window.localStorage.setItem("bodychooser:gender", gender);
    this.setState({gender});
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.creativeTemplates
      .filter((t) => t.gender === processing.template.gender)
      .findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedCreativeTemplateIsUser,
      index,
    });
  };

  handleCodeContentFormSubmit = (e) => {
    e.preventDefault();

    const codeContent = transformData(this.state.codeContent);

    if (isEmpty(codeContent)) {
      throw new Error("Please enter a valid link");
    }

    if (!checkDataSize(codeContent)) {
      throw new Error("Sorry. Your QR code data is too long");
    }

    this.setState({
      codeContent: codeContent,
      qrcodeTask: {
        isProcessing: true,
        isProcessed: false,
        isFailed: false,
      },
    }, this.handleCreateQRCode);
  }

  handleCreateQRCode = () => {
    const processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedCreativeTemplate.id
          && processing.template.gender === this.state.selectedCreativeTemplate.gender;
    });

    if (!processing) {
      throw new Error("An error occurred...");
    }

    this.getCreativeTask(processing.template.id, processing.template.gender)
      .then((taskResult) => createTask("qrcode_web_template", {
        content_url: taskResult.resultUrl,
        code_content: this.state.codeContent,
        framed: true,
      }))
      .then((taskResult) => waitTask(taskResult.id))
      .then((taskResult) => {
        console.log(taskResult);

        webviewSendResult({
          status: "OK",
          result_url: encodeURIComponent(taskResult.result[0].url),
          web_data: encodeWebData({
            body: {
              template: {
                id: processing.template.id,
                gender: processing.template.gender,
              },
            }
          }),
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          qrcodeTask: {
            isProcessing: false,
            isProcessed: false,
            isFailed: true,
            error: err,
          }
        });
      });
  }

  handleRetryQRCodeClick = () => {
    this.setState({
      qrcodeTask: {
        isProcessing: true,
        isProcessed: false,
        isFailed: false,
      },
    }, this.handleCreateQRCode);
  }

  handleGoToQRtoonButtonClick = (e) => {
    const processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedCreativeTemplate.id
        && processing.template.gender === this.state.selectedCreativeTemplate.gender;
    });

    if (!processing) {
      throw new Error("An error occurred...");
    }

    logEvent("qrtoon_banner_click", {
      template_id: processing.template.id,
      gender: processing.template.gender,
      is_user_select: this.state.selectedCreativeTemplateIsUser,
    });

    this.getCreativeTask(processing.template.id, processing.template.gender)
      .then((taskResult) => {
        let ref = "webtemplate";
        if (window.clientConfig.webviewParams["app_id"]) {
          ref += "_" + window.clientConfig.webviewParams["app_id"];
        }

        webviewOpenBrowser(
          "https://qrtoon.com/create"
          + "?ref=" + ref
          + "&file_url=" + encodeURIComponent(this.state.files[0].url)
          + "&rect=" + encodeURIComponent(this.state.files[0].rect)
          + "&rotation=" + encodeURIComponent(this.state.files[0].rotation)
          + "&flip=" + encodeURIComponent(this.state.files[0].flip)
          + "&result_url=" + encodeURIComponent(taskResult.resultUrl)
          + "&result_style=" + encodeURIComponent(processing.template.id)
        );
      })
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const templates = this.state.creativeTemplates
      .filter((template) => template.gender === this.state.gender);

    const processing = this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedCreativeTemplate.id
        && processing.template.gender === this.state.selectedCreativeTemplate.gender;
    });

    const currentStep = this.state.view === views.creative ? 1 : 2;
    const stepTitle = this.state.view === views.creative
      ? i18n.t("qrcode_title_step_1")
      : i18n.t("qrcode_title_step_2");

    return <section className="start-page qrcode">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container">
            <p dangerouslySetInnerHTML={{__html: i18n.t("step_count_text", {step_idx: currentStep, steps: 2})}}/>
          </div>
          <h2 dangerouslySetInnerHTML={{__html: stepTitle}} />
        </div>
      </div>

      <div className="collage-container" hidden={this.state.view !== views.creative}>
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <DumbImageView
            className="collage"
            image={processing.result.result[0].url}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.creative}>
        <div className="container">
          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedCreativeTemplate.id === template.id && this.state.selectedCreativeTemplate.gender === template.gender}
            previewUrl={pwAssetUrl(`templates-previews/${template.id}_${template.gender[0]}.png`)}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>

      <div className="collage-container" hidden={this.state.view !== views.qrcode}>
        <div className="container">
          <Loader
            hidden={!this.state.qrcodeTask.isProcessing}
            message={i18n.t("loader_processing")} />

          {this.state.qrcodeTask.isFailed && <div className="error-container">
            <div>
              <p children={i18n.t("error__api_code_1")} />
              <button
                children={i18n.t("btn_retry")}
                onClick={this.handleRetryQRCodeClick} />
            </div>
          </div>}

          {!this.state.qrcodeTask.isProcessing && !this.state.qrcodeTask.isFailed && <div className="qrcode-container">
            <div className="wrapper">
              <form onSubmit={this.handleCodeContentFormSubmit}>
                <input
                  value={this.state.codeContent}
                  onChange={(e) => this.setState({codeContent: e.target.value})}
                  placeholder={i18n.t("qrcode_enter_url_placeholder")} />
                <button
                  type="submit"
                  className="btn-check"
                  disabled={isEmpty(this.state.codeContent)}
                  children={<svg viewBox="0 0 64 48">
                  <path d="m20.356 37.867-15.2-14.934L0 28l20.356 20L64 5.067 58.844 0z" fillRule="evenodd"/>
              </svg>} />
              </form>

              <button className="banner" onClick={this.handleGoToQRtoonButtonClick}>
                <div className="banner-left">
                  <img src={assetUrl(`assets/images/banner/qr-1.png`)} alt='' />
                  <p className="banner-title" dangerouslySetInnerHTML={{__html: i18n.t("qrcode_footer_title")}}/>
                  <svg viewBox="0 0 233 51">
                    <path d="M219.605 22.043v3.949c-5.614-9.453-15.486-17.57-28.311-23.278-20.007-5.863-43.557-1.995-56.94 9.353-7.85 6.224-14.975 12.824-21.314 19.744-5.09 5.196-15.269 16.627-27.357 12.886-4.927-1.972-9.07-4.679-12.088-7.898a124.7 124.7 0 0 1-9.543-9.56c-4.372-5.945-11.646-10.77-20.677-13.717-11.13-1.663-22.718 1.105-29.583 7.066C5.793 26.298 1.072 33.642.432 41.37c0 .689.854 1.247 1.908 1.247 1.054 0 1.909-.558 1.909-1.247 2.066-6.954 7.145-13.374 14.632-18.497 7.953-5.404 19.723-9.145 28.948-2.702 6.11 5.529 11.534 11.366 16.223 17.458 4.44 5.712 11.735 10.259 20.677 12.886 21.313 3.74 34.673-15.172 42.944-23.277 8.27-8.106 29.584-25.772 52.805-20.784 10.97 2.709 20.03 7.962 25.448 14.756l12.088 12.678a80.614 80.614 0 0 0-6.362-2.494c-3.18-1.039-7.316 1.87-4.135 3.533l17.177 8.522c1.364 1.166 3.844 1.487 5.784.747 1.94-.74 2.734-2.308 1.851-3.657-1.909-6.027-2.863-12.054-5.09-18.082-.502-1.048-1.927-1.794-3.603-1.885-1.676-.092-3.27.49-4.031 1.47z" fill="#FFBA00" fillRule="nonzero"/>
                </svg>
                </div>
                <div className="banner-right">
                  <img src={assetUrl(`assets/images/banner/qr-2.png`)} alt='' />
                </div>
              </button>
            </div>
          </div>}
        </div>
      </div>
    </section>;
  }
}

QRToonPage.contextType = AppContext;
