import React from "react";
import {Route} from "react-router";
import Olympics22Page from "./Olympics22Page";

const routes = [{
  key: "olympics22Route",
  path: "/olympics22",
  render: (props) => <Olympics22Page {...props} pageName="olympics22" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}