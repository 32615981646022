import React from "react";
import {Route} from "react-router";
import NewYear22BackgroundPage from "./NewYear22BackgroundPage";

const routes = [{
  key: "NewYear22BackgroundRoute",
  path: "/happy-new-year-toons",
  render: (props) => <NewYear22BackgroundPage {...props} pageName="happy-new-year-toons" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}