import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {SvgMaskIcon} from "../../icons/SvgMaskIcon";
import {SvgArrowsIcon} from "../../icons/SvgArrowsIcon";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

export default class ValentinePage extends React.Component {

  state = {
    isSwaped: false,
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.headTasks = [null, null];
    this.processings = [];
    this.files = getWebviewInputFilesFromURL();

    const webdata = decodeWebData(window.location.href);
    if (webdata) {
      this.state.isSwaped = webdata.is_swaped;
    }

    this.masksEnabled = !window.clientConfig.isWebviewIOS
      || (window.clientConfig.isWebviewIOS && window.clientConfig.webviewToken.toLowerCase().indexOf("z") === 0);
  }

  componentDidMount() {
    this.startProcessing();
  }

  getHeadTask = (index) => {
    if (this.headTasks[index] != null && this.headTasks[index].resultUrl) {
      return Promise.resolve(this.headTasks[index]);
    }

    const file = index >= this.files.length ? this.files[0] : this.files[index];

    const headConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(
        file.url,
        file.rect,
        file.rotation,
        file.flip,
        file.alt_body || file.alt_body_template
      ))
      .addMethod(new PhotolabTaskCollageMethod(this.props.headTemplateId))
      .build();

    return photolabTask(headConfig, 1000, 500)
      .then((taskResult) => {
        this.headTasks[index] = taskResult;

        if (taskResult.masks && taskResult.masks.length > 0) {
          const mask = taskResult.masks.find((mask) => mask.index === 1 && mask.name === "body");
          file.alt_body_template = mask ? mask.url : null;
        }

        return taskResult;
      });
  };

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
    };

    this.processings.push(processing);

    Promise.all([this.getHeadTask(0), this.getHeadTask(1)])
      .then(() => {
        const taskBuilder = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(this.props.templateId))
          .setLanguage(window.clientConfig.lang || "en");

        (this.state.isSwaped ? [1, 0] : [0, 1]).forEach((index) => {
          if (index >= this.files.length) {
            index = 0;
          }

          taskBuilder.addImage(new PhotolabTaskImageUrl(
            this.headTasks[index].resultUrl,
            "",
            0,
            0,
            this.files[index].alt_body || this.files[index].alt_body_template
          ));
        })

        return photolabTask(taskBuilder.build(), 1000, 500);
      })
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
    });

    const resultData = {
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({is_swaped: this.state.isSwaped}),
    };

    if (this.masksEnabled) {
      this.files.forEach((file, index) => {
        if (file.fixed) {
          return;
        }

        const appIndex = index + 1;

        if (file.alt_body) {
          resultData["alt_body_" + appIndex] = encodeURIComponent(file.alt_body);
        } else if (file.alt_body_template) {
          resultData["body_" + appIndex] = encodeURIComponent(file.alt_body_template);
        }
      });
    }

    webviewSendResult(resultData);
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = () => {
    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
    });
  };

  handleEditMask = () => {
    const params = {};

    this.files.forEach((file, index) => {
      if (file.fixed) {
        return;
      }

      params[`src[${index}]`] = encodeURIComponent(file.src || file.url);
      params[`mask[${index}]`] = encodeURIComponent(file.alt_body || file.alt_body_template);
    });

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    data.forEach((item) => {
      if (item["0"]) {
        this.files[0].alt_body = item["0"];
      } else if (item["1"]) {
        this.files[1].alt_body = item["1"];
      }
    });

    this.headTasks = [null, null];
    this.processings = [];
    this.startProcessing();

    this.setState({updatedAt: Date.now()});
  };

  handleSwap = () => {
    this.processings = [];
    this.startProcessing();

    this.setState({isSwaped: !this.state.isSwaped});
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  render() {
    const processing = this.processings.last();

    if (!processing || processing.isProcessing) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsHidden = !maskSupport || !this.masksEnabled || processing.isFailed;

    return <section className="start-page valentine22">
      <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />
          <button
            className="btn-edit-mask"
            hidden={editMaskButtonIsHidden}
            onClick={() => this.handleEditMask()}>
            <SvgMaskIcon />
          </button>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>
      {this.headTasks[0] && this.headTasks[1] && <div className="templates-container">
        <button onClick={this.handleSwap} className="btn-swap">
          <div className="btn-images">
            <img src={this.headTasks[0].resultUrl} alt="" />
            <SvgArrowsIcon />
            <img src={this.headTasks[1].resultUrl} alt="" />
          </div>
          <span dangerouslySetInnerHTML={{__html: i18n.t("button_tap_to_swap")}} />
        </button>
      </div>}
    </section>;
  }
}

ValentinePage.contextType = AppContext;
