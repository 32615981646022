export const templates = [
  {id: 7872, weight: 336},
  {id: 7882, weight: 324},
  {id: 7835, weight: 279},
  {id: 7837, weight: 277},
  {id: 7832, weight: 273},
  {id: 7876, weight: 258},
  {id: 7833, weight: 254},
  {id: 7874, weight: 245},
  {id: 7834, weight: 242},
  {id: 7875, weight: 237},
  {id: 8083, weight: 237},
  {id: 8089, weight: 364},
  {id: 7921, weight: 321},
  {id: 7940, weight: 287},
  {id: 7917, weight: 279},
  {id: 7951, weight: 267},
  {id: 7931, weight: 265},
  {id: 8092, weight: 249},
  {id: 7927, weight: 249},
  {id: 7929, weight: 242},
  {id: 7919, weight: 241},
  {id: 7922, weight: 315},
  {id: 7941, weight: 296},
  {id: 7918, weight: 268},
  {id: 8082, weight: 267},
  {id: 7939, weight: 238},
  {id: 7952, weight: 232},
  {id: 7956, weight: 436},
  {id: 7993, weight: 400},
  {id: 7965, weight: 342},
  {id: 7955, weight: 327},
  {id: 7946, weight: 315},
  {id: 7971, weight: 302},
  {id: 7995, weight: 301},
  {id: 7960, weight: 298},
  {id: 7962, weight: 268},
  {id: 7996, weight: 248},
  {id: 7997, weight: 243},
  {id: 7994, weight: 237},
  {id: 7970, weight: 232},
];

export const celebrities = {
  "male": [
    {id: "f1"},
    {id: "f2"},
    {id: "f3"},
    {id: "f4"},
    {id: "f5"},
    {id: "f6"},
    {id: "f7"},
    {id: "f8"},
    {id: "f9"},
    {id: "f10"},
    {id: "f11"},
    {id: "f12"},
    {id: "f13"},
    {id: "f14"},
    {id: "f15"},
    {id: "f16"},
    {id: "f17"},
    {id: "f18"},
    {id: "f19"},
    {id: "f20"},
    {id: "f21"},
    {id: "f22"},
  ],
  "female": [
    {id: "bradley-cooper"},
    {id: "clooney"},
    {id: "downey"},
    {id: "harrystyles"},
    {id: "law"},
    {id: "michael-b-jordan"},
    {id: "reynolds"},
    {id: "statham"},
    {id: "cavil"},
    {id: "depp"},
    {id: "gosling"},
    {id: "idriselba"},
    {id: "leto"},
    {id: "pattinson"},
    {id: "rock"},
    {id: "tom-cruise"},
    {id: "cho"},
    {id: "dicaprio"},
    {id: "hardy"},
    {id: "keanu-reeves"},
    {id: "mcconaughey"},
    {id: "pitt"},
    {id: "rudd"},
    {id: "zack"},
  ],
}
