import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  PhotolabResponseError,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import {pwAssetUrl} from "../../../utils/etc";
import config from "./config";
import Loader from "../../components/Loader";
import TemplateItemView from "../../components/TemplateItemView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../../utils/photolab.utils";
import {SvgMaskIcon} from "../../icons/SvgMaskIcon";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

export default class Olympics22Page extends React.Component {

  state = {
    isLoading: false,
    files: [],
    templates: [],
    selectedTemplate: null,
    selectedTemplateIsUser: false,
    processings: [],
    maskUrl: null,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));

    this.state.files = getWebviewInputFilesFromURL();
    this.state.templates = config.templates.shuffle();

    const ruPos = this.state.templates.findIndex((t) => t.cc2 === "RU");
    if (ruPos <= 5) {
      this.state.templates.splice(
          5 + Math.floor(Math.random() * (this.state.templates.length - 5)),
          0,
          ...this.state.templates.splice(ruPos, 1)
      );
    }

    const candidates = [
      "UA",
      // (this.loadedUrl.searchParams.get("geoip_country") || "").toUpperCase(),
      // (this.loadedUrl.searchParams.get("country") || "").toUpperCase(),
    ].map((cc2) => {
      return this.state.templates.find((template) => template.cc2 === cc2);
    });

    // if (this.noUI) {
      const webdata = decodeWebData(window.location.href);
      if (webdata) {
        this.noUI = true;
        candidates.unshift(this.state.templates.find((template) => template.id === webdata.template_id));
      }
    // }

    this.state.selectedTemplate = candidates.find((candidate) => !!candidate);
    if (!this.state.selectedTemplate) {
      this.state.selectedTemplate = this.state.templates.find((t) => t.id === "CHN");
    }

    if (!this.state.selectedTemplate) {
      this.state.selectedTemplate = this.state.templates.first();
    }

    const pos = this.state.templates.indexOf(this.state.selectedTemplate)
    if (pos > 0) {
      this.state.templates.unshift(...this.state.templates.splice(pos, 1));
    }

    if (this.noUI) {
      this.state.isLoading = true;
    }
  }

  componentDidMount() {
    this.handleTemplateSelect(this.state.selectedTemplate, false);
  }

  getHeadTask = () => {
    if (this.headTaskResult != null && this.headTaskResult.resultUrl) {
      return Promise.resolve(this.headTaskResult);
    }

    const file = this.state.files[0];
    const headConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip, file.alt_body))
      .addMethod(new PhotolabTaskCollageMethod(6629))
      .build();

    return photolabTask(headConfig, 1000, 500)
      .then((taskResult) => {
        this.headTaskResult = taskResult;
        if (taskResult.masks && taskResult.masks.length > 0) {
          const mask = taskResult.masks.find((mask) => mask.index === 1 && mask.name === "body");
          this.setState({maskUrl: mask && mask.url});
        }
      });
  };

  startProcessing = (processing) => {
    this.getHeadTask()
      .then(() => {
        const taskConfig = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(6664))
          .addImage(new PhotolabTaskImageUrl(this.headTaskResult.resultUrl, "", 0, 0, this.state.files[0].alt_body || this.state.maskUrl))
          .addImage(new PhotolabTaskImageUrl(pwAssetUrl(`olympics22/${processing.template.id}.jpg?v3`)))
          .setLanguage(window.clientConfig.lang || "en")
          .build();

        return photolabTask(taskConfig, 1000, 500);
      })
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({processings: this.state.processings.slice()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.BODYCHOOSER__ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({processings: this.state.processings.slice()});
      });
  };

  handleEditMask = () => {
    const fileUrl = this.state.files[0].src || this.state.files[0].url;
    const maskUrl = this.state.files[0].alt_body || this.state.maskUrl;
    const params = {
      "src[0]": encodeURIComponent(fileUrl),
      "mask[0]": encodeURIComponent(maskUrl),
    };

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    const files = this.state.files.slice();
    files[0].alt_body = data[0]["0"];

    this.headTaskResult = null;

    this.setState({
      files,
      processings: [],
    }, () => this.handleTemplateSelect(this.state.selectedTemplate, true));
  };

  handleTemplateSelect = (template, isUser) => {
    const index = this.state.templates.findIndex((t) => t.id === template.id);

    logEvent(userEvents.BODYCHOOSER__SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
      is_user_select: isUser,
      index,
    });

    this.setState({
      selectedTemplateIsUser: !!isUser,
      selectedTemplate: template,
    });

    let processing = this.state.processings.find((processing) => {
      return processing.template.id === template.id;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.template = template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      if (window.clientConfig.isWebview) {
        webviewShowNativeAds();
      }

      this.startProcessing(processing);
    });
  };

  handleConfirmResult = (processing) => {
    const index = this.state.templates.findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });

    const resultData = {
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({template_id: processing.template.id})
    };

    if (this.state.files[0].alt_body) {
      resultData.alt_body_1 = encodeURIComponent(this.state.files[0].alt_body);
    } else if (this.state.maskUrl) {
      resultData.body_1 = encodeURIComponent(this.state.maskUrl);
    }

    webviewSendResult(resultData);
  };

  handleRetryClick = (processing) => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.startedAt = Date.now();

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  handleResultLoaded = (processing) => {
    const index = this.state.templates.findIndex((t) => t.id === processing.template.id);

    logEvent(userEvents.BODYCHOOSER__LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      is_user_select: this.state.selectedTemplateIsUser,
      index,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    const templates = this.state.templates
      .filter((template) => template.gender === this.state.gender);

    const processing = this.state.selectedTemplate && this.state.processings.find((processing) => {
      return processing.template.id === this.state.selectedTemplate.id;
    });

    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsHidden = !processing
      || !processing.isProcessed
      || !maskSupport
    ;

    return <section className="start-page olympics22">
      <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("olympics22__start_page_title")}}/>
          <button
            className="btn-edit-mask"
            hidden={editMaskButtonIsHidden}
            onClick={() => this.handleEditMask()}>
            <SvgMaskIcon />
          </button>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container">
        <div className="container">
          {templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.selectedTemplate ? (this.state.selectedTemplate.id === template.id) : false}
            previewUrl={pwAssetUrl(`templates-previews/olympics22/${template.id}.jpg?v3`)}
            children={<span>{template.id}</span>}
            onClick={(template) => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>
    </section>;
  }
}

Olympics22Page.contextType = AppContext;
