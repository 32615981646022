module.exports = {
  "strings": {

    "can_swap_or_continue": "您可换面或保持原样继续",
    "button_tap_to_swap": "点按交换",

    "button_choice_gender_male": "男性",
    "button_choice_gender_female": "女性",
    "button_choice_gender_text": "风格",
    "btn_retry": "重试",

    "step_count_text": "第 {{step_idx}} 步，共 {{steps}} 步",
    "start_page_title": "选择您最喜欢的风格",
    "start_page_title_2": "选择您最喜欢的风格",
    "olympics22__start_page_title" : "您支持谁？",

    "title__choose_body": "选择身体样式",
    "title__choose_crop": "选择身体样式",
    "title__choose_background": "选择背景风格",
    "gfp936_title_choose_crop_backgroud": "选择肖像裁剪和背景",

    "loader_processing": "正在处理",

    "vector_tab_refresh__title": "就快好了！",
    "vector_tab_refresh__subtitle": "选择头部样式",

    "error_modal__default_message": "出问题了。。。",
    "error_modal__network_message": "请检查网络连接。",

    "footer_title": "选择身体样式",

    "more_styles": "更多风格",
  },
};
