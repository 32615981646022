import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {photolabAddTask, photolabGenderTask, photolabTask, photolabWaitTask} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult, webviewShowNativeAds
} from "../../../utils/webview";
import config from "./config";
import Loader from "../../components/Loader";
import {logCreativeFailed, logCreativeProcessed} from "../../../utils/log";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

export default class HalloweenTvPage extends React.Component {

  state = {
    files: [],
  };

  constructor(props) {
    super(props);
    this.loadedUrl = new URL(window.location.href);
    this.state.files = getWebviewInputFilesFromURL();
    this.start();
  }

  start = () => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processing = {};

    photolabGenderTask(this.state.files[0].url)
      .then((taskResult) => taskResult.gender.value)
      .catch(() => [config.genders.male, config.genders.female].random())
      .then((gender) => {
        const templates = config.templatesUsedForTvTemplate[gender];
        const template1 = templates.random();
        const template2 = templates.filter((t) => t !== template1).random();
        const file = this.state.files[0];

        processing.startedAt = Date.now();

        return Promise.all([
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
            .addMethod(new PhotolabTaskCollageMethod(4021))
            .build(), 2000),
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
            .addMethod(new PhotolabTaskCollageMethod(template1))
            .build(), 2000),
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(file.url, file.rect, file.rotation, file.flip))
            .addMethod(new PhotolabTaskCollageMethod(template2))
            .build(), 2000),
        ]);
      })
      .then((tasksResults) => {
        return Promise.all([
          tasksResults[0],
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(tasksResults[1].resultUrl))
            .addMethod(new PhotolabTaskCollageMethod(6037))
            .build(), 2000),
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(tasksResults[2].resultUrl))
            .addMethod(new PhotolabTaskCollageMethod(6037))
            .build(), 2000),
        ]);
      })
      .then((tasksResults) => {
        return photolabAddTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(config.tvTemplate.id, {animation: true}))
          .addImage(new PhotolabTaskImageUrl(tasksResults[0].resultUrl))
          .addImage(new PhotolabTaskImageUrl(tasksResults[1].resultUrl))
          .addImage(new PhotolabTaskImageUrl(tasksResults[2].resultUrl))
          .build());
      })
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 2000))
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing);

        webviewSendResult({
          status: "OK",
          result_url: encodeURIComponent(taskResult.resultUrl),
        });
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing);

        webviewSendResult({
          status: "Error",
          description: err.message,
        });
      });
  };

  render() {
    return <Loader />;
  }
}

HalloweenTvPage.contextType = AppContext;