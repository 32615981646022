import axios from "axios";
import axiosRetry from "axios-retry";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";

const xmlParser = new DOMParser();
const httpClient = axios.create({
  baseURL: window.appConfig.photolab.path,
});

axiosRetry(httpClient, {
  retries: 3,
  retryDelay: 1000,
});

export class PhotolabResponseError extends Error {
  constructor(xmldoc, requestId = undefined) {
    super();
    this.name = "PhotolabResponseError";
    this.requestId = requestId;
    this.code = parseInt(xmldoc.documentElement.querySelector("err_code").textContent);
    this.message = xmldoc.documentElement.querySelector("description").textContent + " (" + this.code + ")";
  }
}

export class PhotolabResponseParseError extends Error {
  constructor(xmldoc) {
    super();
    this.name = "PhotolabResponseParseError";
    this.code = -1;
    this.message = `Error XML response parsing`;
    // todo extract error message from xmldoc
  }
}

export class PhotolabGetResultTimeoutError extends Error {
  constructor(taskId, amounts) {
    super();
    this.name = "PhotolabGetResultTimeoutError";
    this.requestId = taskId;
    this.code = -1;
    this.message = `Max attempts for getResult task is reached (${amounts})`;
    this.amounts = amounts;
  }
}

function checkError(err) {
  throw err;
}

function parseAddTaskResponse(data) {
  const xmldoc = xmlParser.parseFromString(data, "application/xml");

  if (xmldoc.documentElement.nodeName === "parsererror") {
    throw new PhotolabResponseParseError(xmldoc);
  }

  const errorCodeNode = xmldoc.documentElement.querySelector("err_code");

  if (errorCodeNode !== null && errorCodeNode.textContent !== "0") {
    throw new PhotolabResponseError(xmldoc);
  }

  return {
    requestId: xmldoc.documentElement.querySelector("request_id").textContent,
    status: xmldoc.documentElement.querySelector("status").textContent,
    errorCode: xmldoc.documentElement.querySelector("err_code").textContent,
    description: xmldoc.documentElement.querySelector("description").textContent,
  };
}

function addTask(data, signData) {
  const formData = new FormData();
  formData.append("app_id", window.appConfig.photolab.appId);
  formData.append("data", data);
  formData.append("sign_data", signData);

  return httpClient.post("/addtask", formData)
    .then((res) => parseAddTaskResponse(res.data))
    .catch(checkError);
}

function getResultTask(taskId) {
  return httpClient.post("/getresult?request_id=" + taskId + "&r=" + Math.random())
    .then((res) => {
      const xmldoc = xmlParser.parseFromString(res.data, "application/xml");

      if (xmldoc.documentElement.nodeName === "parsererror") {
        throw new PhotolabResponseParseError(xmldoc);
      }

      if (xmldoc.documentElement.querySelector("err_code") !== null) {
        throw new PhotolabResponseError(xmldoc, taskId);
      }

      const status = xmldoc.documentElement.querySelector("status").textContent;
      const response = {
        requestId: xmldoc.documentElement.querySelector("request_id").textContent,
        status: status,
        getRawString: () => res.data,
      };

      if (status === "OK") {
        const resultUrlNode = xmldoc.documentElement.querySelector("result_url");
        if (resultUrlNode) {
          response.resultUrl = resultUrlNode.textContent;
        }

        response.duration = xmldoc.documentElement.querySelector("duration").textContent;
        response.totalDuration = xmldoc.documentElement.querySelector("total_duration").textContent;

        const genderNode = xmldoc.documentElement.querySelector("gender");
        if (genderNode) {
          response.gender = {
            value: genderNode.querySelector("value").textContent,
            probability: parseFloat(genderNode.querySelector("probability").textContent),
          };
        }

        const resultsNode = xmldoc.documentElement.querySelector("results");
        if (resultsNode) {
          response.results = [];

          resultsNode.childNodes.forEach((resultNode) => {
            response.results.push({
              templateId: parseInt(resultNode.getAttribute("template_name")),
              resultUrl: resultNode.textContent,
            });
          });
        }

        const masksNode = xmldoc.documentElement.querySelector("masks");
        if (masksNode) {
          response.masks = [];

          masksNode.childNodes.forEach((maskNode) => {
            response.masks.push({
              index: parseInt(maskNode.getAttribute("index")),
              name: maskNode.getAttribute("name"),
              url: maskNode.textContent,
            });
          });
        }
      }

      return response;
    })
    .catch(checkError);
}

export function photolabSimpleTask(templateId, imageUrl, timeout = 1000, interval = 1000) {
  const taskConfig = new PhotolabTaskBuilder()
    .addImage(new PhotolabTaskImageUrl(imageUrl))
    .addMethod(new PhotolabTaskCollageMethod(templateId))
    .setLanguage(window.clientConfig.lang || "en")
    .build();

  return photolabTask(taskConfig, timeout, interval);
}

export function photolabGenderTask(imageUrl, timeout = 1000, interval = 1000) {
  return photolabSimpleTask("gender_classifier", imageUrl, timeout, interval);
}

export function photolabTask(taskConfig, timeout = 1000, interval = 1000) {
  return photolabAddTask(taskConfig)
    .then((taskResult) => {
      console.log(["ADD TASK", taskResult.requestId, formatXml(taskConfig)].join("\n"));

      return photolabWaitTask(taskResult.requestId, timeout, interval)
        .then((taskResult) => {
          console.log(["GET RESULT", taskResult.requestId, formatXml(taskResult.getRawString())].join("\n"));

          return taskResult;
        });
    });
}

export function photolabAddTask(taskXmlConfig) {
  const params = {
    app_id: window.appConfig.photolab.appId,
    client_token: window.clientConfig.token,
    client_build: 1,
    project_name: window.appConfig.project.name,
    do_add_task: true,
    tasks: [taskXmlConfig],
  };

  const responseFunc = (res) => {
    if (params.do_add_task) {
      return parseAddTaskResponse(res.data[0].data);
    } else {
      return addTask(taskXmlConfig, res.data[0].data);
    }
  };

  return httpClient.post(window.appConfig.photolab.signWithAddTaskEndpoint, params)
    .then(responseFunc)
    .catch((err) => {
      if (params.do_add_task) {
        delete params.do_add_task;
        return httpClient.post(window.appConfig.photolab.signEndpoint, params)
          .then(responseFunc)
          .catch((err) => {
            throw err;
          });
      } else {
        throw err;
      }
    });

  // const signData = signTask(taskXmlConfig);
  // return addTask(taskXmlConfig, signData);
}

export function photolabWaitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0, requestsAmountMax = 0) {
  requestsAmount++;

  function _call(resolve, reject) {
    getResultTask(taskId).then((taskResult) => {
      if (taskResult.status === "OK") {
        taskResult.getResultRequestsAmount = requestsAmount;
        resolve(taskResult);
      } else {
        if (requestsAmountMax > 0 && requestsAmount === requestsAmountMax) {
          reject(new PhotolabGetResultTimeoutError(taskId, requestsAmountMax));
        } else {
          setTimeout(() => {
            photolabWaitTask(taskId, 0, interval, requestsAmount, requestsAmountMax).then(resolve).catch(reject);
          }, interval || 1000);
        }
      }
    }).catch((error) => {
      error.getResultRequestsAmount = requestsAmount;
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}

// https://stackoverflow.com/a/49458964
function formatXml(xml, tab) {
  let formatted = '', indent= '';
  tab = tab || '\t';
  xml.split(/>\s*</).forEach(function(node) {
    if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    formatted += indent + '<' + node + '>\r\n';
    if (node.match( /^<?\w[^>]*[^/]$/ )) indent += tab;              // increase indent
  });
  return formatted.substring(1, formatted.length-3);
}