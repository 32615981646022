import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError, photolabSimpleTask,
  photolabTask
} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import {logCreativeResult, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";

const processingTexts = [
  "AI is looking for stunning<br />looks for you 🔍",
  "Any idea what they’re<br />gonna look like? 🙈",
  "Just wait for it… 🪄",
  "AI’s fantasies<br />are coming true 🧚",
];

const templates = {
  male: [
    {id: 8296, canvasId: 8206, maskId: 8207},
    {id: 8373, canvasId: 8206, maskId: 8207},
    {id: 8446, canvasId: 8206, maskId: 8207},
    {id: 8342, canvasId: 8206, maskId: 8207},
    {id: 8447, canvasId: 8206, maskId: 8207},
    {id: 8297, canvasId: 8206, maskId: 8207},
    {id: 8302, canvasId: 8206, maskId: 8207},
    {id: 8378, canvasId: 8206, maskId: 8207},
    {id: 8300, canvasId: 8206, maskId: 8207},
    {id: 8382, canvasId: 8206, maskId: 8207},
    {id: 8408, canvasId: 8206, maskId: 8207},
    {id: 8340, canvasId: 8206, maskId: 8207},
    {id: 8298, canvasId: 8206, maskId: 8207},
    {id: 8335, canvasId: 8206, maskId: 8207},
    {id: 8384, canvasId: 8206, maskId: 8207},
    {id: 8289, canvasId: 8206, maskId: 8207},
    {id: 8522, canvasId: 8206, maskId: 8207},
    {id: 8455, canvasId: 8206, maskId: 8207},
    {id: 8429, canvasId: 8206, maskId: 8207},
    {id: 8412, canvasId: 8206, maskId: 8207},
  ],
  female: [
    {id: 8296, canvasId: 8206, maskId: 8207},
    {id: 8297, canvasId: 8206, maskId: 8207},
    {id: 8342, canvasId: 8206, maskId: 8207},
    {id: 8412, canvasId: 8206, maskId: 8207},
    {id: 8335, canvasId: 8206, maskId: 8207},
    {id: 8298, canvasId: 8206, maskId: 8207},
    {id: 8373, canvasId: 8206, maskId: 8207},
    {id: 8302, canvasId: 8206, maskId: 8207},
    {id: 8340, canvasId: 8206, maskId: 8207},
    {id: 8522, canvasId: 8206, maskId: 8207},
    {id: 8382, canvasId: 8206, maskId: 8207},
    {id: 8314, canvasId: 8206, maskId: 8207},
    {id: 8303, canvasId: 8206, maskId: 8207},
    {id: 8418, canvasId: 8206, maskId: 8207},
    {id: 8385, canvasId: 8206, maskId: 8207},
    {id: 8332, canvasId: 8206, maskId: 8207},
    {id: 8384, canvasId: 8206, maskId: 8207},
    {id: 8364, canvasId: 8206, maskId: 8207},
    {id: 8446, canvasId: 8206, maskId: 8207},
    {id: 8361, canvasId: 8206, maskId: 8207},
  ],
};

export default class Collage2Page extends React.Component {

  state = {
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.files = getWebviewInputFilesFromURL();
    this.processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      templates: [],
    };
  }

  componentDidMount() {
    this.startProcessing();
  }

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      this.context.setNativeAdProcessingTexts(processingTexts);
      webviewShowNativeAds();
    }

    this.processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      templates: [],
    };

    this.setState({updatedAt: Date.now()});

    Promise.resolve()
      .then(() => {
        return Promise.all([
          photolabGenderTask(this.files.first(), 500, 500),
          photolabSimpleTask(this.props.faceTemplateId, this.files.first(), 2000, 500),
        ]);
      })
      .then((tasksResults) => {
        this.gender = tasksResults[0].gender.value;
        this._preprocessingTaskResult = tasksResults[1];
        this.processing.templates = templates[this.gender].random(4);

        return Promise.all(this.processing.templates.map((template, index) => {
          const taskBuilder = new PhotolabTaskBuilder()
            .addMethod(new PhotolabTaskCollageMethod(template.id, {
              canvas_template_name: template.canvasId,
              mask_template_name: template.maskId,
              gender: this.gender === "male" ? "man" : "woman",
            }))
            .setLanguage(window.clientConfig.lang || "en")
            .addImage(new PhotolabTaskImageUrl(tasksResults[1].resultUrl));

          return photolabTask(taskBuilder.build(), 10000, 500);
        }));
      })
      .then((tasksResults) => {
        const taskBuilder = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(this.props.collageTemplateId))
          .setLanguage(window.clientConfig.lang || "en");

        tasksResults.forEach((taskResult) => {
          taskBuilder.addImage(new PhotolabTaskImageUrl(taskResult.resultUrl));
        });
        taskBuilder.addImage(new PhotolabTaskImageUrl(this._preprocessingTaskResult.resultUrl));

        return photolabTask(taskBuilder.build(), 1000, 500);
      })
      .then((taskResult) => {
        this.processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        // logCreativeProcessed(this.props.pageName, processing, {
        //   template_id: processing.template.id,
        //   input: this.files.map((f) => f.url),
        //   output: [taskResult.resultUrl],
        // });

        logCreativeResult(
          this.props.collageTemplateId,
          this.files,
          [{url: taskResult.resultUrl}],
          true,
          {
            face: this.props.faceTemplateId,
            templates: this.processing.templates.map((t) => t.id),
            gender: this.gender,
          }
        );

        this.processing.isProcessed = true;
        this.processing.isProcessing = false;
        this.processing.result = taskResult;

        this.handleConfirmResult(this.processing);
        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        this.processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        // logCreativeFailed(this.props.pageName, processing, {
        //   template_id: processing.template.id,
        //   input: this.files.map((f) => f.url),
        // });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        this.processing.isProcessing = false;
        this.processing.isFailed = true;
        this.processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: this.props.collageTemplateId,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  render() {
    return <section className="start-page">
      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={this.processing.isFailed}
            message={processingTexts} />

          {this.processing.isFailed && <ProcessingErrorView
            processing={this.processing}
            onRetryClick={this.handleRetryClick}
          />}
        </div>
      </div>
    </section>;
  }
}

Collage2Page.contextType = AppContext;
