import React from "react";

const LOADING_TEXT_INTERVAL = 3000;

export default class Loader extends React.Component {

  state = {
    textIndex: 0,
  };

  updateTextIndexTimer = null;

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    const texts = this.getTexts();

    if (this.state.textIndex === texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  getTexts = () => {
    return Array.isArray(this.props.message) ? this.props.message : [this.props.message];
  }

  render() {
    const texts = this.getTexts();
    const message = texts[this.state.textIndex] || "";

    if (window.clientConfig.isWebviewIOS) {
      return window.appConfig.isToonCoinApp
        ? <ToonCoinIosLoader hidden={this.props.hidden} message={message} />
        : <IosLoader hidden={this.props.hidden} message={message} />;
    } else {
      return <AndroidLoader hidden={this.props.hidden} message={message} />
    }
  }
}

function ToonCoinIosLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container">
    <div>
      <div className="loader-ios">
        <span className="item-loader first-item-loader" />
        <span className="item-loader second-item-loader" />
        <span className="item-loader third-item-loader" />
      </div>
      <p dangerouslySetInnerHTML={{__html: message}} />
    </div>
  </div>;
}

function IosLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container">
    <div className="loader-ios">
      <span className="item-loader first-item-loader" />
      <span className="item-loader second-item-loader" />
      <span className="item-loader third-item-loader" />
    </div>
    <p dangerouslySetInnerHTML={{__html: message}} />
  </div>;
}

function AndroidLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container android">
    <div>
      <p dangerouslySetInnerHTML={{__html: message}} />
      <div className="loader-android">
        <span />
        <span />
      </div>
    </div>
  </div>;
}