import amplitude from "amplitude-js";
import axios from "axios";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProperties});
}

export const userEvents = {
  BODYCHOOSER__SELECT: "bodychooser__select",
  BODYCHOOSER__LOADED: "bodychooser__loaded",
  BODYCHOOSER__CONFIRM: "bodychooser__confirm",
  BODYCHOOSER__ERROR: "bodychooser__error",

  HEADBODYCHOOSER__HEAD_TASK_PROCESSED: "headbodychooser__head_task_processed",
  HEADBODYCHOOSER__HEAD_TASK_FAILED: "headbodychooser__head_task_failed",

  ITEM_SELECT: "item_select",
  ITEM_LOADED: "item_loaded",
  ITEM_CONFIRM: "item_confirm",
  ITEM_ERROR: "item_error",
};

export function logCreativeFailed(pageName, processing, extra = {}) {
  if (!processing.startedAt || !processing.finishedAt) {
    console.error("logCreativeFailed: Processing hans't field 'startedAt' or 'finishedAt'.")
    return;
  }

  logEvent("creative_failed", {
    page_name: pageName,
    time: processing.finishedAt - processing.startedAt,
    ...extra,
  });
}

export function logCreativeProcessed(pageName, processing, extra = {}) {
  if (!processing.startedAt || !processing.finishedAt) {
    console.error("logCreativeProcessed: Processing hans't field 'startedAt' or 'finishedAt'.")
    return;
  }

  const time = processing.finishedAt - processing.startedAt;

  logEvent("creative_processed", {
    page_name: pageName,
    time,
    ...extra,
  });

  logProcessingsTimings(time);
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};

  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, JSON.stringify(eventParams, null, 2));
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    let webviewParams = undefined;
    if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
      webviewParamsIsCommited = true;
      webviewParams = window.clientConfig.webviewParams;
    }

    axios.post(window.appConfig.analytics.endpoint, {
      client_token: window.clientConfig.token,
      client_params: userParams,
      client_webview_params: webviewParams,
      project_name: window.appConfig.project.name,
      event_name: eventId,
      event_params: eventParams,
      locale: window.clientConfig.locale,
    }).then((res) => {
      cb && cb();
    }).catch((err) => {
      console.error(err);
    });
  }
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}

export function logCreativeResult(templateId, inputData, outputData, isDownloaded, extra = {}) {
  if (!window.appConfig.resultsCollect.isEnabled) {
    return;
  }

  axios.post(window.appConfig.resultsCollect.endpoint, {
    client_token: window.clientConfig.token,
    project_name: window.appConfig.project.name,
    template_id: templateId,
    input_data: inputData,
    output_data: outputData,
    is_downloaded: isDownloaded,
    extra,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}