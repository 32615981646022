import React from "react";
import PropTypes from "prop-types";

export default function TemplateItemView({
  template,
  isActive,
  onClick,
  previewUrl,
  children,
  className
}) {
  const classNames = [];

  classNames.push("btn-choice-template");

  if (typeof template === "object") {
    classNames.push("btn-choice-template--" + template.id);
  } else {
    classNames.push("btn-choice-template--" + template);
  }

  isActive && classNames.push("active");
  className && classNames.push(className);

  const styles = {};

  if (previewUrl) {
    styles.backgroundImage = `url(${previewUrl})`;
  }

  return <button
    className={classNames.join(" ")}
    style={styles}
    onClick={() => onClick(template)}
    children={children}
  />;
}

TemplateItemView.propTypes = {
  template: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired
};