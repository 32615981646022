import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {
  photolabGenderTask,
  PhotolabResponseError, photolabSimpleTask,
  photolabTask
} from "../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../utils/webview";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../utils/log";
import Loader from "../components/Loader";
import ProcessingErrorView from "../components/ProcessingErrorView";
import ProcessingResultView from "../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../utils/hits";
import TemplateItemView2 from "../components/TemplateItemView2";

const processingTexts = [
  "AI is looking for a stunning<br />look for you 🔍",
  "Any idea what it’s gonna<br />look like? 🙈",
  "Just wait for it… 🪄",
  "AI’s fantasy is coming true 🧚",
];

const templates = [
  {id: 8296, canvasId: 8206, maskId: 8207},
  {id: 8297, canvasId: 8206, maskId: 8207},
  {id: 8298, canvasId: 8206, maskId: 8207},
  {id: 8301, canvasId: 8206, maskId: 8207},
  {id: 8302, canvasId: 8206, maskId: 8207},
  {id: 8303, canvasId: 8206, maskId: 8207},
  {id: 8314, canvasId: 8206, maskId: 8207},
  {id: 8332, canvasId: 8206, maskId: 8207},
  {id: 8333, canvasId: 8206, maskId: 8207},
  {id: 8335, canvasId: 8206, maskId: 8207},
  {id: 8340, canvasId: 8206, maskId: 8207},
  {id: 8342, canvasId: 8206, maskId: 8207},
  {id: 8361, canvasId: 8206, maskId: 8207},
  {id: 8364, canvasId: 8206, maskId: 8207},
  {id: 8373, canvasId: 8206, maskId: 8207},
  {id: 8378, canvasId: 8206, maskId: 8207},
  {id: 8289, canvasId: 8206, maskId: 8207},
  {id: 8382, canvasId: 8206, maskId: 8207},
  {id: 8384, canvasId: 8206, maskId: 8207},
  {id: 8385, canvasId: 8206, maskId: 8207},
  {id: 8400, canvasId: 8206, maskId: 8207},
  {id: 8407, canvasId: 8206, maskId: 8207},
  {id: 8408, canvasId: 8206, maskId: 8207},
  {id: 8412, canvasId: 8206, maskId: 8207},
  {id: 8414, canvasId: 8206, maskId: 8207},
  {id: 8418, canvasId: 8206, maskId: 8207},
  {id: 8426, canvasId: 8206, maskId: 8207},
  {id: 8427, canvasId: 8206, maskId: 8207},
  {id: 8429, canvasId: 8206, maskId: 8207},
  {id: 8436, canvasId: 8206, maskId: 8207},
  {id: 8439, canvasId: 8206, maskId: 8207},
  {id: 8440, canvasId: 8206, maskId: 8207},
  {id: 8446, canvasId: 8206, maskId: 8207},
  {id: 8447, canvasId: 8206, maskId: 8207},
  {id: 8448, canvasId: 8206, maskId: 8207},
  {id: 8449, canvasId: 8206, maskId: 8207},
  {id: 8453, canvasId: 8206, maskId: 8207},
  {id: 8454, canvasId: 8206, maskId: 8207},
  {id: 8455, canvasId: 8206, maskId: 8207},
  {id: 8507, canvasId: 8206, maskId: 8207},
  {id: 8510, canvasId: 8206, maskId: 8207},
  {id: 8516, canvasId: 8206, maskId: 8207},
  {id: 8518, canvasId: 8206, maskId: 8207},
  {id: 8519, canvasId: 8206, maskId: 8207},
  {id: 8522, canvasId: 8206, maskId: 8207},
  {id: 8534, canvasId: 8206, maskId: 8207},
  {id: 8535, canvasId: 8206, maskId: 8207},
  {id: 8536, canvasId: 8206, maskId: 8207},
  {id: 8537, canvasId: 8206, maskId: 8207},
  {id: 8538, canvasId: 8206, maskId: 8207},
  {id: 8538, canvasId: 8206, maskId: 8207},
  {id: 8539, canvasId: 8206, maskId: 8207},
  {id: 8540, canvasId: 8206, maskId: 8207},
  {id: 8541, canvasId: 8206, maskId: 8207},
  {id: 8542, canvasId: 8206, maskId: 8207},
  {id: 8543, canvasId: 8206, maskId: 8207},
  {id: 8544, canvasId: 8206, maskId: 8207},
  {id: 8545, canvasId: 8206, maskId: 8207},
  {id: 8546, canvasId: 8206, maskId: 8207},
  {id: 8547, canvasId: 8206, maskId: 8207},
];

export default class SdSingleSkinPage extends React.Component {

  state = {
    isLoading: true,
    processingId: null,
    processings: [],
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.files = getWebviewInputFilesFromURL();
    this.templates = templates.shuffle();

    let template = this.templates.first();

    const webdata = decodeWebData(window.location.href);
    if (webdata && webdata.template && webdata.template.id) {
      const templateByWebdata = this.templates.find((t) => t.id === webdata.template.id);
      if (templateByWebdata) {
        template = templateByWebdata;
      }
    }

    this.state.processings.push(this.createProcessing(template));
    this.state.processingId = this.state.processings.first().id;
  }

  componentDidMount() {
    this.startProcessing(this.state.processings.first());
  }

  getCurrentProcessing = () => {
    return this.state.processings.find((p) => p.id === this.state.processingId);
  };

  createProcessing = (template) => {
    return {
      id: Date.now(),
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template,
    };
  };

  startProcessing = (processing) => {
    if (window.clientConfig.isWebview) {
      this.context.setNativeAdProcessingTexts(processingTexts);
      webviewShowNativeAds();
    }

    processing.error = null;
    processing.isProcessed = false;
    processing.isFailed = false;
    processing.isProcessing = true;
    processing.startedAt = Date.now();

    Promise.resolve()
      .then(() => {
        const file = this.files.first();
        file.rotation = 0;

        return Promise.all([
          photolabGenderTask(file, 500, 500),
          photolabSimpleTask(7092, file, 2000, 500)
        ]);
      })
      .then(([genderTaskResult, taskResult]) => {
        this.gender = genderTaskResult.gender.value;

        const taskBuilder = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(processing.template.id, {
            canvas_template_name: processing.template.canvasId,
            mask_template_name: processing.template.maskId,
            gender: this.gender === "male" ? "man" : "woman",
          }))
          .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
          .setLanguage(window.clientConfig.lang || "en");

        return photolabTask(taskBuilder.build(), 1000, 500)
      })
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({
          processings: this.state.processings.slice(),
        });
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({
          processings: this.state.processings.slice(),
        });
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: this.gender,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({template: processing.template}),
    });
  };

  handleRetryClick = (processing) => {
    this.startProcessing(processing);
    this.setState({
      processings: this.state.processings.slice(),
    });
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      gender: this.gender,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleMoreStylesClick = () => {
    const usedTemplatesIds = this.state.processings.map((p) => p.template.id);
    const currentProcessing = this.getCurrentProcessing();

    let template = this.templates.find((t) => !usedTemplatesIds.includes(t.id));
    if (template === undefined) {
      let index = this.templates.findIndex((t) => t.id === currentProcessing.template.id);
      if (index + 1 >= this.templates.length) {
        index = 0;
      } else {
        index += 1;
      }

      template = this.templates[index];
    }

    let processing = this.state.processings.find((processing) => {
      return processing.template.id === template.id;
    });

    if (!processing) {
      processing = this.createProcessing(template);
    }

    this.setState({
      processingId: processing.id,
      processings: [...this.state.processings, processing],
    }, () => {
      this.startProcessing(processing);
      if (this.processingsContainerRef) {
        this.processingsContainerRef.scrollLeft = 999999;
      }
    });
  };

  handleProcessingSelect = (processing) => {
    this.setState({processingId: processing.id});
  };

  render() {
    const processing = this.getCurrentProcessing();
    const hasUnfinishedProcessing = this.state.processings.some((p) => p.isProcessing);

    return <section className="start-page valentine22">
      <div className="start-page-header" hidden>
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={!processing.isProcessing}
            message={processingTexts} />

          {processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={() => this.handleRetryClick(processing)}
          />}

          <button
            className="btn-done"
            hidden={!processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container templates-container_column pb-0">
        {this.state.processings.length === 1 && processing.isProcessed && <button
          className="btn-more"
          onClick={this.handleMoreStylesClick}>
          {i18n.t("another_go")}
          <SvgRefreshIcon />
        </button>}
        {this.state.processings.length > 1 && <div className="container" ref={(ref) => this.processingsContainerRef = ref}>
          {this.state.processings.map((processing) => <TemplateItemView2
            key={processing.id}
            itemId={processing.template.id}
            isActive={processing.id === this.state.processingId}
            isLoading={processing.isProcessing}
            previewUrl={processing.result && processing.result.resultUrl}
            onClick={() => this.handleProcessingSelect(processing)}
          />)}
          <button
            className="btn-more btn-more-square"
            disabled={hasUnfinishedProcessing}
            onClick={this.handleMoreStylesClick}>
            {i18n.t("another_go")}
            <SvgRefreshIcon />
          </button>
        </div>}
      </div>
    </section>;
  }
}

SdSingleSkinPage.contextType = AppContext;

function SvgRefreshIcon() {
  return <svg viewBox="0 0 19 18">
    <path d="M5 15.5a.5.5 0 0 0 .5-.5v-4.5a.5.5 0 0 0-1 0v4h-4a.5.5 0 0 0 0 1H5zM17.5 9a7.5 7.5 0 0 1-7.5 7.5v1A8.5 8.5 0 0 0 18.5 9h-1zM10 1.5A7.5 7.5 0 0 1 17.5 9h1A8.5 8.5 0 0 0 10 .5v1zm0-1C5.309.5 1.5 4.22 1.5 9h1c0-4.22 3.355-7.5 7.5-7.5v-1zM1.5 9c0 2.352.656 3.838 1.42 4.809.377.48.77.821 1.095 1.072.16.123.31.227.42.307.122.089.182.136.211.166l.708-.708a2.827 2.827 0 0 0-.334-.268c-.121-.088-.25-.179-.395-.29a5.086 5.086 0 0 1-.92-.897C3.095 12.412 2.5 11.148 2.5 9h-1z"></path>
  </svg>;
}
