export function reorderMaskTemplates(templates) {
  const groups = [];

  templates.forEach((template) => {
    const key = template.weight || 1;
    const group = groups.find((g) => g.key === key);
    if (group) {
      group.items.push(template);
    } else {
      groups.push({key, items: [template]});
    }
  });

  groups.sort((a, b) => a.key < b.key ? 1 : -1);

  return groups.flatMap((g) => g.items.shuffle());
}

export function isEmpty(str) {
  return (!str || /^\s*$/.test(str));
}

const DATA_NUMERIC_MAX_SIZE = 3058;
const DATA_ALPHANUM_MAX_SIZE = 1852;
const DATA_BYTES_MAX_SIZE = 1274;

export function transformData(str) {
  if (/^shc:\/\d+$/.test(str)) {
    return "SHC" + str.substring(3);
  }

  return str;
}

export function checkDataSize(str) {
  const size = Buffer.byteLength(str, "utf8");

  let maxSize = DATA_BYTES_MAX_SIZE;

  if (/^\d+$/.test(str)) {
    maxSize = DATA_NUMERIC_MAX_SIZE;
  }
  // eslint-disable-next-line no-useless-escape
  else if (/^[0-9A-Z $\%\*\+\-\.\/\:]+$/.test(str)) {
    maxSize = DATA_ALPHANUM_MAX_SIZE;
  }

  return size > 0 && size <= maxSize;
}
