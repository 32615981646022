import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {PhotolabResponseError, photolabTask} from "../../photolab/api";
import {
  getWebviewInputFilesFromURL, webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../utils/webview";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../utils/log";
import Loader from "../components/Loader";
import ProcessingErrorView from "../components/ProcessingErrorView";
import ProcessingResultView from "../components/ProcessingResultView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../utils/hits";
import {SvgMaskIcon} from "../icons/SvgMaskIcon";

export default class SimpleViewPage extends React.Component {

  state = {
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.files = getWebviewInputFilesFromURL();
    this.processing = this.getProcessingInitialState();
  }

  getProcessingInitialState = () => {
    return {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template: {
        id: this.props.templateId,
      },
    };
  };

  componentDidMount() {
    this.startProcessing();
  }

  startProcessing = () => {
    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(this.processing.template.id))
      .setLanguage(window.clientConfig.lang || "en");

    this.files.forEach((file) => {
      taskBuilder.addImage(new PhotolabTaskImageUrl(file));
    });

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        this.processing.finishedAt = Date.now();

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, this.processing, {
          template_id: this.processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        this.processing.isProcessed = true;
        this.processing.isProcessing = false;
        this.processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult();
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        this.processing.finishedAt = Date.now();

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, this.processing, {
          template_id: this.processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        this.processing.isProcessing = false;
        this.processing.isFailed = true;
        this.processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = () => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: this.processing.template.id,
      input: this.files.map((f) => f.url),
      output: [this.processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(this.processing.result.resultUrl),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = () => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: this.processing.template.id,
      input: this.files.map((f) => f.url),
      output: [this.processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleEditMask = () => {
    const params = {};

    this.files.forEach((file, index) => {
      if (file.fixed) {
        return;
      }

      params[`src[${index}]`] = encodeURIComponent(file.src || file.url);
      params[`mask[${index}]`] = encodeURIComponent(file.alt_body || file.alt_body_template);
    });

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    data.forEach((item) => {
      if (item["0"]) {
        this.files[0].alt_body = item["0"];
      } else if (item["1"]) {
        this.files[1].alt_body = item["1"];
      }
    });

    this.setState(this.getInitialState(), () => {
      this.startProcessing();
    });
  };

  render() {
    if (window.clientConfig.isWebview) {
      if (this.processing.isProcessing) {
        webviewShowNativeAds();
      } else {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }
    }

    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsHidden = !maskSupport || this.processing.isFailed;

    return <section className="start-page valentine22">
      <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("vector_tab_refresh__title")}} />
          <button
            className="btn-edit-mask"
            hidden={editMaskButtonIsHidden}
            onClick={() => this.handleEditMask()}>
            <SvgMaskIcon />
          </button>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={this.processing.isProcessed || this.processing.isFailed}
            message={i18n.t("loader_processing")} />

          {this.processing.isProcessed && <ProcessingResultView
            processing={this.processing}
            onImageLoaded={this.handleResultLoaded}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {this.processing.isFailed && <ProcessingErrorView
            processing={this.processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!this.processing.isProcessed}
            onClick={this.handleConfirmResult}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>
    </section>;
  }
}

SimpleViewPage.contextType = AppContext;
