module.exports = {
  "strings": {

    "can_swap_or_continue": "Sie können die Gesichter tauschen oder so weitermachen wie es jetzt ist.",
    "button_tap_to_swap": "Zum Tauschen antippen",

    "button_choice_gender_male": "männliche",
    "button_choice_gender_female": "weibliche",
    "button_choice_gender_text": "Stile",
    "btn_retry": "Erneut versuchen",

    "step_count_text": "{{step_idx}} von {{steps}}",
    "start_page_title": "Wähle deinen Lieblingsstil",
    "start_page_title_2": "Wähle deinen Lieblingsstil",
    "olympics22__start_page_title" : "Wem drückst du die Daumen?",

    "title__choose_body": "Körperstil wählen",
    "title__choose_crop": "Körperstil wählen",
    "title__choose_background": "Hintergrundstil wählen",
    "gfp936_title_choose_crop_backgroud": "Porträtformat und Hintergrund auswählen",

    "loader_processing": "Bearbeitung",

    "vector_tab_refresh__title": "Fast geschafft!",
    "vector_tab_refresh__subtitle": "Kopfstil wählen",

    "error_modal__default_message": "Etwas ist schief gelaufen...",
    "error_modal__network_message": "Bitte prüfen Sie Ihre Internetverbundung nach.",

    "footer_title": "Körperstil wählen",

    "more_styles": "Weitere Stile",
  },
};
