import React from "react";
import PropTypes from "prop-types";

export default function TemplateItemView2({
  itemId,
  isActive,
  isLoading,
  onClick,
  previewUrl,
  children,
  disabled,
  className
}) {
  const classNames = [];

  classNames.push("btn-choice-template");
  classNames.push("btn-choice-template--" + itemId);

  isActive && classNames.push("active");
  isLoading && classNames.push("loading");
  className && classNames.push(className);

  const styles = {};

  if (previewUrl) {
    styles.backgroundImage = `url(${previewUrl})`;
  }

  return <button
    className={classNames.join(" ")}
    style={styles}
    onClick={() => !disabled && onClick && onClick()}
    children={children}
  />;
}

TemplateItemView2.propTypes = {
  itemId: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};