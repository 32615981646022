import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {PhotolabResponseError, photolabTask} from "../../photolab/api";
import {
  getWebviewInputFilesFromURL, webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../utils/webview";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../utils/log";
import Loader from "../components/Loader";
import ProcessingErrorView from "../components/ProcessingErrorView";
import ProcessingResultView from "../components/ProcessingResultView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../utils/hits";
import {decodeWebData, encodeWebData} from "../../utils/photolab.utils";
import DumbImageView from "../components/DumbImageView";

const shapes = {
  skin: "skin",
  body: "body",
  head: "head",
  face: "face",
  silhouette: "silhouette",
};

const shapesToTemplateId = {
  [shapes.skin]: 8208,
  [shapes.body]: 8028,
  [shapes.head]: 8029,
  [shapes.face]: 8030,
  [shapes.silhouette]: 8060,
};

const testItems = [
  {seed: 0, text: "A {gender} oil painting portrait, king in a robe, a crown on his head, show the castle against the backdrop of nature in the background, high resolution"},
  {seed: 0, text: "A {gender} oil painting portrait white color,  flower white color, lily flower, vector graphics white color, coreldraw x3, lilly pattern, lilly pulitzer, flowers white color, floral, lillies, flora white color, biroki, louisiana, watercolor painting of a beautiful pink rose, oil painting style background white color, high resolution, nostalgic heart-warming, trending on artstation hq"},
  {seed: 0, text: "A {gender} portrait in a style of 14th century painting of a medieval, epic artwork, atmospheric, by Evgeny Botvinnik, detailed and masterful"},
  {seed: 7, text: "A {gender} portrait wearing a beautiful intricately detailed japanese kimono by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k, beautiful sakura on the background, no extra legs, no extra arms, no people on the background"},
  {seed: 5, text: "A {gender} oil painting portrait, holding red roses, show oil painted garden landscape on background, high resolution"},
  {seed: 27, text: "A {gender} as postmodern poster, paper background, neon, geometric, collage, lettering, high resolution"},
  {seed: 10, text: "A {gender} wearing kimono, old japanese engraving, ginkgo trees, japanese pagoda"},
  {seed: 19, text: "A {gender} as classic Pre Raphaelites painting, Dante Gabriel Rossetti art style, flowers, trees"},
  {seed: 5, text: "A {gender} in surrealistic mushroom forest at rainy night"},
  {seed: 13, text: "A {gender} as a cowboy in desert, western city, western atmosphere, impressionism art style"},
  {seed: 10, text: "A {gender} in a rainforest, exotic flowers, oil painting style with a large strokes, high resolution"},
  {seed: 1, text: "A {gender}  god of electricity, greek god, white hair, masculine, mature, handsome, upper body, muscular, hairy torso, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gaston bussiere and alphonse mucha"},
  {seed: 5, text: "A {gender} is on hike"},
  {seed: 1, text: "a {gender} in tailored suit, futurist style, intricate baroque detail, elegant, glowing lights, highly detailed, film photography, 35mm, smooth, sharp focus, kodak portra 400, art by wlop, mars ravelo and greg rutkowski"},
  {seed: 2, text: "a {gender} digital painting, automaton, all metal, glowing magical eye, intricate details, perched on workshop bench, cinematic lighting, by greg rutkowski, in the style of midjourney, ethereal Bohemian Waxwing, Bombycilla garrulus , intricate details, ornate, detailed illustration,  Johanna Rupprecht style, William Morris style, trending on artstation"},
  {seed: 2, text: "A {gender} by eddie mendoza and russ mills and mort kunstler and milo manara, no face, no faces, geometry, natural lights, lord of the rings, fantasy, epic, golden light, shine, glare"},
  {seed: 18, text: "A {gender} oil painting portrait,  flower, lily flower, vector graphics, coreldraw x3, lilly pattern, lilly pulitzer, flowers, floral, lillies, flora, biroki, louisiana, watercolor painting of a beautiful pink rose, oil painting style background, high resolution, nostalgic heart-warming, trending on artstation hq"},
  {seed: 45, text: "A {gender} oil painting portrait, flower, ismail inceoglu epic painting of lovers book cover landscape, year 1990, line art, ink, art concept for a book cover!!, warm colours, red and white colors, astonishing sky, oil painting, think brushstrokes, highly detailed, centered, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker oil painting style background, high resolution, nostalgic heart-warming, trending on artstation hq"},
  {seed: 3, text: "A {gender} is wearing sweater, show fireplace and Christmas tree on the background"},
  {seed: 9, text: "A {gender} at wheat field at night, gouache art style, night sky full of stars with cresent moon and clouds"},
];

export default class ShapeWithTextPage extends React.Component {

  state = {
    updatedAt: 0,
    userText: "A {gender} oil painting portrait, Paris on background, painting heart style background, high resolution",
    seed: 25,
    prevShape: shapes.skin,
    shape: shapes.skin,
    processingId: 0,
    isEdited: true,
  };

  placeholder = "{gender}";

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = this.loadedUrl.searchParams.get("no_ui") === "1";
    this.files = getWebviewInputFilesFromURL();
    this.cacheKey = "pwt:" + this.props.pageName;

    if (window.localStorage[this.cacheKey]) {
       try {
         const restoredData = JSON.parse(window.localStorage[this.cacheKey]);
         if (restoredData && restoredData.time && Math.abs(restoredData.time - Date.now()) < 60_000) { // 1 min
           this.state.userText = restoredData.userText || this.state.userText;
           this.state.seed = restoredData.seed || this.state.seed;
           this.state.shape = restoredData.shape || this.state.shape;
         }
       } catch (e) {
         console.error("Failed to parse cache", e);
       }
    } else {
      const webdata = decodeWebData(window.location.href);
      if (webdata) {
        this.state.userText = webdata.userText || this.state.userText;
        this.state.seed = webdata.seed || this.state.seed;
        this.state.shape = webdata.shape || this.state.shape;
      }
    }

    this.state.prevShape = this.state.shape;

    this.processings = [];
  }

  componentDidMount() {
    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }
    }, 1000);

    if (this.noUI) {
      const processingId = Date.now();
      this.startProcessing(processingId);
      this.setState({processingId});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.userText !== prevState.userText || this.state.shape !== prevState.shape || this.state.seed !== prevState.seed) {
      this.setState({isEdited: true});
    }
  }

  getCropTask = (file) => {
    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(8205))
      .addImage(file)
      .setLanguage(window.clientConfig.lang || "en");

    return photolabTask(taskBuilder.build(), 1000, 500);
  };

  getTask = (templateId, file) => {
    if (templateId === shapesToTemplateId[shapes.silhouette]) {
      return this.getCropTask(file)
        .then((taskResult) => {
          const taskBuilder = new PhotolabTaskBuilder()
            .addMethod(new PhotolabTaskCollageMethod(templateId, {
              seeds: this.state.seed,
              prompt: this.state.userText,
            }))
            .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
            .setLanguage(window.clientConfig.lang || "en");

          return photolabTask(taskBuilder.build(), 1000, 500);
        });
    }

    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(templateId, {
        seeds: this.state.seed,
        prompt: this.state.userText,
      }))
      .addImage(new PhotolabTaskImageUrl(file))
      .setLanguage(window.clientConfig.lang || "en");

    return photolabTask(taskBuilder.build(), 1000, 500);
  };

  startProcessing = (processingId) => {
    const processing = {
      id: processingId,
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template: {
        id: shapesToTemplateId[this.state.shape],
      },
    };

    this.processings.push(processing);

    delete this.files[0].alt_body_template;

    if (this.state.prevShape !== this.state.shape) {
      delete this.files[0].alt_body;
    }

    this.getTask(processing.template.id, this.files[0])
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (taskResult.masks) {
          taskResult.masks.forEach((mask) => {
            this.files[mask.index - 1].alt_body_template = mask.url;
          });
        }

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({
          prevShape: this.state.shape,
          updatedAt: Date.now(),
        });
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    const resultData = {
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({
        userText: this.state.userText,
        seed: this.state.seed,
        shape: this.state.shape,
      }),
    };

    this.files.forEach((file, index) => {
      const appIndex = index + 1;

      if (file.alt_body_template) {
        resultData[`body_${appIndex}`] = encodeURIComponent(file.alt_body_template);
      }

      if (file.alt_body) {
        resultData[`alt_body_${appIndex}`] = encodeURIComponent(file.alt_body);
      }
    });

    webviewSendResult(resultData);
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleEditMask = () => {
    const params = {};

    this.files.forEach((file, index) => {
      if (file.fixed) {
        return;
      }

      params[`src[${index}]`] = encodeURIComponent(file.src || file.url);
      params[`mask[${index}]`] = encodeURIComponent(file.alt_body || file.alt_body_template);
    });

    window.localStorage[this.cacheKey] = JSON.stringify({
      time: Date.now(),
      userText: this.state.userText,
      seed: this.state.seed,
      shape: this.state.shape,
    });

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    data.forEach((item) => {
      if (item["0"]) {
        this.files[0].alt_body = item["0"];
      } else if (item["1"]) {
        this.files[1].alt_body = item["1"];
      }
    });

    const processingId = Date.now();
    this.startProcessing(processingId);

    this.setState({processingId});
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processingId = Date.now();
    this.startProcessing(processingId);

    this.setState({
      processingId,
      isEdited: false,
    });
  };

  handleUserTextChange = (e) => {
    const newValue = e.target.value;
    const oldValue = this.state.userText;

    if (newValue.isEmpty()) {
      this.setState({userText: this.placeholder});
    } else if (newValue.indexOf(this.placeholder) < 0) {
      this.setState({userText: oldValue});
    } else {
      this.setState({userText: newValue});
    }
  };

  handleUserTextSelect = (e) => {
    const placeholderStart = e.target.value.indexOf(this.placeholder);
    const placeholderEnd = placeholderStart + this.placeholder.length;

    if (e.target.selectionStart === e.target.selectionEnd) {
      if (e.target.selectionStart > placeholderStart && e.target.selectionStart < placeholderEnd) {
        if (this.prevSelectionStart < e.target.selectionStart) {
          e.target.setSelectionRange(placeholderEnd, placeholderEnd);
        } else {
          e.target.setSelectionRange(placeholderStart, placeholderStart);
        }
      }
    } else {
      // выделение внутри блока
      if (e.target.selectionStart > placeholderStart && e.target.selectionEnd < placeholderEnd) {
        e.target.setSelectionRange(placeholderStart, placeholderStart);
      }
      // начало выделение внутри блока
      else if (e.target.selectionStart > placeholderStart && e.target.selectionStart < placeholderEnd) {
        e.target.setSelectionRange(placeholderEnd, e.target.selectionEnd);
      }
      // конец выделения внутри блока
      else if (e.target.selectionEnd > placeholderStart && e.target.selectionEnd < placeholderEnd) {
        e.target.setSelectionRange(e.target.selectionStart, placeholderStart);
      }
      // блок внутри выделения
      else if (placeholderStart >= e.target.selectionStart && placeholderEnd <= e.target.selectionEnd) {
        if (placeholderStart - e.target.selectionStart > e.target.selectionEnd - placeholderEnd) {
          e.target.setSelectionRange(e.target.selectionStart, placeholderStart);
        } else {
          e.target.setSelectionRange(placeholderEnd, e.target.selectionEnd);
        }
      }
    }

    this.prevSelectionStart = e.target.selectionStart;
  };

  handleTestItemsChange = (e) => {
    const item = testItems[e.target.selectedIndex];
    this.setState({
      seed: item.seed,
      userText: item.text,
    })
  };

  render() {
    const processing = this.processings.find((p) => p.id === this.state.processingId);
    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsVisible = maskSupport
      && processing
      && processing.isProcessed
      && (this.files[0].alt_body || this.files[0].alt_body_template);

    const isDisabled = processing && processing.isProcessing;

    return <section className="start-page shape-with-text-template">
      {/* <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("vector_tab_refresh__title")}} />
          <button
            className="btn-edit-mask"
            hidden={!editMaskButtonIsVisible}
            onClick={() => this.handleEditMask()}>
            <SvgMaskIcon />
          </button>
        </div>
      </div> */}

      <div className="collage-container collage-container-content">
        <div className="container">
          <div className="form-container">
            <div className="wrapper">
              <select onChange={this.handleTestItemsChange}>
                {testItems.map((item, index) => <option key={item.text}>#{index} {item.text}</option>)}
              </select>
              <br />
              <br />

              <form onSubmit={this.handleFormSubmit} id="form">
                <textarea
                  className="text_field"
                  value={this.state.userText}
                  disabled={isDisabled}
                  onChange={this.handleUserTextChange}
                  onSelect={this.handleUserTextSelect}
                  placeholder={i18n.t("user text")}
                />

                <div className="form_group form_group-seed">
                  <h3 dangerouslySetInnerHTML={{__html: i18n.t("seed")}} />
                  <input
                    type="number"
                    value={this.state.seed}
                    min={1}
                    max={999999999}
                    disabled={isDisabled}
                    onChange={(e) => this.setState({seed: e.target.value})}
                  />
                </div>

                <div className="form_group">
                  {/*<h3 dangerouslySetInnerHTML={{__html: i18n.t("selected_mask")}} />*/}
                  <div className="form_group-container">
                    {Object.values(shapes).map((shape) => <label className="form_radio_control" key={shape}>
                      <input
                        type="radio"
                        name="shape"
                        value={shape}
                        checked={this.state.shape === shape}
                        disabled={isDisabled}
                        onChange={(e) => e.target.checked && this.setState({shape})}
                      />
                      {" "}
                      <span>{shape}</span>
                    </label>)}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={!processing || processing.isProcessed || processing.isFailed}
            message={i18n.t("loader_processing")} />

          {!processing && <DumbImageView
            className={`collage collage-result--source`}
            image={this.files[0].url}
          />}

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-mask"
            hidden={!editMaskButtonIsVisible}
            onClick={() => this.handleEditMask()}
            children={<SvgMaskIcon />}
          />

          <button
            className="btn-check"
            type="submit"
            form="form"
            hidden={!this.state.isEdited}
            disabled={isDisabled}
            children={<SvgCheckMarkIcon />}
          />

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed || this.state.isEdited}
            onClick={() => this.handleConfirmResult(processing)}
            children={<SvgSprite viewBox="0 0 24 24" icon="icon-done" />}
          />
        </div>
      </div>
    </section>;
  };
}

ShapeWithTextPage.contextType = AppContext;

function SvgCheckMarkIcon() {
  return <svg viewBox="0 0 48 48">
    <defs>
      <clipPath id="fwxm9w04na">
        <path d="M1242 0v2208H0V0h1242z"/>
      </clipPath>
      <clipPath id="odk882bicb">
        <path d="m24 0 4.2 4.2L11.4 21H48v6H11.4l16.8 16.8L24 48 0 24 24 0z"/>
      </clipPath>
    </defs>
    <g clipPath="url(#fwxm9w04na)" transform="translate(-1026 -1992)">
      <g clipPath="url(#odk882bicb)" transform="matrix(-1 0 0 1 1074 1992)">
        <path fill="#FFF" d="M0 0h48v48H0V0z"/>
      </g>
    </g>
  </svg>;
}

function SvgMaskIcon() {
  return <svg width="62" height="62" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <clipPath id="taewlkeqma">
            <path d="M1242 0v2208H0V0h1242z"/>
        </clipPath>
        <clipPath id="9xaveuwm6b">
            <path d="M13.385 60v2h-12v-2h12zm18 0v2h-12v-2h12zm18 0v2h-12v-2h12zM62 54.615V62h-6.615v-2H60L60 54.615h2zM2.868 43.356l1.404 1.425C2.746 46.285 2.002 47.883 2 49.672v5.713H0v-5.714c.003-2.355.979-4.453 2.868-6.315zm50.3-3.932c5.267 2.526 8.2 5.422 8.743 9.046l-1.978.296c-.424-2.825-2.9-5.27-7.63-7.538zm-34.342-4.303 1.397 1.43c-.83.813-2.243 1.462-5.502 2.622-2.238.796-4.132 1.589-5.743 2.408l-.906-1.783c1.694-.861 3.665-1.686 5.979-2.51 2.955-1.051 4.235-1.64 4.775-2.167zM41.849 33l.178.124.095.066.272.188.134.093.14.097.281.195.067.046.229.159.247.172c-.131.19-.264.37-.406.554l-.118.146c.322.596 1.338 1.129 4.291 2.203l.302.109-.678 1.882c-4.524-1.63-5.745-2.425-6.012-4.276l-.065-.446.292-.343c.297-.352.528-.648.75-.969zm4.64-10.289 1.994.154-.013.164c-.248 3.22-1.192 5.548-3.008 7.14l-.168.14-.033.114c-.39 1.27-.9 2.384-1.548 3.387l-.221.33-.247-.172-.229-.159-.067-.046-.281-.195-.14-.097-.134-.093-.272-.188-.095-.066-.178-.124c.628-.906 1.118-1.93 1.491-3.135l.12-.408.09-.325.27-.203c1.588-1.204 2.429-3.11 2.656-6.054l.013-.164zm-32.382-3.55 1.872.706a7.625 7.625 0 0 0-.467 2.688l-.003.126.015.192c.226 2.944 1.066 4.85 2.655 6.055l.269.203.09.325c.079.283.164.556.255.82l-1.892.65a16.44 16.44 0 0 1-.29-.933l.091.315-.166-.141c-1.746-1.53-2.685-3.743-2.975-6.773l-.032-.368-.017-.268v-.205a9.622 9.622 0 0 1 .595-3.391zM44.232 5.1c.26.279.51.567.75.865 2.328 2.902 3.499 6.691 3.18 10.022a39.28 39.28 0 0 1-.113 1.02l-1.986-.244c.043-.343.078-.665.108-.968.269-2.804-.742-6.077-2.75-8.578a12.829 12.829 0 0 0-.648-.748zM20.559 2.778l1.083 1.681a12.792 12.792 0 0 0-3.02 2.703c-1.54 1.918-2.501 4.227-2.764 6.504l-1.987-.23c.306-2.65 1.414-5.314 3.194-7.53a14.8 14.8 0 0 1 3.494-3.128zM30.999 0c2.927 0 5.586.491 7.911 1.456l-.766 1.847C36.071 2.443 33.672 2 31 2c-1.46 0-2.839.132-4.129.393l-.396-1.96A22.834 22.834 0 0 1 31 0z"/>
        </clipPath>
        <clipPath id="wiyci7a12c">
            <path d="M25 0c9.635 0 11.441 6.784 11.19 9.41-.204 2.107-.724 4.845-.724 4.845s1.022.46 1.022 2.31c-.358 4.644-2.23 2.644-2.617 4.677-.932 4.902-3.05 4.028-3.05 6.706 0 4.458 2.219 6.541 9.16 9.01C46.947 39.435 50 41.962 50 43.683V50H0v-6.317c0-1.725 3.054-4.248 10.018-6.726 6.942-2.468 9.16-4.55 9.16-9.01 0-2.678-2.119-1.803-3.05-6.706-.387-2.033-2.259-.033-2.616-4.676 0-1.85 1.02-2.31 1.02-2.31s-.519-2.738-.722-4.846C13.556 6.782 15.365 0 25 0z"/>
        </clipPath>
    </defs>
    <g clipPath="url(#taewlkeqma)" transform="translate(-1019 -1105)">
        <g clipPath="url(#9xaveuwm6b)" transform="translate(1019 1105)">
            <path fill="#FFF" d="M0 0h62v62H0V0z"/>
        </g>
        <g clipPath="url(#wiyci7a12c)" transform="translate(1025 1111)">
            <path fill="#FFF" d="M0 0h50v50H0V0z"/>
            <path d="M39.982 36.958c-6.942-2.469-9.161-4.552-9.161-9.01 0-2.678 2.118-1.804 3.05-6.706.386-2.033 2.259-.033 2.617-4.676 0-1.85-1.022-2.31-1.022-2.31s.52-2.739.723-4.846C36.441 6.784 34.635 0 25 0 15.366 0 13.557 6.782 13.81 9.41c.203 2.107.723 4.845.723 4.845s-1.021.46-1.021 2.31c.357 4.643 2.23 2.643 2.615 4.676.932 4.903 3.05 4.028 3.05 6.706 0 4.46-2.217 6.542-9.16 9.01C3.055 39.435 0 41.958 0 43.683V50h50v-6.317c0-1.721-3.054-4.248-10.018-6.725z" stroke="#FFF" strokeWidth="12" fill="none" strokeMiterlimit="5"/>
        </g>
    </g>
  </svg>;
}
