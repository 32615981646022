import i18n from "../../i18n";
import {PhotolabResponseError} from "../../photolab/api";
import React from "react";
import PropTypes from "prop-types";

export default function ProcessingErrorView({processing, onRetryClick}) {
  let errorMessage = i18n.t("error_modal__default_message");
  if (processing.error instanceof PhotolabResponseError) {
    errorMessage = processing.error.message;
  } else if (processing.error.name === "NetworkError" || processing.error.message === "Network Error") {
    errorMessage = i18n.t("error_modal__network_message");
  }

  return <div className="error-container" hidden={!processing || !processing.isFailed}>
    <div>
      <p dangerouslySetInnerHTML={{__html: errorMessage}} />
      <button dangerouslySetInnerHTML={{__html: i18n.t("btn_retry")}} onClick={() => onRetryClick(processing)} />
    </div>
  </div>;
}

ProcessingErrorView.propTypes = {
  processing: PropTypes.object.isRequired,
  onRetryClick: PropTypes.func.isRequired,
}