import React from "react";
import {Route} from "react-router";
import QRToonPage from "./QRToonPage";

const routes = [{
  key: "qrtoonRoute",
  path: "/qrtoon",
  render: (props) => <QRToonPage {...props} pageName="qrtoon" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}