import axios from "axios";
import axiosRetry from "axios-retry";

const httpClient = axios.create({
  baseURL: window.appConfig.qrcode.path,
  headers: {
    "X-Project-Key": window.appConfig.project.key,
    "X-Client-Token": window.clientConfig.token,
  }
});

axiosRetry(httpClient, {
  retries: 3,
  retryDelay: 1000,
});

class ApiResponseError extends Error {
  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

export function createTask(type, params) {
  return httpClient.post("/tasks/create", {type, params})
    .then(checkApiResponse);
}

export function fetchTask(taskId) {
  return httpClient.get("/tasks/" + taskId)
    .then(checkApiResponse);
}

export function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  requestsAmount++;

  function _call(resolve, reject) {
    fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        task.getResultRequestsAmount = requestsAmount;
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      error.getResultRequestsAmount = requestsAmount;
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}
