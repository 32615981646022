import React from "react";
import {Route} from "react-router";
import Gfp936v2Page from "./Gfp936v2Page";
import Gfp936v2CropPage from "./Gfp936v2CropPage";
import Gfp936v2Crop2in1Page from "./Gfp936v2Crop2in1Page";

const routes = [{
  key: "Gfp936v2Route",
  path: "/gfp936v2",
  render: (props) => <Gfp936v2Page {...props} pageName="gfp936v2" />,
}, {
  key: "Gfp936v2CropRoute",
  path: "/gfp936v2crop",
  render: (props) => <Gfp936v2CropPage {...props} pageName="gfp936v2crop" />,
}, {
  key: "Gfp936v2Crop2in1Route",
  path: "/gfp936v2crop2in1",
  render: (props) => <Gfp936v2Crop2in1Page {...props} pageName="gfp936v2crop2in1" />,
}];

export default {
  routes: routes.map((routeData) => <Route {...routeData} />),
}