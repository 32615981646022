import React from "react";
import AppContext from "../../contexts/AppContext";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {
  PhotolabResponseError,
  photolabTask
} from "../../photolab/api";
import {
  getWebviewInputFilesFromURL, webviewCallWithCallback,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../utils/webview";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../utils/log";
import Loader from "../components/Loader";
import ProcessingErrorView from "../components/ProcessingErrorView";
import ProcessingResultView from "../components/ProcessingResultView";
import {decodeWebData, encodeWebData} from "../../utils/photolab.utils";
import {hitPhotoFailed, hitPhotoProcessed} from "../../utils/hits";
import TemplateItemView from "../components/TemplateItemView";
import {pwAssetUrl} from "../../utils/etc";
import {SvgMaskIcon} from "../icons/SvgMaskIcon";

export default class SimpleListPage extends React.Component {

  state = {
    template: null,
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.noUI = !!parseInt(this.loadedUrl.searchParams.get("no_ui"));
    this.files = getWebviewInputFilesFromURL();
    this.processings = [];
    this.templates = this.props.templates.map((id) => {
      return {id};
    });

    if (this.props.shuffleTemplates === "all") {
      this.templates = this.templates.shuffle();
    } else if (this.props.shuffleTemplates === "first") {
      const templateIndex = this.templates.randomIndex();
      this.templates.unshift(...this.templates.splice(templateIndex, 1));
    }

    this.state.template = this.templates[0];

    const webdata = decodeWebData(window.location.href);
    if (webdata && webdata.template && webdata.template.id) {
      // eslint-disable-next-line eqeqeq
      const template = this.templates.find((t) => t.id == webdata.template.id);
      if (template) {
        this.state.template = template;
      }
    }
  }

  componentDidMount() {
    if (this.templatesContainerRef) {
      const activeElement = this.templatesContainerRef.querySelector(".active");

      if (activeElement) {
        activeElement.scrollIntoView({inline: "start"});
      }
    }

    this.startProcessing();
  }

  startProcessing = () => {
    if (window.clientConfig.isWebview) {
      webviewShowNativeAds();
    }

    const processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template: this.state.template,
    };

    this.processings.push(processing);

    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(this.state.template.id))
      .setLanguage(window.clientConfig.lang || "en");

    this.files.forEach((file) => {
      taskBuilder.addImage(new PhotolabTaskImageUrl(file));
    });

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (taskResult.masks) {
          taskResult.masks.forEach((mask, index) => {
            this.files[index].alt_body_template = mask.url;
          });
        }

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        if (window.clientConfig.isWebview) {
          webviewHideNativeAds();
          this.context.hideNativeAd();
        }

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
      web_data: encodeWebData({template: this.state.template}),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleTemplateSelect = (template) => {
    logEvent(userEvents.ITEM_SELECT, {
      page_name: this.props.pageName,
      template_id: template.id,
    });

    const nextState = {
      template,
      updatedAt: Date.now(),
    };

    const processing = this.processings.find((processing) => {
      return processing.template.id === template.id;
    });

    this.setState(nextState, () => {
      if (!processing) {
        this.startProcessing();
      }
    });
  }

  handleEditMask = () => {
    const params = {};

    this.files.forEach((file, index) => {
      if (file.fixed) {
        return;
      }

      params[`src[${index}]`] = encodeURIComponent(file.src || file.url);
      params[`mask[${index}]`] = encodeURIComponent(file.alt_body || file.alt_body_template);
    });

    webviewCallWithCallback("editMask", params, this.handleWebviewUpdateMask);
  };

  handleWebviewUpdateMask = (data) => {
    if (!data || data.length === 0) {
      return;
    }

    data.forEach((item) => {
      if (item["0"]) {
        this.files[0].alt_body = item["0"];
      } else if (item["1"]) {
        this.files[1].alt_body = item["1"];
      }
    });

    this.setState({
      updatedAt: Date.now()
    }, () => {
      this.processings = [];
      this.startProcessing();
    });
  };

  render() {
    const processing = this.processings.find((p) => {
      return p.template.id === this.state.template.id;
    });

    const maskSupport = !!parseInt(this.loadedUrl.searchParams.get("mask_support"));
    const editMaskButtonIsHidden = !maskSupport || (processing && processing.isFailed);

    return <section className={`start-page ${this.props.pageName}`}>
      <div className="start-page-header">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{__html: i18n.t("start_page_title")}} />
        </div>
        <button
          className="btn-edit-mask"
          hidden={editMaskButtonIsHidden}
          onClick={() => this.handleEditMask()}>
          <SvgMaskIcon />
        </button>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            templateId={processing.template.id}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container">
        <div className="container" ref={(ref) => this.templatesContainerRef = ref}>
          {this.templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            className="btn-choice-template--bg-center"
            isActive={this.state.template.id === template.id}
            previewUrl={pwAssetUrl(`templates-previews/${template.id}.jpg`)}
            onClick={(template) => this.handleTemplateSelect(template)}
          />)}
        </div>
      </div>
    </section>;
  }
}

SimpleListPage.contextType = AppContext;
