import React from "react";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import {PhotolabResponseError, photolabTask} from "../../../photolab/api";
import {
  getWebviewInputFilesFromURL,
  webviewHideNativeAds, webviewSendResult,
  webviewShowNativeAds
} from "../../../utils/webview";
import i18n from "../../../i18n";
import {SvgSprite} from "../../components/SvgSprite";
import {logCreativeFailed, logCreativeProcessed, logEvent, userEvents} from "../../../utils/log";
import Loader from "../../components/Loader";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import ProcessingResultView from "../../components/ProcessingResultView";
import {hitPhotoFailed, hitPhotoProcessed} from "../../../utils/hits";
import TemplateItemView from "../../components/TemplateItemView";
import {pwAssetUrl} from "../../../utils/etc";

const templates = {
  "first": [
    {id: 7832},
    {id: 7833},
    {id: 7834},
    {id: 7835},
    {id: 7836},
    {id: 7837},
    {id: 7838},
    {id: 7841},
    {id: 7842},
    {id: 7843},
    {id: 7847},
    {id: 7849},
    {id: 7850},
    {id: 7852},
    {id: 7856},
    {id: 7857},
    {id: 7860},
    {id: 7863},
    {id: 7864},
    {id: 7867},
    {id: 7870},
    {id: 7871},
    {id: 7872},
    {id: 7874},
    {id: 7875},
    {id: 7876},
    {id: 7877},
    {id: 7878},
    {id: 7880},
    {id: 7881},
    {id: 7882},
  ],
  "second": [
    {id: 7917},
    {id: 7919},
    {id: 7921},
    {id: 7953},
    {id: 7925},
    {id: 7927},
    {id: 7929},
    {id: 7933},
    {id: 7931},
    {id: 7935},
    {id: 7938},
    {id: 7940},
    {id: 7942},
    {id: 7944},
    {id: 7947},
    {id: 7949},
    {id: 7951},
  ],
  "third": [
    {id: 7918},
    {id: 7920},
    {id: 7922},
    {id: 7924},
    {id: 7926},
    {id: 7928},
    {id: 7930},
    {id: 7934},
    {id: 7932},
    {id: 7936},
    {id: 7939},
    {id: 7941},
    {id: 7943},
    {id: 7945},
    {id: 7948},
    {id: 7950},
    {id: 7952},
  ],
  "fourth": [
    {id: 7946},
    {id: 7955},
    {id: 7956},
    {id: 7957},
    {id: 7958},
    {id: 7959},
    {id: 7960},
    {id: 7961},
    {id: 7962},
    {id: 7963},
    {id: 7964},
    {id: 7965},
    {id: 7967},
    {id: 7968},
    {id: 7969},
    {id: 7970},
    {id: 7971},
  ],
};

export default class Valentine5Page extends React.Component {

  state = {
    group: "",
    template: {},
    updatedAt: 0,
  };

  constructor(props) {
    super(props);

    this.loadedUrl = new URL(window.location.href);
    this.files = getWebviewInputFilesFromURL();
    this.processings = [];
    this.templates = templates;
    this.groups = Object.keys(this.templates);

    this.state.group = this.groups.first();
    this.groups.forEach((group) => {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.template[group] = this.templates[group].random();
    });
  }

  componentDidMount() {
    this.setState({
      updatedAt: Date.now(), // start processing
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Object.values(this.state.template).forEach((template) => {
      const processing = this.processings.find((p) => p.template.id === template.id);
      if (!processing) {
        this.startProcessing(template);
      }
    });
  }

  startProcessing = (template) => {
    const processing = {
      result: null,
      error: null,
      isProcessed: false,
      isFailed: false,
      isProcessing: true,
      startedAt: Date.now(),
      template,
    };

    this.processings.push(processing);

    const taskBuilder = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
      .setLanguage(window.clientConfig.lang || "en");

    this.files.forEach((file) => {
      taskBuilder.addImage(new PhotolabTaskImageUrl(file));
    });

    photolabTask(taskBuilder.build(), 1000, 500)
      .then((taskResult) => {
        processing.finishedAt = Date.now();

        hitPhotoProcessed();
        logCreativeProcessed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
          output: [taskResult.resultUrl],
        });

        processing.isProcessed = true;
        processing.isProcessing = false;
        processing.result = taskResult;

        if (this.noUI) {
          this.handleConfirmResult(processing);
        }

        this.setState({updatedAt: Date.now()});
      })
      .catch((err) => {
        processing.finishedAt = Date.now();

        hitPhotoFailed();
        logCreativeFailed(this.props.pageName, processing, {
          template_id: processing.template.id,
          input: this.files.map((f) => f.url),
        });

        if (err instanceof PhotolabResponseError) {
          webviewSendResult({status: "Error", description: err.message});
          return;
        }

        const logError = {
          page_name: this.props.pageName,
          name: err.name,
          code: err.code || undefined,
          message: err.message || undefined,
        };

        logEvent(userEvents.ITEM_ERROR, logError);

        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;

        this.setState({updatedAt: Date.now()});
      });
  };

  handleConfirmResult = (processing) => {
    logEvent(userEvents.ITEM_CONFIRM, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });

    webviewSendResult({
      status: "OK",
      result_url: encodeURIComponent(processing.result.resultUrl),
    });
  };

  handleRetryClick = () => {
    this.startProcessing();
  };

  handleResultLoaded = (processing) => {
    logEvent(userEvents.ITEM_LOADED, {
      page_name: this.props.pageName,
      template_id: processing.template.id,
      input: this.files.map((f) => f.url),
      output: [processing.result.resultUrl],
    });
  };

  handleImageLoadFailed = (imageUrl) => {
    // todo send error to native app?
  };

  handleGroupSelect = (group) => {
    this.setState({group});
  };

  handleMoreStylesClick = () => {
    const usedTemplatesIds = this.processings.map((p) => p.template.id);
    const currentTemplateId = this.state.template[this.state.group];
    const groupTemplates = this.templates[this.state.group];

    let template = groupTemplates.find((t) => !usedTemplatesIds.includes(t.id));
    if (template === undefined) {
      template = groupTemplates.shuffle().find((t) => t.id !== currentTemplateId);
    }

    this.setState({
      template: {
        ...this.state.template,
        [this.state.group]: template,
      }
    })
  };

  render() {
    const template = this.state.template[this.state.group];
    const processing = this.processings.find((p) => p.template.id === template.id);

    if (window.clientConfig.isWebview) {
      if (!processing || processing.isProcessing) {
        webviewShowNativeAds();
      } else {
        webviewHideNativeAds();
        this.context.hideNativeAd();
      }
    }

    return <section className="start-page valentine22">
      <div className="start-page-header" hidden>
        <div className="container">
          {/*<h2 dangerouslySetInnerHTML={{__html: i18n.t("can_swap_or_continue")}} />*/}
          <h2>&nbsp;</h2>
        </div>
      </div>

      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={i18n.t("loader_processing")} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onImageLoadError={this.handleImageLoadFailed}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onRetryClick={this.handleRetryClick}
          />}

          <button
            className="btn-done"
            hidden={!processing || !processing.isProcessed}
            onClick={() => this.handleConfirmResult(processing)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-done" />
          </button>
        </div>
      </div>

      <div className="templates-container templates-container_column">
        <p className="templates-container-text">Просмотрите все варианты</p>
        <div className="container">
          {this.groups.map((group) => <TemplateItemView
            key={group}
            template={group}
            className="btn-choice-template--bg-center"
            isActive={this.state.group === group}
            previewUrl={pwAssetUrl(`templates-previews/${templates[group][0].id}.jpg`)}
            onClick={(group) => this.handleGroupSelect(group)}
          />)}
          <button className="btn-more btn-more_choice" onClick={this.handleMoreStylesClick}>
            {i18n.t("another_go")}
          </button>
        </div>
      </div>
    </section>;
  }
}

Valentine5Page.contextType = AppContext;
