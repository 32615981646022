module.exports = {
  "strings": {

    "can_swap_or_continue": "आप चेहरों की अदला-बदली कर सकते हैं या यथावत जारी रख सकते हैं",
    "button_tap_to_swap": "अदला-बदली के लिए टैप करें",

    "button_choice_gender_male": "पुरुष",
    "button_choice_gender_female": "महिला",
    "button_choice_gender_text": "शैलियाँ",
    "btn_retry": "पुन: प्रयास करें",

    "step_count_text": "{{step_idx}} में से {{steps}} कदम",
    "start_page_title": "वह शैली चुनें जिसे आप सबसे अधिक पसंद करते हैं",
    "start_page_title_2": "वह शैली चुनें जिसे आप सबसे अधिक पसंद करते हैं",
    "olympics22__start_page_title" : "आप किसका समर्थन कर रहे हैं?",

    "title__choose_body": "बॉडी स्टाइल चुनें",
    "title__choose_crop": "बॉडी स्टाइल चुनें",
    "title__choose_background": "बैकग्राउंड स्टाइल चुनें",
    "gfp936_title_choose_crop_backgroud": "पोर्ट्रेट क्रॉप और बैकग्राउंड चुनें",

    "loader_processing": "प्रोसेस हो रहा है",

    "vector_tab_refresh__title": "बस पहुंच ही गए!",
    "vector_tab_refresh__subtitle": "हेड स्टाइल चुनें",

    "error_modal__default_message": "कुछ गड़बड़ हो गई।",
    "error_modal__network_message": "कृपया इंटरनेट कनेक्शन की जाँच करें।",

    "footer_title": "बॉडी स्टाइल चुनें",

    "more_styles": "और स्टाइल",
  },
};
