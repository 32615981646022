const genders = {
  male: "male",
  female: "female",
};

const templates = [
  {id: "vector", gender: genders.female, weight: 10},
  {id: "disney", gender: genders.female},
  {id: "gfp936", gender: genders.female},
  {id: "simpsons", gender: genders.female},

  {id: "vector", gender: genders.male, weight: 10},
  {id: "disney", gender: genders.male},
  {id: "gfp936", gender: genders.male},
  {id: "simpsons", gender: genders.male},
];

export default {
  genders,
  templates,
}